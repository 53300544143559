@charset "UTF-8";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    width: 100%;
    height: auto;
    max-width: 100%;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
    border: none;
}

@-moz-document url-prefix() {
    .rte img {
        width: 100%;
    }
}

::-webkit-scrollbar {
    display: none;
}

.position-relative {
    position: relative;
}

.table {
    display: table;
}

.table-block {
    display: table;
    width: 100%;
}

.table-cell {
    display: table-cell;
}

::-moz-selection,
::selection {
    background: rgba(0, 0, 0, 0.5);
    color: white;
}

nav ul,
nav li {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
}

.clearfix {
    clear: both;
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
}

.ce-border figure {
    display: block;
    padding: 0.5rem;
    border: 1px solid black;
}

a.lightbox-gallery img {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: -o-zoom-in;
    cursor: zoom-in;
}

.lightbox-zoom {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    width: 3rem;
    height: 3rem;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    opacity: 0.8;
}

.lightbox-zoom i {
    color: white;
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    line-height: 3rem;
    opacity: 0.7;
}

a.lightbox-gallery {
    position: relative;
}

html.no-touchevents a.lightbox-gallery {
    display: block;
    background-color: black;
    overflow: hidden;
}

html.no-touchevents a.lightbox-gallery img {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
}

html.no-touchevents a.lightbox-gallery .lightbox-zoom {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
}

html.no-touchevents a.lightbox-gallery:hover img {
    -webkit-transform: scale(1.01);
    -moz-transform: scale(1.01);
    -ms-transform: scale(1.01);
    transform: scale(1.01);
    opacity: 0.8;
}

html.no-touchevents a.lightbox-gallery:hover .lightbox-zoom {
    display: block;
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
    opacity: 0.8;
}

figure.image {
    position: relative;
}

figure.image .image-copyright {
    position: absolute;
    bottom: 1vw;
    right: 1vw;
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 12px;
    line-height: 1.4;
    padding: 3px 6px;
    border-radius: 3px;
}

figure.image figcaption {
    font-size: 80%;
    line-height: 1.2;
    margin-top: 0.5rem;
}

.responsive-video {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
}

.responsive-video > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.responsive-table {
    width: 100%;
    overflow-x: auto;
    margin-left: auto;
    margin-right: auto;
}

.responsive-table table::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
}

.responsive-table table::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #fff;
    background-color: rgba(0, 0, 0, 0.3);
}

.typo3-messages {
    max-width: 1280px;
    margin: 1em auto !important;
    padding: 0 !important;
    list-style: none;
}

.typo3-messages .alert-danger,
.typo3-messages .alert-2 {
    border: 1px solid #e43018;
    background-color: #fceae7;
    color: #e43018;
    padding: 1em;
}

.typo3-messages .alert-warning {
    border: 1px solid #FFA500;
    background-color: #fceae7;
    color: #FFA500;
    padding: 1em;
}

.typo3-messages .alert-success,
.typo3-messages .alert-0 {
    border: 1px solid #009640;
    background-color: #e5f4ec;
    color: #009640;
    padding: 1em;
}

.typo3-messages li.alert {
    margin-top: 1em;
}

.typo3-messages li.alert:first-child {
    margin-top: 0;
}

.typo3-messages li.alert:first-child h4 {
    margin-bottom: 0;
}

.typo3-messages .media-body {
    display: inline-block;
}

.typo3-messages .media-left {
    display: inline-block;
}

.typo3-messages .media-left .fa-stack-1x {
    color: white;
}

a {
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    cursor: pointer;
}

.header-overlay-active .whitediv {
    width: 100%;
    height: 99999px;
    background-color: white;
    position: absolute;
    top: 0;
}

/* Signika-regular - latin */
@font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/signika-v9-latin-regular.eot");
    /* IE9 Compat Modes */
    src: local("Signika Regular"), local("Signika-Regular"), url("../fonts/signika-v9-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/signika-v9-latin-regular.woff2") format("woff2"), url("../fonts/signika-v9-latin-regular.woff") format("woff"), url("../fonts/signika-v9-latin-regular.ttf") format("truetype"), url("../fonts/signika-v9-latin-regular.svg#Signika") format("svg");
    /* Legacy iOS */
}

/* Signika-700 - latin */
@font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/signika-v9-latin-700.eot");
    /* IE9 Compat Modes */
    src: local("Signika Bold"), local("Signika-Bold"), url("../fonts/signika-v9-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/signika-v9-latin-700.woff2") format("woff2"), url("../fonts/signika-v9-latin-700.woff") format("woff"), url("../fonts/signika-v9-latin-700.ttf") format("truetype"), url("../fonts/signika-v9-latin-700.svg#Signika") format("svg");
    /* Legacy iOS */
}

/* Lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/lato-v15-latin-regular.eot");
    /* IE9 Compat Modes */
    src: local("Lato Regular"), local("Lato-Regular"), url("../fonts/lato-v15-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v15-latin-regular.woff2") format("woff2"), url("../fonts/lato-v15-latin-regular.woff") format("woff"), url("../fonts/lato-v15-latin-regular.ttf") format("truetype"), url("../fonts/lato-v15-latin-regular.svg#Lato") format("svg");
    /* Legacy iOS */
}

/* Lato-700 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/lato-v15-latin-700.eot");
    /* IE9 Compat Modes */
    src: local("Lato Bold"), local("Lato-Bold"), url("../fonts/lato-v15-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-v15-latin-700.woff2") format("woff2"), url("../fonts/lato-v15-latin-700.woff") format("woff"), url("../fonts/lato-v15-latin-700.ttf") format("truetype"), url("../fonts/lato-v15-latin-700.svg#Lato") format("svg");
    /* Legacy iOS */
}

html {
    font-size: 16px;
    line-height: 1.6;
    word-break: normal;
    word-wrap: break-word;
    hyphens: auto;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: auto;
}

body {
    font-family: 'Lato', sans-serif;
    font-size: 1.25vw;
    line-height: 1.6;
}

body .size-smaller {
    font-size: 80%;
}

@media screen and (max-width: 1040px) {
    body {
        font-size: 13px;
    }
}

@media screen and (min-width: 1281px) {
    body {
        font-size: 16px;
    }
}

.ui-widget {
    font-family: 'Lato', sans-serif !important;
    font-size: 14px !important;
}

strong,
b {
    font-weight: 700;
}

p code {
    padding: 0 0.1em;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

pre,
code {
    font-family: monospace;
    font-weight: normal;
    font-feature-settings: "dlig" 0, "numr" 0, "dnom" 0, "tnum" 0, "case" 0, "zero" 0, "frac" 0, "sups" 0, "subs" 0, "cpsp" 0, "salt" 0, "ss01" 0, "ss02" 0, "ss03" 0, "cv01" 0, "cv02" 0, "cv03" 0, "cv04" 0, "cv05" 0, "cv06" 0, "cv07" 0, "cv08", "cv09" 0, "cv10" 0, "cv11" 0, "calt" 0, "liga" 0, "kern" 0;
}

pre strong,
pre b,
code strong,
code b {
    font-weight: 700;
}

pre code.language-js,
pre code.language-javascript,
pre code.language-bash,
pre code.language-php,
pre code.language-css,
pre code.json,
pre code.language-ts,
pre code.language-yaml,
pre code.language-html,
pre code.apache {
    position: relative;
    padding-top: 1.5rem;
}

pre code.language-js:before,
pre code.language-javascript:before,
pre code.language-bash:before,
pre code.language-php:before,
pre code.language-css:before,
pre code.json:before,
pre code.language-ts:before,
pre code.language-yaml:before,
pre code.language-html:before,
pre code.apache:before {
    font-size: 60%;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
}

pre code.language-js:before,
pre code.language-javascript:before {
    content: "JavaScript:";
}

pre code.language-bash:before {
    content: "BASH:";
}

pre code.language-php:before {
    content: "PHP:";
}

pre code.language-css:before {
    content: "CSS:";
}

pre code.json:before {
    content: "JSON:";
}

pre code.language-ts:before {
    content: "TS:";
}

pre code.language-yaml:before {
    content: "YAML:";
}

pre code.language-html:before {
    content: "HTML:";
}

pre code.apache:before {
    content: "Apache:";
}

i,
em {
    font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
}

h1 .icon-before,
h1 .icon-after,
h2 .icon-before,
h2 .icon-after,
h3 .icon-before,
h3 .icon-after,
h4 .icon-before,
h4 .icon-after,
h5 .icon-before,
h5 .icon-after,
h6 .icon-before,
h6 .icon-after {
    vertical-align: middle;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    display: block;
}

h1,
.header--large {
    line-height: 1.2;
    font-size: 3.90625vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 600px) {
    h1,
    .header--large {
        font-size: 28px;
    }
}

@media screen and (min-width: 1281px) {
    h1,
    .header--large {
        font-size: 50px;
    }
}

h2,
.header--middle {
    line-height: 1.2;
    font-size: 3.51562vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 830px) {
    h2,
    .header--middle {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    h2,
    .header--middle {
        font-size: 45px;
    }
}

h3,
.header--small {
    line-height: 1.2;
    font-size: 3.125vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    h3,
    .header--small {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    h3,
    .header--small {
        font-size: 40px;
    }
}

.merged-header-and-subheader .header,
.merged-header-and-subheader .subheader {
    display: block;
}

.merged-header-and-subheader .header {
    line-height: 1.2;
    font-size: 3.90625vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 600px) {
    .merged-header-and-subheader .header {
        font-size: 28px;
    }
}

@media screen and (min-width: 1281px) {
    .merged-header-and-subheader .header {
        font-size: 50px;
    }
}

.merged-header-and-subheader .subheader {
    line-height: 1.2;
    font-size: 1.71875vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #7F8D32;
    margin-top: 10px;
}

@media screen and (max-width: 780px) {
    .merged-header-and-subheader .subheader {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .merged-header-and-subheader .subheader {
        font-size: 22px;
    }
}

@media screen and (max-width: 768px) {
    .merged-header-and-subheader .subheader {
        margin-top: 5px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
h1.font-regular,
h2.font-regular,
h3.font-regular,
h4.font-regular,
h5.font-regular,
h6.font-regular,
.font-regular {
    font-weight: 700;
}

h1.font-bold,
h2.font-bold,
h3.font-bold,
h4.font-bold,
h5.font-bold,
h6.font-bold,
.font-bold {
    font-weight: 700;
}

h1.font-header-regular,
h2.font-header-regular,
h3.font-header-regular,
h4.font-header-regular,
h5.font-header-regular,
h6.font-header-regular,
.font-header-regular {
    font-family: 'Signika', sans-serif;
    font-weight: 400;
}

h1.font-header-bold,
h2.font-header-bold,
h3.font-header-bold,
h4.font-header-bold,
h5.font-header-bold,
h6.font-header-bold,
.font-header-bold {
    font-family: 'Signika', sans-serif;
    font-weight: 700;
}

h1.font-headline-bold,
h2.font-headline-bold,
h3.font-headline-bold,
h4.font-headline-bold,
h5.font-headline-bold,
h6.font-headline-bold,
.font-headline-bold {
    line-height: 1.2;
    font-size: 3.90625vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 600px) {
    h1.font-headline-bold,
    h2.font-headline-bold,
    h3.font-headline-bold,
    h4.font-headline-bold,
    h5.font-headline-bold,
    h6.font-headline-bold,
    .font-headline-bold {
        font-size: 28px;
    }
}

@media screen and (min-width: 1281px) {
    h1.font-headline-bold,
    h2.font-headline-bold,
    h3.font-headline-bold,
    h4.font-headline-bold,
    h5.font-headline-bold,
    h6.font-headline-bold,
    .font-headline-bold {
        font-size: 50px;
    }
}

h1.font-small-headline-bold,
h2.font-small-headline-bold,
h3.font-small-headline-bold,
h4.font-small-headline-bold,
h5.font-small-headline-bold,
h6.font-small-headline-bold,
.font-small-headline-bold {
    line-height: 1.2;
    font-size: 3.125vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    h1.font-small-headline-bold,
    h2.font-small-headline-bold,
    h3.font-small-headline-bold,
    h4.font-small-headline-bold,
    h5.font-small-headline-bold,
    h6.font-small-headline-bold,
    .font-small-headline-bold {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    h1.font-small-headline-bold,
    h2.font-small-headline-bold,
    h3.font-small-headline-bold,
    h4.font-small-headline-bold,
    h5.font-small-headline-bold,
    h6.font-small-headline-bold,
    .font-small-headline-bold {
        font-size: 40px;
    }
}

h1.font-extrasmall-headline-bold,
h2.font-extrasmall-headline-bold,
h3.font-extrasmall-headline-bold,
h4.font-extrasmall-headline-bold,
h5.font-extrasmall-headline-bold,
h6.font-extrasmall-headline-bold,
.font-extrasmall-headline-bold {
    line-height: 1.2;
    font-size: 1.71875vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    h1.font-extrasmall-headline-bold,
    h2.font-extrasmall-headline-bold,
    h3.font-extrasmall-headline-bold,
    h4.font-extrasmall-headline-bold,
    h5.font-extrasmall-headline-bold,
    h6.font-extrasmall-headline-bold,
    .font-extrasmall-headline-bold {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    h1.font-extrasmall-headline-bold,
    h2.font-extrasmall-headline-bold,
    h3.font-extrasmall-headline-bold,
    h4.font-extrasmall-headline-bold,
    h5.font-extrasmall-headline-bold,
    h6.font-extrasmall-headline-bold,
    .font-extrasmall-headline-bold {
        font-size: 22px;
    }
}

h1.font-table-headline-bold,
h2.font-table-headline-bold,
h3.font-table-headline-bold,
h4.font-table-headline-bold,
h5.font-table-headline-bold,
h6.font-table-headline-bold,
.font-table-headline-bold {
    line-height: 1.2;
    font-size: 1.71875vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    h1.font-table-headline-bold,
    h2.font-table-headline-bold,
    h3.font-table-headline-bold,
    h4.font-table-headline-bold,
    h5.font-table-headline-bold,
    h6.font-table-headline-bold,
    .font-table-headline-bold {
        font-size: 16px;
    }
}

@media screen and (min-width: 1281px) {
    h1.font-table-headline-bold,
    h2.font-table-headline-bold,
    h3.font-table-headline-bold,
    h4.font-table-headline-bold,
    h5.font-table-headline-bold,
    h6.font-table-headline-bold,
    .font-table-headline-bold {
        font-size: 22px;
    }
}

h1.font-small-underlined-headline-bold,
h2.font-small-underlined-headline-bold,
h3.font-small-underlined-headline-bold,
h4.font-small-underlined-headline-bold,
h5.font-small-underlined-headline-bold,
h6.font-small-underlined-headline-bold,
.font-small-underlined-headline-bold {
    border-bottom: 1px solid black;
    line-height: 1.2;
    font-size: 3.125vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    h1.font-small-underlined-headline-bold,
    h2.font-small-underlined-headline-bold,
    h3.font-small-underlined-headline-bold,
    h4.font-small-underlined-headline-bold,
    h5.font-small-underlined-headline-bold,
    h6.font-small-underlined-headline-bold,
    .font-small-underlined-headline-bold {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    h1.font-small-underlined-headline-bold,
    h2.font-small-underlined-headline-bold,
    h3.font-small-underlined-headline-bold,
    h4.font-small-underlined-headline-bold,
    h5.font-small-underlined-headline-bold,
    h6.font-small-underlined-headline-bold,
    .font-small-underlined-headline-bold {
        font-size: 40px;
    }
}

h1.font-extrasmall-underlined-headline-bold,
h2.font-extrasmall-underlined-headline-bold,
h3.font-extrasmall-underlined-headline-bold,
h4.font-extrasmall-underlined-headline-bold,
h5.font-extrasmall-underlined-headline-bold,
h6.font-extrasmall-underlined-headline-bold,
.font-extrasmall-underlined-headline-bold {
    border-bottom: 1px solid black;
    line-height: 1.2;
    font-size: 1.71875vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    h1.font-extrasmall-underlined-headline-bold,
    h2.font-extrasmall-underlined-headline-bold,
    h3.font-extrasmall-underlined-headline-bold,
    h4.font-extrasmall-underlined-headline-bold,
    h5.font-extrasmall-underlined-headline-bold,
    h6.font-extrasmall-underlined-headline-bold,
    .font-extrasmall-underlined-headline-bold {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    h1.font-extrasmall-underlined-headline-bold,
    h2.font-extrasmall-underlined-headline-bold,
    h3.font-extrasmall-underlined-headline-bold,
    h4.font-extrasmall-underlined-headline-bold,
    h5.font-extrasmall-underlined-headline-bold,
    h6.font-extrasmall-underlined-headline-bold,
    .font-extrasmall-underlined-headline-bold {
        font-size: 22px;
    }
}

.price-balloon {
    position: absolute;
    top: -110px;
    right: 10px;
    text-align: center;
    background-color: #7f8d32;
    color: white;
    padding: 10px 25px;
    line-height: 1.2;
    font-size: 3.125vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    .price-balloon {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .price-balloon {
        font-size: 40px;
    }
}

@media screen and (max-width: 768px) {
    .price-balloon {
        top: -86px;
        font-size: 20px;
    }
}

ul,
ol {
    padding: 0 0 0 1.25em;
    margin: 0 0 0 0;
    padding-bottom: 1.5rem;
}

@media screen and (min-width: 1281px) {
    ul,
    ol {
        padding-bottom: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    ul,
    ol {
        padding-bottom: 1rem;
    }
}

ul li,
ol li {
    position: relative;
    padding: 0;
    margin: 0;
}

ul ul,
ul ol, ul:last-child,
ol ul,
ol ol,
ol:last-child {
    padding-bottom: 0;
}

.standard-padding-bottom {
    padding-bottom: 1rem;
}

@media screen and (min-width: 1281px) {
    .standard-padding-bottom {
        padding-bottom: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .standard-padding-bottom {
        padding-bottom: 1rem;
    }
}

.ce-textmedia ul li {
    position: relative;
}

a.envelope,
a.phone,
a.fax {
    text-decoration: none;
    padding-bottom: auto;
    border-bottom: none;
}

a.envelope i,
a.phone i,
a.fax i {
    width: 1.75em;
    color: #7F8D32;
}

html.no-touch a.envelope:hover, html.no-touch
a.phone:hover, html.no-touch
a.fax:hover {
    text-decoration: none;
}

a {
    -webkit-transition: color 0.3s ease;
    -moz-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
}

a.iframe-no-decoration,
a.no-decoration {
    text-decoration: none;
}

a.iframe-no-decoration:hover,
a.no-decoration:hover {
    text-decoration: none;
}

a.colored-button,
a.colored-button-iframe,
a.white-button,
a.white-button-iframe {
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    padding: 0.1em 0.4em;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
}

html.no-touchevents a.colored-button:hover, html.no-touchevents
a.colored-button-iframe:hover, html.no-touchevents
a.white-button:hover, html.no-touchevents
a.white-button-iframe:hover {
    text-decoration: none;
}

a.colored-button:after,
a.colored-button-iframe:after,
a.white-button:after,
a.white-button-iframe:after {
    content: "▸";
    margin-left: 0.5em;
}

a.colored-button,
a.colored-button-iframe {
    border-color: #7F8D32;
    background-color: #7F8D32;
    color: white;
}

html.no-touchevents a.colored-button:hover, html.no-touchevents
a.colored-button-iframe:hover {
    background-color: white;
    color: #7F8D32;
}

a.white-button,
a.white-button-iframe {
    border-color: white;
    background-color: white;
    color: #7F8D32;
}

html.no-touchevents a.white-button:hover, html.no-touchevents
a.white-button-iframe:hover {
    background-color: #7F8D32;
    color: white;
}

h1,
h2,
h3,
h4,
h5 {
    padding-bottom: 1.5rem;
}

@media screen and (min-width: 1281px) {
    h1,
    h2,
    h3,
    h4,
    h5 {
        padding-bottom: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    h1,
    h2,
    h3,
    h4,
    h5 {
        padding-bottom: 1rem;
    }
}

pre,
p,
blockquote {
    padding-bottom: 1.5rem;
}

@media screen and (min-width: 1281px) {
    pre,
    p,
    blockquote {
        padding-bottom: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    pre,
    p,
    blockquote {
        padding-bottom: 1rem;
    }
}

pre:last-child,
p:last-child,
blockquote:last-child {
    padding-bottom: 0;
}

.text-columne-count-2 p,
.text-columne-count-3 p {
    padding-bottom: 1.5em !important;
}

hr {
    border: none;
    height: 1px;
    background-color: black;
    margin-bottom: getRemFromPx(14);
}

.color-white hr {
    background-color: white;
}

figcaption p {
    margin-bottom: 0;
}

sub {
    vertical-align: sub;
    line-height: 0;
    font-size: 70%;
}

sup {
    vertical-align: super;
    line-height: 0;
    font-size: 70%;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-justify {
    text-align: justify;
}

.align-right {
    text-align: right;
}

.align-top {
    vertical-align: top;
}

.align-middle {
    vertical-align: middle;
}

.align-bottom {
    vertical-align: bottom;
}

.transform-uppercase {
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.transform-lowercase {
    text-transform: lowecase;
}

.transform-none {
    text-transform: none;
}

.transform-small-caps {
    font-variant: small-caps;
}

.indent {
    margin-left: 2em;
}

blockquote {
    border-left: .25em solid #dfe2e5;
    color: #6a737d;
    padding: 0 1em;
}

u,
.underline {
    text-decoration-line: underline;
    text-decoration-style: wavy;
}

s,
.line-through {
    text-decoration: line-through;
}

.ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nowrap {
    white-space: nowrap;
}

/* tables
= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
table.ce-table {
    border-spacing: 0;
    /* no space between cells */
    border-collapse: separate;
    /* no collisions between cell-borders */
    *border-collapse: collapse;
    /* hack is needed for IE7 to prevent space between cells */
    border: none;
    width: 100%;
}

table.ce-table td,
table.ce-table th {
    padding: 0.5rem;
    border-top: 1px solid #000;
    text-align: left;
    vertical-align: top;
}

table.ce-table tr:first-child td,
table.ce-table tr:first-child th {
    border-top: none;
}

table.ce-table th {
    font-weight: 700;
    width: 1%;
    white-space: nowrap;
}

table.ce-table td {
    font-weight: 700;
}

table.ce-table tr *:first-child {
    padding-left: 0;
}

table.ce-table tr *:last-child {
    padding-right: 0;
}

table.ce-table-aside th {
    font-weight: 700;
}

table.ce-table-aside td {
    font-weight: 400;
}

table.ce-markdown {
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    overflow: auto;
    width: 100%;
}

table.ce-markdown tr {
    border-top: 1px solid #c6cbd1;
    background-color: #fff;
}

table.ce-markdown tbody tr:nth-child(odd) {
    background-color: #f6f8fa;
}

table.ce-markdown tbody tr:nth-child(even) {
    background-color: #fff;
}

table.ce-markdown td,
table.ce-markdown th {
    border: 1px solid #dfe2e5;
    padding: 6px 13px;
}

table.ce-markdown th {
    font-weight: 700;
}

.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: -2rem;
    margin-top: -2rem;
}

.grid--rev {
    flex-wrap: wrap-reverse;
}

.grid__item {
    display: inline-block;
    padding-left: 2rem;
    margin-top: 2rem;
    width: 100%;
}

.one-whole {
    width: 100%;
}

.one-half, .two-quarters, .three-sixths, .four-eighths, .five-tenths, .six-twelfths {
    width: 50%;
}

.one-third, .two-sixths, .four-twelfths {
    width: 33.333%;
}

.two-thirds, .four-sixths, .eight-twelfths {
    width: 66.666%;
}

.one-quarter, .two-eighths, .three-twelfths {
    width: 25%;
}

.three-quarters, .six-eighths, .nine-twelfths {
    width: 75%;
}

.one-fifth, .two-tenths {
    width: 20%;
}

.two-fifths, .four-tenths {
    width: 40%;
}

.three-fifths, .six-tenths {
    width: 60%;
}

.four-fifths, .eight-tenths {
    width: 80%;
}

.one-sixth, .two-twelfths {
    width: 16.666%;
}

.five-sixths, .ten-twelfths {
    width: 83.333%;
}

.one-eighth {
    width: 12.5%;
}

.three-eighths {
    width: 37.5%;
}

.five-eighths {
    width: 62.5%;
}

.seven-eighths {
    width: 87.5%;
}

.one-tenth {
    width: 10%;
}

.three-tenths {
    width: 30%;
}

.seven-tenths {
    width: 70%;
}

.nine-tenths {
    width: 90%;
}

.one-twelfth {
    width: 8.333%;
}

.five-twelfths {
    width: 41.666%;
}

.seven-twelfths {
    width: 58.333%;
}

.eleven-twelfths {
    width: 91.666%;
}

.grid-space-0 {
    margin-left: 0;
    margin-top: 0;
}

.grid-space-0 > .grid__item {
    padding-left: 0;
    margin-top: 0;
}

.grid-space-8 {
    margin-left: -0.5rem;
    margin-top: -0.5rem;
}

.grid-space-8 > .grid__item {
    padding-left: 0.5rem;
    margin-top: 0.5rem;
}

.grid-space-16 {
    margin-left: -1rem;
    margin-top: -1rem;
}

.grid-space-16 > .grid__item {
    padding-left: 1rem;
    margin-top: 1rem;
}

.grid-space-24 {
    margin-left: -1.5rem;
    margin-top: -1.5rem;
}

.grid-space-24 > .grid__item {
    padding-left: 1.5rem;
    margin-top: 1.5rem;
}

.grid-space-32 {
    margin-left: -2rem;
    margin-top: -2rem;
}

.grid-space-32 > .grid__item {
    padding-left: 2rem;
    margin-top: 2rem;
}

.grid-space-40 {
    margin-left: -2.5rem;
    margin-top: -2.5rem;
}

.grid-space-40 > .grid__item {
    padding-left: 2.5rem;
    margin-top: 2.5rem;
}

.grid-space-48 {
    margin-left: -3rem;
    margin-top: -3rem;
}

.grid-space-48 > .grid__item {
    padding-left: 3rem;
    margin-top: 3rem;
}

.grid-space-56 {
    margin-left: -3.5rem;
    margin-top: -3.5rem;
}

.grid-space-56 > .grid__item {
    padding-left: 3.5rem;
    margin-top: 3.5rem;
}

.grid-space-64 {
    margin-left: -4rem;
    margin-top: -4rem;
}

.grid-space-64 > .grid__item {
    padding-left: 4rem;
    margin-top: 4rem;
}

.grid-space-72 {
    margin-left: -4.5rem;
    margin-top: -4.5rem;
}

.grid-space-72 > .grid__item {
    padding-left: 4.5rem;
    margin-top: 4.5rem;
}

.grid-space-80 {
    margin-left: -5rem;
    margin-top: -5rem;
}

.grid-space-80 > .grid__item {
    padding-left: 5rem;
    margin-top: 5rem;
}

.grid-space-vertical-0 {
    margin-top: 0;
}

.grid-space-vertical-0 > .grid__item {
    margin-top: 0;
}

.grid-space-vertical-8 {
    margin-top: -0.5rem;
}

.grid-space-vertical-8 > .grid__item {
    margin-top: 0.5rem;
}

.grid-space-vertical-16 {
    margin-top: -1rem;
}

.grid-space-vertical-16 > .grid__item {
    margin-top: 1rem;
}

.grid-space-vertical-24 {
    margin-top: -1.5rem;
}

.grid-space-vertical-24 > .grid__item {
    margin-top: 1.5rem;
}

.grid-space-vertical-32 {
    margin-left: -2rem;
}

.grid-space-vertical-32 > .grid__item {
    margin-top: 2rem;
}

.grid-space-vertical-40 {
    margin-top: -2.5rem;
}

.grid-space-vertical-40 > .grid__item {
    margin-top: 2.5rem;
}

.grid-space-vertical-48 {
    margin-top: -3rem;
}

.grid-space-vertical-48 > .grid__item {
    margin-top: 3rem;
}

.grid-space-vertical-56 {
    margin-top: -3.5rem;
}

.grid-space-vertical-56 > .grid__item {
    margin-top: 3.5rem;
}

.grid-space-vertical-64 {
    margin-top: -4rem;
}

.grid-space-vertical-64 > .grid__item {
    margin-top: 4rem;
}

.grid-space-vertical-72 {
    margin-top: -4.5rem;
}

.grid-space-vertical-72 > .grid__item {
    margin-top: 4.5rem;
}

.grid-space-vertical-80 {
    margin-top: -5rem;
}

.grid-space-vertical-80 > .grid__item {
    margin-top: 5rem;
}

.grid--left {
    justify-content: flex-start;
}

.grid--center {
    justify-content: center;
}

.grid--right {
    justify-content: flex-end;
}

.grid--top {
    align-items: flex-start;
}

.grid--middle {
    align-items: center;
}

.grid--bottom {
    align-items: flex-end;
}

.grid--same-height {
    align-items: stretch;
}

.grid--same-height > .grid__item > * {
    min-height: 100%;
    height: 100%;
}

.grid--reverse {
    flex-direction: row-reverse;
}

.grid--rev {
    flex-wrap: wrap-reverse;
}

@media screen and (min-width: 1281px) {
    .desktop--one-whole {
        width: 100%;
    }
    .desktop--one-half, .desktop--two-quarters, .desktop--three-sixths, .desktop--four-eighths, .desktop--five-tenths, .desktop--six-twelfths {
        width: 50%;
    }
    .desktop--one-third, .desktop--two-sixths, .desktop--four-twelfths {
        width: 33.333%;
    }
    .desktop--two-thirds, .desktop--four-sixths, .desktop--eight-twelfths {
        width: 66.666%;
    }
    .desktop--one-quarter, .desktop--two-eighths, .desktop--three-twelfths {
        width: 25%;
    }
    .desktop--three-quarters, .desktop--six-eighths, .desktop--nine-twelfths {
        width: 75%;
    }
    .desktop--one-fifth, .desktop--two-tenths {
        width: 20%;
    }
    .desktop--two-fifths, .desktop--four-tenths {
        width: 40%;
    }
    .desktop--three-fifths, .desktop--six-tenths {
        width: 60%;
    }
    .desktop--four-fifths, .desktop--eight-tenths {
        width: 80%;
    }
    .desktop--one-sixth, .desktop--two-twelfths {
        width: 16.666%;
    }
    .desktop--five-sixths, .desktop--ten-twelfths {
        width: 83.333%;
    }
    .desktop--one-eighth {
        width: 12.5%;
    }
    .desktop--three-eighths {
        width: 37.5%;
    }
    .desktop--five-eighths {
        width: 62.5%;
    }
    .desktop--seven-eighths {
        width: 87.5%;
    }
    .desktop--one-tenth {
        width: 10%;
    }
    .desktop--three-tenths {
        width: 30%;
    }
    .desktop--seven-tenths {
        width: 70%;
    }
    .desktop--nine-tenths {
        width: 90%;
    }
    .desktop--one-twelfth {
        width: 8.333%;
    }
    .desktop--five-twelfths {
        width: 41.666%;
    }
    .desktop--seven-twelfths {
        width: 58.333%;
    }
    .desktop--eleven-twelfths {
        width: 91.666%;
    }
    .desktop--grid-space-0 {
        margin-left: 0;
        margin-top: 0;
    }
    .desktop--grid-space-0 > .grid__item {
        padding-left: 0;
        margin-top: 0;
    }
    .desktop--grid-space-8 {
        margin-left: -0.5rem;
        margin-top: -0.5rem;
    }
    .desktop--grid-space-8 > .grid__item {
        padding-left: 0.5rem;
        margin-top: 0.5rem;
    }
    .desktop--grid-space-16 {
        margin-left: -1rem;
        margin-top: -1rem;
    }
    .desktop--grid-space-16 > .grid__item {
        padding-left: 1rem;
        margin-top: 1rem;
    }
    .desktop--grid-space-24 {
        margin-left: -1.5rem;
        margin-top: -1.5rem;
    }
    .desktop--grid-space-24 > .grid__item {
        padding-left: 1.5rem;
        margin-top: 1.5rem;
    }
    .desktop--grid-space-32 {
        margin-left: -2rem;
        margin-top: -2rem;
    }
    .desktop--grid-space-32 > .grid__item {
        padding-left: 2rem;
        margin-top: 2rem;
    }
    .desktop--grid-space-40 {
        margin-left: -2.5rem;
        margin-top: -2.5rem;
    }
    .desktop--grid-space-40 > .grid__item {
        padding-left: 2.5rem;
        margin-top: 2.5rem;
    }
    .desktop--grid-space-48 {
        margin-left: -3rem;
        margin-top: -3rem;
    }
    .desktop--grid-space-48 > .grid__item {
        padding-left: 3rem;
        margin-top: 3rem;
    }
    .desktop--grid-space-56 {
        margin-left: -3.5rem;
        margin-top: -3.5rem;
    }
    .desktop--grid-space-56 > .grid__item {
        padding-left: 3.5rem;
        margin-top: 3.5rem;
    }
    .desktop--grid-space-64 {
        margin-left: -4rem;
        margin-top: -4rem;
    }
    .desktop--grid-space-64 > .grid__item {
        padding-left: 4rem;
        margin-top: 4rem;
    }
    .desktop--grid-space-72 {
        margin-left: -4.5rem;
        margin-top: -4.5rem;
    }
    .desktop--grid-space-72 > .grid__item {
        padding-left: 4.5rem;
        margin-top: 4.5rem;
    }
    .desktop--grid-space-80 {
        margin-left: -5rem;
        margin-top: -5rem;
    }
    .desktop--grid-space-80 > .grid__item {
        padding-left: 5rem;
        margin-top: 5rem;
    }
    .desktop--grid-space-vertical-0 {
        margin-top: 0;
    }
    .desktop--grid-space-vertical-0 > .grid__item {
        margin-top: 0;
    }
    .desktop--grid-space-vertical-8 {
        margin-top: -0.5rem;
    }
    .desktop--grid-space-vertical-8 > .grid__item {
        margin-top: 0.5rem;
    }
    .desktop--grid-space-vertical-16 {
        margin-top: -1rem;
    }
    .desktop--grid-space-vertical-16 > .grid__item {
        margin-top: 1rem;
    }
    .desktop--grid-space-vertical-24 {
        margin-top: -1.5rem;
    }
    .desktop--grid-space-vertical-24 > .grid__item {
        margin-top: 1.5rem;
    }
    .desktop--grid-space-vertical-32 {
        margin-left: -2rem;
    }
    .desktop--grid-space-vertical-32 > .grid__item {
        margin-top: 2rem;
    }
    .desktop--grid-space-vertical-40 {
        margin-top: -2.5rem;
    }
    .desktop--grid-space-vertical-40 > .grid__item {
        margin-top: 2.5rem;
    }
    .desktop--grid-space-vertical-48 {
        margin-top: -3rem;
    }
    .desktop--grid-space-vertical-48 > .grid__item {
        margin-top: 3rem;
    }
    .desktop--grid-space-vertical-56 {
        margin-top: -3.5rem;
    }
    .desktop--grid-space-vertical-56 > .grid__item {
        margin-top: 3.5rem;
    }
    .desktop--grid-space-vertical-64 {
        margin-top: -4rem;
    }
    .desktop--grid-space-vertical-64 > .grid__item {
        margin-top: 4rem;
    }
    .desktop--grid-space-vertical-72 {
        margin-top: -4.5rem;
    }
    .desktop--grid-space-vertical-72 > .grid__item {
        margin-top: 4.5rem;
    }
    .desktop--grid-space-vertical-80 {
        margin-top: -5rem;
    }
    .desktop--grid-space-vertical-80 > .grid__item {
        margin-top: 5rem;
    }
    .desktop--grid--left {
        justify-content: flex-start;
    }
    .desktop--grid--center {
        justify-content: center;
    }
    .desktop--grid--right {
        justify-content: flex-end;
    }
    .desktop--grid--top {
        align-items: flex-start;
    }
    .desktop--grid--middle {
        align-items: center;
    }
    .desktop--grid--bottom {
        align-items: flex-end;
    }
    .desktop--grid--same-height {
        align-items: stretch;
    }
    .desktop--grid--same-height > .grid__item > * {
        min-height: 100%;
        height: 100%;
    }
    .desktop--grid--reverse {
        flex-direction: row-reverse;
    }
    .desktop--grid--rev {
        flex-wrap: wrap-reverse;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .laptop--one-whole {
        width: 100%;
    }
    .laptop--one-half, .laptop--two-quarters, .laptop--three-sixths, .laptop--four-eighths, .laptop--five-tenths, .laptop--six-twelfths {
        width: 50%;
    }
    .laptop--one-third, .laptop--two-sixths, .laptop--four-twelfths {
        width: 33.333%;
    }
    .laptop--two-thirds, .laptop--four-sixths, .laptop--eight-twelfths {
        width: 66.666%;
    }
    .laptop--one-quarter, .laptop--two-eighths, .laptop--three-twelfths {
        width: 25%;
    }
    .laptop--three-quarters, .laptop--six-eighths, .laptop--nine-twelfths {
        width: 75%;
    }
    .laptop--one-fifth, .laptop--two-tenths {
        width: 20%;
    }
    .laptop--two-fifths, .laptop--four-tenths {
        width: 40%;
    }
    .laptop--three-fifths, .laptop--six-tenths {
        width: 60%;
    }
    .laptop--four-fifths, .laptop--eight-tenths {
        width: 80%;
    }
    .laptop--one-sixth, .laptop--two-twelfths {
        width: 16.666%;
    }
    .laptop--five-sixths, .laptop--ten-twelfths {
        width: 83.333%;
    }
    .laptop--one-eighth {
        width: 12.5%;
    }
    .laptop--three-eighths {
        width: 37.5%;
    }
    .laptop--five-eighths {
        width: 62.5%;
    }
    .laptop--seven-eighths {
        width: 87.5%;
    }
    .laptop--one-tenth {
        width: 10%;
    }
    .laptop--three-tenths {
        width: 30%;
    }
    .laptop--seven-tenths {
        width: 70%;
    }
    .laptop--nine-tenths {
        width: 90%;
    }
    .laptop--one-twelfth {
        width: 8.333%;
    }
    .laptop--five-twelfths {
        width: 41.666%;
    }
    .laptop--seven-twelfths {
        width: 58.333%;
    }
    .laptop--eleven-twelfths {
        width: 91.666%;
    }
    .laptop--grid-space-0 {
        margin-left: 0;
        margin-top: 0;
    }
    .laptop--grid-space-0 > .grid__item {
        padding-left: 0;
        margin-top: 0;
    }
    .laptop--grid-space-8 {
        margin-left: -0.5rem;
        margin-top: -0.5rem;
    }
    .laptop--grid-space-8 > .grid__item {
        padding-left: 0.5rem;
        margin-top: 0.5rem;
    }
    .laptop--grid-space-16 {
        margin-left: -1rem;
        margin-top: -1rem;
    }
    .laptop--grid-space-16 > .grid__item {
        padding-left: 1rem;
        margin-top: 1rem;
    }
    .laptop--grid-space-24 {
        margin-left: -1.5rem;
        margin-top: -1.5rem;
    }
    .laptop--grid-space-24 > .grid__item {
        padding-left: 1.5rem;
        margin-top: 1.5rem;
    }
    .laptop--grid-space-32 {
        margin-left: -2rem;
        margin-top: -2rem;
    }
    .laptop--grid-space-32 > .grid__item {
        padding-left: 2rem;
        margin-top: 2rem;
    }
    .laptop--grid-space-40 {
        margin-left: -2.5rem;
        margin-top: -2.5rem;
    }
    .laptop--grid-space-40 > .grid__item {
        padding-left: 2.5rem;
        margin-top: 2.5rem;
    }
    .laptop--grid-space-48 {
        margin-left: -3rem;
        margin-top: -3rem;
    }
    .laptop--grid-space-48 > .grid__item {
        padding-left: 3rem;
        margin-top: 3rem;
    }
    .laptop--grid-space-56 {
        margin-left: -3.5rem;
        margin-top: -3.5rem;
    }
    .laptop--grid-space-56 > .grid__item {
        padding-left: 3.5rem;
        margin-top: 3.5rem;
    }
    .laptop--grid-space-64 {
        margin-left: -4rem;
        margin-top: -4rem;
    }
    .laptop--grid-space-64 > .grid__item {
        padding-left: 4rem;
        margin-top: 4rem;
    }
    .laptop--grid-space-72 {
        margin-left: -4.5rem;
        margin-top: -4.5rem;
    }
    .laptop--grid-space-72 > .grid__item {
        padding-left: 4.5rem;
        margin-top: 4.5rem;
    }
    .laptop--grid-space-80 {
        margin-left: -5rem;
        margin-top: -5rem;
    }
    .laptop--grid-space-80 > .grid__item {
        padding-left: 5rem;
        margin-top: 5rem;
    }
    .laptop--grid-space-vertical-0 {
        margin-top: 0;
    }
    .laptop--grid-space-vertical-0 > .grid__item {
        margin-top: 0;
    }
    .laptop--grid-space-vertical-8 {
        margin-top: -0.5rem;
    }
    .laptop--grid-space-vertical-8 > .grid__item {
        margin-top: 0.5rem;
    }
    .laptop--grid-space-vertical-16 {
        margin-top: -1rem;
    }
    .laptop--grid-space-vertical-16 > .grid__item {
        margin-top: 1rem;
    }
    .laptop--grid-space-vertical-24 {
        margin-top: -1.5rem;
    }
    .laptop--grid-space-vertical-24 > .grid__item {
        margin-top: 1.5rem;
    }
    .laptop--grid-space-vertical-32 {
        margin-left: -2rem;
    }
    .laptop--grid-space-vertical-32 > .grid__item {
        margin-top: 2rem;
    }
    .laptop--grid-space-vertical-40 {
        margin-top: -2.5rem;
    }
    .laptop--grid-space-vertical-40 > .grid__item {
        margin-top: 2.5rem;
    }
    .laptop--grid-space-vertical-48 {
        margin-top: -3rem;
    }
    .laptop--grid-space-vertical-48 > .grid__item {
        margin-top: 3rem;
    }
    .laptop--grid-space-vertical-56 {
        margin-top: -3.5rem;
    }
    .laptop--grid-space-vertical-56 > .grid__item {
        margin-top: 3.5rem;
    }
    .laptop--grid-space-vertical-64 {
        margin-top: -4rem;
    }
    .laptop--grid-space-vertical-64 > .grid__item {
        margin-top: 4rem;
    }
    .laptop--grid-space-vertical-72 {
        margin-top: -4.5rem;
    }
    .laptop--grid-space-vertical-72 > .grid__item {
        margin-top: 4.5rem;
    }
    .laptop--grid-space-vertical-80 {
        margin-top: -5rem;
    }
    .laptop--grid-space-vertical-80 > .grid__item {
        margin-top: 5rem;
    }
    .laptop--grid--left {
        justify-content: flex-start;
    }
    .laptop--grid--center {
        justify-content: center;
    }
    .laptop--grid--right {
        justify-content: flex-end;
    }
    .laptop--grid--top {
        align-items: flex-start;
    }
    .laptop--grid--middle {
        align-items: center;
    }
    .laptop--grid--bottom {
        align-items: flex-end;
    }
    .laptop--grid--same-height {
        align-items: stretch;
    }
    .laptop--grid--same-height > .grid__item > * {
        min-height: 100%;
        height: 100%;
    }
    .laptop--grid--reverse {
        flex-direction: row-reverse;
    }
    .laptop--grid--rev {
        flex-wrap: wrap-reverse;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .tablet--one-whole {
        width: 100%;
    }
    .tablet--one-half, .tablet--two-quarters, .tablet--three-sixths, .tablet--four-eighths, .tablet--five-tenths, .tablet--six-twelfths {
        width: 50%;
    }
    .tablet--one-third, .tablet--two-sixths, .tablet--four-twelfths {
        width: 33.333%;
    }
    .tablet--two-thirds, .tablet--four-sixths, .tablet--eight-twelfths {
        width: 66.666%;
    }
    .tablet--one-quarter, .tablet--two-eighths, .tablet--three-twelfths {
        width: 25%;
    }
    .tablet--three-quarters, .tablet--six-eighths, .tablet--nine-twelfths {
        width: 75%;
    }
    .tablet--one-fifth, .tablet--two-tenths {
        width: 20%;
    }
    .tablet--two-fifths, .tablet--four-tenths {
        width: 40%;
    }
    .tablet--three-fifths, .tablet--six-tenths {
        width: 60%;
    }
    .tablet--four-fifths, .tablet--eight-tenths {
        width: 80%;
    }
    .tablet--one-sixth, .tablet--two-twelfths {
        width: 16.666%;
    }
    .tablet--five-sixths, .tablet--ten-twelfths {
        width: 83.333%;
    }
    .tablet--one-eighth {
        width: 12.5%;
    }
    .tablet--three-eighths {
        width: 37.5%;
    }
    .tablet--five-eighths {
        width: 62.5%;
    }
    .tablet--seven-eighths {
        width: 87.5%;
    }
    .tablet--one-tenth {
        width: 10%;
    }
    .tablet--three-tenths {
        width: 30%;
    }
    .tablet--seven-tenths {
        width: 70%;
    }
    .tablet--nine-tenths {
        width: 90%;
    }
    .tablet--one-twelfth {
        width: 8.333%;
    }
    .tablet--five-twelfths {
        width: 41.666%;
    }
    .tablet--seven-twelfths {
        width: 58.333%;
    }
    .tablet--eleven-twelfths {
        width: 91.666%;
    }
    .tablet--grid-space-0 {
        margin-left: 0;
        margin-top: 0;
    }
    .tablet--grid-space-0 > .grid__item {
        padding-left: 0;
        margin-top: 0;
    }
    .tablet--grid-space-8 {
        margin-left: -0.5rem;
        margin-top: -0.5rem;
    }
    .tablet--grid-space-8 > .grid__item {
        padding-left: 0.5rem;
        margin-top: 0.5rem;
    }
    .tablet--grid-space-16 {
        margin-left: -1rem;
        margin-top: -1rem;
    }
    .tablet--grid-space-16 > .grid__item {
        padding-left: 1rem;
        margin-top: 1rem;
    }
    .tablet--grid-space-24 {
        margin-left: -1.5rem;
        margin-top: -1.5rem;
    }
    .tablet--grid-space-24 > .grid__item {
        padding-left: 1.5rem;
        margin-top: 1.5rem;
    }
    .tablet--grid-space-32 {
        margin-left: -2rem;
        margin-top: -2rem;
    }
    .tablet--grid-space-32 > .grid__item {
        padding-left: 2rem;
        margin-top: 2rem;
    }
    .tablet--grid-space-40 {
        margin-left: -2.5rem;
        margin-top: -2.5rem;
    }
    .tablet--grid-space-40 > .grid__item {
        padding-left: 2.5rem;
        margin-top: 2.5rem;
    }
    .tablet--grid-space-48 {
        margin-left: -3rem;
        margin-top: -3rem;
    }
    .tablet--grid-space-48 > .grid__item {
        padding-left: 3rem;
        margin-top: 3rem;
    }
    .tablet--grid-space-56 {
        margin-left: -3.5rem;
        margin-top: -3.5rem;
    }
    .tablet--grid-space-56 > .grid__item {
        padding-left: 3.5rem;
        margin-top: 3.5rem;
    }
    .tablet--grid-space-64 {
        margin-left: -4rem;
        margin-top: -4rem;
    }
    .tablet--grid-space-64 > .grid__item {
        padding-left: 4rem;
        margin-top: 4rem;
    }
    .tablet--grid-space-72 {
        margin-left: -4.5rem;
        margin-top: -4.5rem;
    }
    .tablet--grid-space-72 > .grid__item {
        padding-left: 4.5rem;
        margin-top: 4.5rem;
    }
    .tablet--grid-space-80 {
        margin-left: -5rem;
        margin-top: -5rem;
    }
    .tablet--grid-space-80 > .grid__item {
        padding-left: 5rem;
        margin-top: 5rem;
    }
    .tablet--grid-space-vertical-0 {
        margin-top: 0;
    }
    .tablet--grid-space-vertical-0 > .grid__item {
        margin-top: 0;
    }
    .tablet--grid-space-vertical-8 {
        margin-top: -0.5rem;
    }
    .tablet--grid-space-vertical-8 > .grid__item {
        margin-top: 0.5rem;
    }
    .tablet--grid-space-vertical-16 {
        margin-top: -1rem;
    }
    .tablet--grid-space-vertical-16 > .grid__item {
        margin-top: 1rem;
    }
    .tablet--grid-space-vertical-24 {
        margin-top: -1.5rem;
    }
    .tablet--grid-space-vertical-24 > .grid__item {
        margin-top: 1.5rem;
    }
    .tablet--grid-space-vertical-32 {
        margin-left: -2rem;
    }
    .tablet--grid-space-vertical-32 > .grid__item {
        margin-top: 2rem;
    }
    .tablet--grid-space-vertical-40 {
        margin-top: -2.5rem;
    }
    .tablet--grid-space-vertical-40 > .grid__item {
        margin-top: 2.5rem;
    }
    .tablet--grid-space-vertical-48 {
        margin-top: -3rem;
    }
    .tablet--grid-space-vertical-48 > .grid__item {
        margin-top: 3rem;
    }
    .tablet--grid-space-vertical-56 {
        margin-top: -3.5rem;
    }
    .tablet--grid-space-vertical-56 > .grid__item {
        margin-top: 3.5rem;
    }
    .tablet--grid-space-vertical-64 {
        margin-top: -4rem;
    }
    .tablet--grid-space-vertical-64 > .grid__item {
        margin-top: 4rem;
    }
    .tablet--grid-space-vertical-72 {
        margin-top: -4.5rem;
    }
    .tablet--grid-space-vertical-72 > .grid__item {
        margin-top: 4.5rem;
    }
    .tablet--grid-space-vertical-80 {
        margin-top: -5rem;
    }
    .tablet--grid-space-vertical-80 > .grid__item {
        margin-top: 5rem;
    }
    .tablet--grid--left {
        justify-content: flex-start;
    }
    .tablet--grid--center {
        justify-content: center;
    }
    .tablet--grid--right {
        justify-content: flex-end;
    }
    .tablet--grid--top {
        align-items: flex-start;
    }
    .tablet--grid--middle {
        align-items: center;
    }
    .tablet--grid--bottom {
        align-items: flex-end;
    }
    .tablet--grid--same-height {
        align-items: stretch;
    }
    .tablet--grid--same-height > .grid__item > * {
        min-height: 100%;
        height: 100%;
    }
    .tablet--grid--reverse {
        flex-direction: row-reverse;
    }
    .tablet--grid--rev {
        flex-wrap: wrap-reverse;
    }
}

@media screen and (max-width: 480px) {
    .smartphone--one-whole {
        width: 100%;
    }
    .smartphone--one-half, .smartphone--two-quarters, .smartphone--three-sixths, .smartphone--four-eighths, .smartphone--five-tenths, .smartphone--six-twelfths {
        width: 50%;
    }
    .smartphone--one-third, .smartphone--two-sixths, .smartphone--four-twelfths {
        width: 33.333%;
    }
    .smartphone--two-thirds, .smartphone--four-sixths, .smartphone--eight-twelfths {
        width: 66.666%;
    }
    .smartphone--one-quarter, .smartphone--two-eighths, .smartphone--three-twelfths {
        width: 25%;
    }
    .smartphone--three-quarters, .smartphone--six-eighths, .smartphone--nine-twelfths {
        width: 75%;
    }
    .smartphone--one-fifth, .smartphone--two-tenths {
        width: 20%;
    }
    .smartphone--two-fifths, .smartphone--four-tenths {
        width: 40%;
    }
    .smartphone--three-fifths, .smartphone--six-tenths {
        width: 60%;
    }
    .smartphone--four-fifths, .smartphone--eight-tenths {
        width: 80%;
    }
    .smartphone--one-sixth, .smartphone--two-twelfths {
        width: 16.666%;
    }
    .smartphone--five-sixths, .smartphone--ten-twelfths {
        width: 83.333%;
    }
    .smartphone--one-eighth {
        width: 12.5%;
    }
    .smartphone--three-eighths {
        width: 37.5%;
    }
    .smartphone--five-eighths {
        width: 62.5%;
    }
    .smartphone--seven-eighths {
        width: 87.5%;
    }
    .smartphone--one-tenth {
        width: 10%;
    }
    .smartphone--three-tenths {
        width: 30%;
    }
    .smartphone--seven-tenths {
        width: 70%;
    }
    .smartphone--nine-tenths {
        width: 90%;
    }
    .smartphone--one-twelfth {
        width: 8.333%;
    }
    .smartphone--five-twelfths {
        width: 41.666%;
    }
    .smartphone--seven-twelfths {
        width: 58.333%;
    }
    .smartphone--eleven-twelfths {
        width: 91.666%;
    }
    .smartphone--grid-space-0 {
        margin-left: 0;
        margin-top: 0;
    }
    .smartphone--grid-space-0 > .grid__item {
        padding-left: 0;
        margin-top: 0;
    }
    .smartphone--grid-space-8 {
        margin-left: -0.5rem;
        margin-top: -0.5rem;
    }
    .smartphone--grid-space-8 > .grid__item {
        padding-left: 0.5rem;
        margin-top: 0.5rem;
    }
    .smartphone--grid-space-16 {
        margin-left: -1rem;
        margin-top: -1rem;
    }
    .smartphone--grid-space-16 > .grid__item {
        padding-left: 1rem;
        margin-top: 1rem;
    }
    .smartphone--grid-space-24 {
        margin-left: -1.5rem;
        margin-top: -1.5rem;
    }
    .smartphone--grid-space-24 > .grid__item {
        padding-left: 1.5rem;
        margin-top: 1.5rem;
    }
    .smartphone--grid-space-32 {
        margin-left: -2rem;
        margin-top: -2rem;
    }
    .smartphone--grid-space-32 > .grid__item {
        padding-left: 2rem;
        margin-top: 2rem;
    }
    .smartphone--grid-space-40 {
        margin-left: -2.5rem;
        margin-top: -2.5rem;
    }
    .smartphone--grid-space-40 > .grid__item {
        padding-left: 2.5rem;
        margin-top: 2.5rem;
    }
    .smartphone--grid-space-48 {
        margin-left: -3rem;
        margin-top: -3rem;
    }
    .smartphone--grid-space-48 > .grid__item {
        padding-left: 3rem;
        margin-top: 3rem;
    }
    .smartphone--grid-space-56 {
        margin-left: -3.5rem;
        margin-top: -3.5rem;
    }
    .smartphone--grid-space-56 > .grid__item {
        padding-left: 3.5rem;
        margin-top: 3.5rem;
    }
    .smartphone--grid-space-64 {
        margin-left: -4rem;
        margin-top: -4rem;
    }
    .smartphone--grid-space-64 > .grid__item {
        padding-left: 4rem;
        margin-top: 4rem;
    }
    .smartphone--grid-space-72 {
        margin-left: -4.5rem;
        margin-top: -4.5rem;
    }
    .smartphone--grid-space-72 > .grid__item {
        padding-left: 4.5rem;
        margin-top: 4.5rem;
    }
    .smartphone--grid-space-80 {
        margin-left: -5rem;
        margin-top: -5rem;
    }
    .smartphone--grid-space-80 > .grid__item {
        padding-left: 5rem;
        margin-top: 5rem;
    }
    .smartphone--grid-space-vertical-0 {
        margin-top: 0;
    }
    .smartphone--grid-space-vertical-0 > .grid__item {
        margin-top: 0;
    }
    .smartphone--grid-space-vertical-8 {
        margin-top: -0.5rem;
    }
    .smartphone--grid-space-vertical-8 > .grid__item {
        margin-top: 0.5rem;
    }
    .smartphone--grid-space-vertical-16 {
        margin-top: -1rem;
    }
    .smartphone--grid-space-vertical-16 > .grid__item {
        margin-top: 1rem;
    }
    .smartphone--grid-space-vertical-24 {
        margin-top: -1.5rem;
    }
    .smartphone--grid-space-vertical-24 > .grid__item {
        margin-top: 1.5rem;
    }
    .smartphone--grid-space-vertical-32 {
        margin-left: -2rem;
    }
    .smartphone--grid-space-vertical-32 > .grid__item {
        margin-top: 2rem;
    }
    .smartphone--grid-space-vertical-40 {
        margin-top: -2.5rem;
    }
    .smartphone--grid-space-vertical-40 > .grid__item {
        margin-top: 2.5rem;
    }
    .smartphone--grid-space-vertical-48 {
        margin-top: -3rem;
    }
    .smartphone--grid-space-vertical-48 > .grid__item {
        margin-top: 3rem;
    }
    .smartphone--grid-space-vertical-56 {
        margin-top: -3.5rem;
    }
    .smartphone--grid-space-vertical-56 > .grid__item {
        margin-top: 3.5rem;
    }
    .smartphone--grid-space-vertical-64 {
        margin-top: -4rem;
    }
    .smartphone--grid-space-vertical-64 > .grid__item {
        margin-top: 4rem;
    }
    .smartphone--grid-space-vertical-72 {
        margin-top: -4.5rem;
    }
    .smartphone--grid-space-vertical-72 > .grid__item {
        margin-top: 4.5rem;
    }
    .smartphone--grid-space-vertical-80 {
        margin-top: -5rem;
    }
    .smartphone--grid-space-vertical-80 > .grid__item {
        margin-top: 5rem;
    }
    .smartphone--grid--left {
        justify-content: flex-start;
    }
    .smartphone--grid--center {
        justify-content: center;
    }
    .smartphone--grid--right {
        justify-content: flex-end;
    }
    .smartphone--grid--top {
        align-items: flex-start;
    }
    .smartphone--grid--middle {
        align-items: center;
    }
    .smartphone--grid--bottom {
        align-items: flex-end;
    }
    .smartphone--grid--same-height {
        align-items: stretch;
    }
    .smartphone--grid--same-height > .grid__item > * {
        min-height: 100%;
        height: 100%;
    }
    .smartphone--grid--reverse {
        flex-direction: row-reverse;
    }
    .smartphone--grid--rev {
        flex-wrap: wrap-reverse;
    }
}

.page-centered {
    max-width: 1790px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 768px) {
    .page-centered {
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}

.no-space-left {
    padding-left: 0;
    margin-left: 0;
}

.no-space-top {
    padding-top: 0;
    margin-top: 0;
}

.no-space-bottom {
    padding-bottom: 0;
    margin-bottom: 0;
}

.no-space-right {
    padding-right: 0;
    margin-right: 0;
}

.space-left-8 {
    padding-left: 0.5rem;
}

.space-top-8 {
    padding-top: 0.5rem;
}

.space-bottom-8 {
    padding-bottom: 0.5rem;
}

.space-right-8 {
    padding-right: 0.5rem;
}

.space-left-16 {
    padding-left: 1rem;
}

.space-top-16 {
    padding-top: 1rem;
}

.space-bottom-16 {
    padding-bottom: 1rem;
}

.space-right-16 {
    padding-right: 1rem;
}

.space-left-24 {
    padding-left: 1.5rem;
}

.space-top-24 {
    padding-top: 1.5rem;
}

.space-bottom-24 {
    padding-bottom: 1.5rem;
}

.space-right-24 {
    padding-right: 1.5rem;
}

.space-left-32 {
    padding-left: 2rem;
}

.space-top-32 {
    padding-top: 2rem;
}

.space-bottom-32 {
    padding-bottom: 2rem;
}

.space-right-32 {
    padding-right: 2rem;
}

.space-left-40 {
    padding-left: 2.5rem;
}

.space-top-40 {
    padding-top: 2.5rem;
}

.space-bottom-40 {
    padding-bottom: 2.5rem;
}

.space-right-40 {
    padding-right: 2.5rem;
}

.space-left-48 {
    padding-left: 3rem;
}

.space-top-48 {
    padding-top: 3rem;
}

.space-bottom-48 {
    padding-bottom: 3rem;
}

.space-right-48 {
    padding-right: 3rem;
}

.space-left-56 {
    padding-left: 3.5rem;
}

.space-top-56 {
    padding-top: 3.5rem;
}

.space-bottom-56 {
    padding-bottom: 3.5rem;
}

.space-right-56 {
    padding-right: 3.5rem;
}

.space-left-64 {
    padding-left: 4rem;
}

.space-top-64 {
    padding-top: 4rem;
}

.space-bottom-64 {
    padding-bottom: 4rem;
}

.space-right-64 {
    padding-right: 4rem;
}

.space-left-72 {
    padding-left: 4.5rem;
}

.space-top-72 {
    padding-top: 4.5rem;
}

.space-bottom-72 {
    padding-bottom: 4.5rem;
}

.space-right-72 {
    padding-right: 4.5rem;
}

.space-left-80 {
    padding-left: 5rem;
}

.space-top-80 {
    padding-top: 5rem;
}

.space-bottom-80 {
    padding-bottom: 5rem;
}

.space-right-80 {
    padding-right: 5rem;
}

.space-left-88 {
    padding-left: 5.5rem;
}

.space-top-88 {
    padding-top: 5.5rem;
}

.space-bottom-88 {
    padding-bottom: 5.5rem;
}

.space-right-88 {
    padding-right: 5.5rem;
}

.space-left-96 {
    padding-left: 6rem;
}

.space-top-96 {
    padding-top: 6rem;
}

.space-bottom-96 {
    padding-bottom: 6rem;
}

.space-right-96 {
    padding-right: 6rem;
}

.space-left-104 {
    padding-left: 6.5rem;
}

.space-top-104 {
    padding-top: 6.5rem;
}

.space-bottom-104 {
    padding-bottom: 6.5rem;
}

.space-right-104 {
    padding-right: 6.5rem;
}

.space-left-112 {
    padding-left: 7rem;
}

.space-top-112 {
    padding-top: 7rem;
}

.space-bottom-112 {
    padding-bottom: 7rem;
}

.space-right-112 {
    padding-right: 7rem;
}

.space-left-120 {
    padding-left: 7.5rem;
}

.space-top-120 {
    padding-top: 7.5rem;
}

.space-bottom-120 {
    padding-bottom: 7.5rem;
}

.space-right-120 {
    padding-right: 7.5rem;
}

.space-left-128 {
    padding-left: 8rem;
}

.space-top-128 {
    padding-top: 8rem;
}

.space-bottom-128 {
    padding-bottom: 8rem;
}

.space-right-128 {
    padding-right: 8rem;
}

.space-left-136 {
    padding-left: 8.5rem;
}

.space-top-136 {
    padding-top: 8.5rem;
}

.space-bottom-136 {
    padding-bottom: 8.5rem;
}

.space-right-136 {
    padding-right: 8.5rem;
}

.space-left-144 {
    padding-left: 9rem;
}

.space-top-144 {
    padding-top: 9rem;
}

.space-bottom-144 {
    padding-bottom: 9rem;
}

.space-right-144 {
    padding-right: 9rem;
}

.space-left-152 {
    padding-left: 9.5rem;
}

.space-top-152 {
    padding-top: 9.5rem;
}

.space-bottom-152 {
    padding-bottom: 9.5rem;
}

.space-right-152 {
    padding-right: 9.5rem;
}

.space-left-160 {
    padding-left: 10rem;
}

.space-top-160 {
    padding-top: 10rem;
}

.space-bottom-160 {
    padding-bottom: 10rem;
}

.space-right-160 {
    padding-right: 10rem;
}

.outer-space-left--16,
.space-left--16 {
    margin-left: -1rem;
}

.outer-space-top--16,
.space-top--16 {
    margin-top: -1rem;
}

.outer-space-bottom--16,
.space-bottom--16 {
    margin-bottom: -1rem;
}

.outer-space-right--16,
.space-right--16 {
    margin-right: -1rem;
}

.outer-space-left--12,
.space-left--12 {
    margin-left: -0.75rem;
}

.outer-space-top--12,
.space-top--12 {
    margin-top: -0.75rem;
}

.outer-space-bottom--12,
.space-bottom--12 {
    margin-bottom: -0.75rem;
}

.outer-space-right--12,
.space-right--12 {
    margin-right: -0.75rem;
}

.outer-space-left--8,
.space-left--8 {
    margin-left: -0.5rem;
}

.outer-space-top--8,
.space-top--8 {
    margin-top: -0.5rem;
}

.outer-space-bottom--8,
.space-bottom--8 {
    margin-bottom: -0.5rem;
}

.outer-space-right--8,
.space-right--8 {
    margin-right: -0.5rem;
}

.outer-space-left--4,
.space-left--4 {
    margin-left: -0.25rem;
}

.outer-space-top--4,
.space-top--4 {
    margin-top: -0.25rem;
}

.outer-space-bottom--4,
.space-bottom--4 {
    margin-bottom: -0.25rem;
}

.outer-space-right--4,
.space-right--4 {
    margin-right: -0.25rem;
}

.outer-space-left-8 {
    margin-left: 0.5rem;
}

.outer-space-top-8 {
    margin-top: 0.5rem;
}

.outer-space-bottom-8 {
    margin-bottom: 0.5rem;
}

.outer-space-right-8 {
    margin-right: 0.5rem;
}

.outer-space-left-16 {
    margin-left: 1rem;
}

.outer-space-top-16 {
    margin-top: 1rem;
}

.outer-space-bottom-16 {
    margin-bottom: 1rem;
}

.outer-space-right-16 {
    margin-right: 1rem;
}

.outer-space-left-24 {
    margin-left: 1.5rem;
}

.outer-space-top-24 {
    margin-top: 1.5rem;
}

.outer-space-bottom-24 {
    margin-bottom: 1.5rem;
}

.outer-space-right-24 {
    margin-right: 1.5rem;
}

.outer-space-left-32 {
    margin-left: 2rem;
}

.outer-space-top-32 {
    margin-top: 2rem;
}

.outer-space-bottom-32 {
    margin-bottom: 2rem;
}

.outer-space-right-32 {
    margin-right: 2rem;
}

.outer-space-left-40 {
    margin-left: 2.5rem;
}

.outer-space-top-40 {
    margin-top: 2.5rem;
}

.outer-space-bottom-40 {
    margin-bottom: 2.5rem;
}

.outer-space-right-40 {
    margin-right: 2.5rem;
}

.outer-space-left-48 {
    margin-left: 3rem;
}

.outer-space-top-48 {
    margin-top: 3rem;
}

.outer-space-bottom-48 {
    margin-bottom: 3rem;
}

.outer-space-right-48 {
    margin-right: 3rem;
}

.outer-space-left-56 {
    margin-left: 3.5rem;
}

.outer-space-top-56 {
    margin-top: 3.5rem;
}

.outer-space-bottom-56 {
    margin-bottom: 3.5rem;
}

.outer-space-right-56 {
    margin-right: 3.5rem;
}

.outer-space-left-64 {
    margin-left: 4rem;
}

.outer-space-top-64 {
    margin-top: 4rem;
}

.outer-space-bottom-64 {
    margin-bottom: 4rem;
}

.outer-space-right-64 {
    margin-right: 4rem;
}

.outer-space-left-72 {
    margin-left: 4.5rem;
}

.outer-space-top-72 {
    margin-top: 4.5rem;
}

.outer-space-bottom-72 {
    margin-bottom: 4.5rem;
}

.outer-space-right-72 {
    margin-right: 4.5rem;
}

.outer-space-left-80 {
    margin-left: 5rem;
}

.outer-space-top-80 {
    margin-top: 5rem;
}

.outer-space-bottom-80 {
    margin-bottom: 5rem;
}

.outer-space-right-80 {
    margin-right: 5rem;
}

.outer-space-left-88 {
    margin-left: 5.5rem;
}

.outer-space-top-88 {
    margin-top: 5.5rem;
}

.outer-space-bottom-88 {
    margin-bottom: 5.5rem;
}

.outer-space-right-88 {
    margin-right: 5.5rem;
}

.outer-space-left-96 {
    margin-left: 6rem;
}

.outer-space-top-96 {
    margin-top: 6rem;
}

.outer-space-bottom-96 {
    margin-bottom: 6rem;
}

.outer-space-right-96 {
    margin-right: 6rem;
}

.outer-space-left-104 {
    margin-left: 6.5rem;
}

.outer-space-top-104 {
    margin-top: 6.5rem;
}

.outer-space-bottom-104 {
    margin-bottom: 6.5rem;
}

.outer-space-right-104 {
    margin-right: 6.5rem;
}

.outer-space-left-112 {
    margin-left: 7rem;
}

.outer-space-top-112 {
    margin-top: 7rem;
}

.outer-space-bottom-112 {
    margin-bottom: 7rem;
}

.outer-space-right-112 {
    margin-right: 7rem;
}

.outer-space-left-120 {
    margin-left: 7.5rem;
}

.outer-space-top-120 {
    margin-top: 7.5rem;
}

.outer-space-bottom-120 {
    margin-bottom: 7.5rem;
}

.outer-space-right-120 {
    margin-right: 7.5rem;
}

.outer-space-left-128 {
    margin-left: 8rem;
}

.outer-space-top-128 {
    margin-top: 8rem;
}

.outer-space-bottom-128 {
    margin-bottom: 8rem;
}

.outer-space-right-128 {
    margin-right: 8rem;
}

.outer-space-left-136 {
    margin-left: 8.5rem;
}

.outer-space-top-136 {
    margin-top: 8.5rem;
}

.outer-space-bottom-136 {
    margin-bottom: 8.5rem;
}

.outer-space-right-136 {
    margin-right: 8.5rem;
}

.outer-space-left-144 {
    margin-left: 9rem;
}

.outer-space-top-144 {
    margin-top: 9rem;
}

.outer-space-bottom-144 {
    margin-bottom: 9rem;
}

.outer-space-right-144 {
    margin-right: 9rem;
}

.outer-space-left-152 {
    margin-left: 9.5rem;
}

.outer-space-top-152 {
    margin-top: 9.5rem;
}

.outer-space-bottom-152 {
    margin-bottom: 9.5rem;
}

.outer-space-right-152 {
    margin-right: 9.5rem;
}

.outer-space-left-160 {
    margin-left: 10rem;
}

.outer-space-top-160 {
    margin-top: 10rem;
}

.outer-space-bottom-160 {
    margin-bottom: 10rem;
}

.outer-space-right-160 {
    margin-right: 10rem;
}

@media screen and (min-width: 1281px) {
    .desktop--display-none {
        display: none;
    }
    .desktop--display-block {
        display: block;
    }
    .desktop--display-inline-block {
        display: inline-block;
    }
    .desktop--no-space-left {
        padding-left: 0;
        margin-left: 0;
    }
    .desktop--no-space-top {
        padding-top: 0;
        margin-top: 0;
    }
    .desktop--no-space-bottom {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .desktop--no-space-right {
        padding-right: 0;
        margin-right: 0;
    }
    .desktop--space-left-8 {
        padding-left: 0.5rem;
    }
    .desktop--space-top-8 {
        padding-top: 0.5rem;
    }
    .desktop--space-bottom-8 {
        padding-bottom: 0.5rem;
    }
    .desktop--space-right-8 {
        padding-right: 0.5rem;
    }
    .desktop--space-left-16 {
        padding-left: 1rem;
    }
    .desktop--space-top-16 {
        padding-top: 1rem;
    }
    .desktop--space-bottom-16 {
        padding-bottom: 1rem;
    }
    .desktop--space-right-16 {
        padding-right: 1rem;
    }
    .desktop--space-left-24 {
        padding-left: 1.5rem;
    }
    .desktop--space-top-24 {
        padding-top: 1.5rem;
    }
    .desktop--space-bottom-24 {
        padding-bottom: 1.5rem;
    }
    .desktop--space-right-24 {
        padding-right: 1.5rem;
    }
    .desktop--space-left-32 {
        padding-left: 2rem;
    }
    .desktop--space-top-32 {
        padding-top: 2rem;
    }
    .desktop--space-bottom-32 {
        padding-bottom: 2rem;
    }
    .desktop--space-right-32 {
        padding-right: 2rem;
    }
    .desktop--space-left-40 {
        padding-left: 2.5rem;
    }
    .desktop--space-top-40 {
        padding-top: 2.5rem;
    }
    .desktop--space-bottom-40 {
        padding-bottom: 2.5rem;
    }
    .desktop--space-right-40 {
        padding-right: 2.5rem;
    }
    .desktop--space-left-48 {
        padding-left: 3rem;
    }
    .desktop--space-top-48 {
        padding-top: 3rem;
    }
    .desktop--space-bottom-48 {
        padding-bottom: 3rem;
    }
    .desktop--space-right-48 {
        padding-right: 3rem;
    }
    .desktop--space-left-56 {
        padding-left: 3.5rem;
    }
    .desktop--space-top-56 {
        padding-top: 3.5rem;
    }
    .desktop--space-bottom-56 {
        padding-bottom: 3.5rem;
    }
    .desktop--space-right-56 {
        padding-right: 3.5rem;
    }
    .desktop--space-left-64 {
        padding-left: 4rem;
    }
    .desktop--space-top-64 {
        padding-top: 4rem;
    }
    .desktop--space-bottom-64 {
        padding-bottom: 4rem;
    }
    .desktop--space-right-64 {
        padding-right: 4rem;
    }
    .desktop--space-left-72 {
        padding-left: 4.5rem;
    }
    .desktop--space-top-72 {
        padding-top: 4.5rem;
    }
    .desktop--space-bottom-72 {
        padding-bottom: 4.5rem;
    }
    .desktop--space-right-72 {
        padding-right: 4.5rem;
    }
    .desktop--space-left-80 {
        padding-left: 5rem;
    }
    .desktop--space-top-80 {
        padding-top: 5rem;
    }
    .desktop--space-bottom-80 {
        padding-bottom: 5rem;
    }
    .desktop--space-right-80 {
        padding-right: 5rem;
    }
    .desktop--space-left-88 {
        padding-left: 5.5rem;
    }
    .desktop--space-top-88 {
        padding-top: 5.5rem;
    }
    .desktop--space-bottom-88 {
        padding-bottom: 5.5rem;
    }
    .desktop--space-right-88 {
        padding-right: 5.5rem;
    }
    .desktop--space-left-96 {
        padding-left: 6rem;
    }
    .desktop--space-top-96 {
        padding-top: 6rem;
    }
    .desktop--space-bottom-96 {
        padding-bottom: 6rem;
    }
    .desktop--space-right-96 {
        padding-right: 6rem;
    }
    .desktop--space-left-104 {
        padding-left: 6.5rem;
    }
    .desktop--space-top-104 {
        padding-top: 6.5rem;
    }
    .desktop--space-bottom-104 {
        padding-bottom: 6.5rem;
    }
    .desktop--space-right-104 {
        padding-right: 6.5rem;
    }
    .desktop--space-left-112 {
        padding-left: 7rem;
    }
    .desktop--space-top-112 {
        padding-top: 7rem;
    }
    .desktop--space-bottom-112 {
        padding-bottom: 7rem;
    }
    .desktop--space-right-112 {
        padding-right: 7rem;
    }
    .desktop--space-left-120 {
        padding-left: 7.5rem;
    }
    .desktop--space-top-120 {
        padding-top: 7.5rem;
    }
    .desktop--space-bottom-120 {
        padding-bottom: 7.5rem;
    }
    .desktop--space-right-120 {
        padding-right: 7.5rem;
    }
    .desktop--space-left-128 {
        padding-left: 8rem;
    }
    .desktop--space-top-128 {
        padding-top: 8rem;
    }
    .desktop--space-bottom-128 {
        padding-bottom: 8rem;
    }
    .desktop--space-right-128 {
        padding-right: 8rem;
    }
    .desktop--space-left-136 {
        padding-left: 8.5rem;
    }
    .desktop--space-top-136 {
        padding-top: 8.5rem;
    }
    .desktop--space-bottom-136 {
        padding-bottom: 8.5rem;
    }
    .desktop--space-right-136 {
        padding-right: 8.5rem;
    }
    .desktop--space-left-144 {
        padding-left: 9rem;
    }
    .desktop--space-top-144 {
        padding-top: 9rem;
    }
    .desktop--space-bottom-144 {
        padding-bottom: 9rem;
    }
    .desktop--space-right-144 {
        padding-right: 9rem;
    }
    .desktop--space-left-152 {
        padding-left: 9.5rem;
    }
    .desktop--space-top-152 {
        padding-top: 9.5rem;
    }
    .desktop--space-bottom-152 {
        padding-bottom: 9.5rem;
    }
    .desktop--space-right-152 {
        padding-right: 9.5rem;
    }
    .desktop--space-left-160 {
        padding-left: 10rem;
    }
    .desktop--space-top-160 {
        padding-top: 10rem;
    }
    .desktop--space-bottom-160 {
        padding-bottom: 10rem;
    }
    .desktop--space-right-160 {
        padding-right: 10rem;
    }
    .desktop--outer-space-left--16,
    .desktop--space-left--16 {
        margin-left: -1rem;
    }
    .desktop--outer-space-top--16,
    .desktop--space-top--16 {
        margin-top: -1rem;
    }
    .desktop--outer-space-bottom--16,
    .desktop--space-bottom--16 {
        margin-bottom: -1rem;
    }
    .desktop--outer-space-right--16,
    .desktop--space-right--16 {
        margin-right: -1rem;
    }
    .desktop--outer-space-left--12,
    .desktop--space-left--12 {
        margin-left: -0.75rem;
    }
    .desktop--outer-space-top--12,
    .desktop--space-top--12 {
        margin-top: -0.75rem;
    }
    .desktop--outer-space-bottom--12,
    .desktop--space-bottom--12 {
        margin-bottom: -0.75rem;
    }
    .desktop--outer-space-right--12,
    .desktop--space-right--12 {
        margin-right: -0.75rem;
    }
    .desktop--outer-space-left--8,
    .desktop--space-left--8 {
        margin-left: -0.5rem;
    }
    .desktop--outer-space-top--8,
    .desktop--space-top--8 {
        margin-top: -0.5rem;
    }
    .desktop--outer-space-bottom--8,
    .desktop--space-bottom--8 {
        margin-bottom: -0.5rem;
    }
    .desktop--outer-space-right--8,
    .desktop--space-right--8 {
        margin-right: -0.5rem;
    }
    .desktop--outer-space-left--4,
    .desktop--space-left--4 {
        margin-left: -0.25rem;
    }
    .desktop--outer-space-top--4,
    .desktop--space-top--4 {
        margin-top: -0.25rem;
    }
    .desktop--outer-space-bottom--4,
    .desktop--space-bottom--4 {
        margin-bottom: -0.25rem;
    }
    .desktop--outer-space-right--4,
    .desktop--space-right--4 {
        margin-right: -0.25rem;
    }
    .desktop--outer-space-left-8 {
        margin-left: 0.5rem;
    }
    .desktop--outer-space-top-8 {
        margin-top: 0.5rem;
    }
    .desktop--outer-space-bottom-8 {
        margin-bottom: 0.5rem;
    }
    .desktop--outer-space-right-8 {
        margin-right: 0.5rem;
    }
    .desktop--outer-space-left-16 {
        margin-left: 1rem;
    }
    .desktop--outer-space-top-16 {
        margin-top: 1rem;
    }
    .desktop--outer-space-bottom-16 {
        margin-bottom: 1rem;
    }
    .desktop--outer-space-right-16 {
        margin-right: 1rem;
    }
    .desktop--outer-space-left-24 {
        margin-left: 1.5rem;
    }
    .desktop--outer-space-top-24 {
        margin-top: 1.5rem;
    }
    .desktop--outer-space-bottom-24 {
        margin-bottom: 1.5rem;
    }
    .desktop--outer-space-right-24 {
        margin-right: 1.5rem;
    }
    .desktop--outer-space-left-32 {
        margin-left: 2rem;
    }
    .desktop--outer-space-top-32 {
        margin-top: 2rem;
    }
    .desktop--outer-space-bottom-32 {
        margin-bottom: 2rem;
    }
    .desktop--outer-space-right-32 {
        margin-right: 2rem;
    }
    .desktop--outer-space-left-40 {
        margin-left: 2.5rem;
    }
    .desktop--outer-space-top-40 {
        margin-top: 2.5rem;
    }
    .desktop--outer-space-bottom-40 {
        margin-bottom: 2.5rem;
    }
    .desktop--outer-space-right-40 {
        margin-right: 2.5rem;
    }
    .desktop--outer-space-left-48 {
        margin-left: 3rem;
    }
    .desktop--outer-space-top-48 {
        margin-top: 3rem;
    }
    .desktop--outer-space-bottom-48 {
        margin-bottom: 3rem;
    }
    .desktop--outer-space-right-48 {
        margin-right: 3rem;
    }
    .desktop--outer-space-left-56 {
        margin-left: 3.5rem;
    }
    .desktop--outer-space-top-56 {
        margin-top: 3.5rem;
    }
    .desktop--outer-space-bottom-56 {
        margin-bottom: 3.5rem;
    }
    .desktop--outer-space-right-56 {
        margin-right: 3.5rem;
    }
    .desktop--outer-space-left-64 {
        margin-left: 4rem;
    }
    .desktop--outer-space-top-64 {
        margin-top: 4rem;
    }
    .desktop--outer-space-bottom-64 {
        margin-bottom: 4rem;
    }
    .desktop--outer-space-right-64 {
        margin-right: 4rem;
    }
    .desktop--outer-space-left-72 {
        margin-left: 4.5rem;
    }
    .desktop--outer-space-top-72 {
        margin-top: 4.5rem;
    }
    .desktop--outer-space-bottom-72 {
        margin-bottom: 4.5rem;
    }
    .desktop--outer-space-right-72 {
        margin-right: 4.5rem;
    }
    .desktop--outer-space-left-80 {
        margin-left: 5rem;
    }
    .desktop--outer-space-top-80 {
        margin-top: 5rem;
    }
    .desktop--outer-space-bottom-80 {
        margin-bottom: 5rem;
    }
    .desktop--outer-space-right-80 {
        margin-right: 5rem;
    }
    .desktop--outer-space-left-88 {
        margin-left: 5.5rem;
    }
    .desktop--outer-space-top-88 {
        margin-top: 5.5rem;
    }
    .desktop--outer-space-bottom-88 {
        margin-bottom: 5.5rem;
    }
    .desktop--outer-space-right-88 {
        margin-right: 5.5rem;
    }
    .desktop--outer-space-left-96 {
        margin-left: 6rem;
    }
    .desktop--outer-space-top-96 {
        margin-top: 6rem;
    }
    .desktop--outer-space-bottom-96 {
        margin-bottom: 6rem;
    }
    .desktop--outer-space-right-96 {
        margin-right: 6rem;
    }
    .desktop--outer-space-left-104 {
        margin-left: 6.5rem;
    }
    .desktop--outer-space-top-104 {
        margin-top: 6.5rem;
    }
    .desktop--outer-space-bottom-104 {
        margin-bottom: 6.5rem;
    }
    .desktop--outer-space-right-104 {
        margin-right: 6.5rem;
    }
    .desktop--outer-space-left-112 {
        margin-left: 7rem;
    }
    .desktop--outer-space-top-112 {
        margin-top: 7rem;
    }
    .desktop--outer-space-bottom-112 {
        margin-bottom: 7rem;
    }
    .desktop--outer-space-right-112 {
        margin-right: 7rem;
    }
    .desktop--outer-space-left-120 {
        margin-left: 7.5rem;
    }
    .desktop--outer-space-top-120 {
        margin-top: 7.5rem;
    }
    .desktop--outer-space-bottom-120 {
        margin-bottom: 7.5rem;
    }
    .desktop--outer-space-right-120 {
        margin-right: 7.5rem;
    }
    .desktop--outer-space-left-128 {
        margin-left: 8rem;
    }
    .desktop--outer-space-top-128 {
        margin-top: 8rem;
    }
    .desktop--outer-space-bottom-128 {
        margin-bottom: 8rem;
    }
    .desktop--outer-space-right-128 {
        margin-right: 8rem;
    }
    .desktop--outer-space-left-136 {
        margin-left: 8.5rem;
    }
    .desktop--outer-space-top-136 {
        margin-top: 8.5rem;
    }
    .desktop--outer-space-bottom-136 {
        margin-bottom: 8.5rem;
    }
    .desktop--outer-space-right-136 {
        margin-right: 8.5rem;
    }
    .desktop--outer-space-left-144 {
        margin-left: 9rem;
    }
    .desktop--outer-space-top-144 {
        margin-top: 9rem;
    }
    .desktop--outer-space-bottom-144 {
        margin-bottom: 9rem;
    }
    .desktop--outer-space-right-144 {
        margin-right: 9rem;
    }
    .desktop--outer-space-left-152 {
        margin-left: 9.5rem;
    }
    .desktop--outer-space-top-152 {
        margin-top: 9.5rem;
    }
    .desktop--outer-space-bottom-152 {
        margin-bottom: 9.5rem;
    }
    .desktop--outer-space-right-152 {
        margin-right: 9.5rem;
    }
    .desktop--outer-space-left-160 {
        margin-left: 10rem;
    }
    .desktop--outer-space-top-160 {
        margin-top: 10rem;
    }
    .desktop--outer-space-bottom-160 {
        margin-bottom: 10rem;
    }
    .desktop--outer-space-right-160 {
        margin-right: 10rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .laptop--display-none {
        display: none;
    }
    .laptop--display-block {
        display: block;
    }
    .laptop--display-inline-block {
        display: inline-block;
    }
    .laptop--no-space-left {
        padding-left: 0;
        margin-left: 0;
    }
    .laptop--no-space-top {
        padding-top: 0;
        margin-top: 0;
    }
    .laptop--no-space-bottom {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .laptop--no-space-right {
        padding-right: 0;
        margin-right: 0;
    }
    .laptop--space-left-8 {
        padding-left: 0.5rem;
    }
    .laptop--space-top-8 {
        padding-top: 0.5rem;
    }
    .laptop--space-bottom-8 {
        padding-bottom: 0.5rem;
    }
    .laptop--space-right-8 {
        padding-right: 0.5rem;
    }
    .laptop--space-left-16 {
        padding-left: 1rem;
    }
    .laptop--space-top-16 {
        padding-top: 1rem;
    }
    .laptop--space-bottom-16 {
        padding-bottom: 1rem;
    }
    .laptop--space-right-16 {
        padding-right: 1rem;
    }
    .laptop--space-left-24 {
        padding-left: 1.5rem;
    }
    .laptop--space-top-24 {
        padding-top: 1.5rem;
    }
    .laptop--space-bottom-24 {
        padding-bottom: 1.5rem;
    }
    .laptop--space-right-24 {
        padding-right: 1.5rem;
    }
    .laptop--space-left-32 {
        padding-left: 2rem;
    }
    .laptop--space-top-32 {
        padding-top: 2rem;
    }
    .laptop--space-bottom-32 {
        padding-bottom: 2rem;
    }
    .laptop--space-right-32 {
        padding-right: 2rem;
    }
    .laptop--space-left-40 {
        padding-left: 2.5rem;
    }
    .laptop--space-top-40 {
        padding-top: 2.5rem;
    }
    .laptop--space-bottom-40 {
        padding-bottom: 2.5rem;
    }
    .laptop--space-right-40 {
        padding-right: 2.5rem;
    }
    .laptop--space-left-48 {
        padding-left: 3rem;
    }
    .laptop--space-top-48 {
        padding-top: 3rem;
    }
    .laptop--space-bottom-48 {
        padding-bottom: 3rem;
    }
    .laptop--space-right-48 {
        padding-right: 3rem;
    }
    .laptop--space-left-56 {
        padding-left: 3.5rem;
    }
    .laptop--space-top-56 {
        padding-top: 3.5rem;
    }
    .laptop--space-bottom-56 {
        padding-bottom: 3.5rem;
    }
    .laptop--space-right-56 {
        padding-right: 3.5rem;
    }
    .laptop--space-left-64 {
        padding-left: 4rem;
    }
    .laptop--space-top-64 {
        padding-top: 4rem;
    }
    .laptop--space-bottom-64 {
        padding-bottom: 4rem;
    }
    .laptop--space-right-64 {
        padding-right: 4rem;
    }
    .laptop--space-left-72 {
        padding-left: 4.5rem;
    }
    .laptop--space-top-72 {
        padding-top: 4.5rem;
    }
    .laptop--space-bottom-72 {
        padding-bottom: 4.5rem;
    }
    .laptop--space-right-72 {
        padding-right: 4.5rem;
    }
    .laptop--space-left-80 {
        padding-left: 5rem;
    }
    .laptop--space-top-80 {
        padding-top: 5rem;
    }
    .laptop--space-bottom-80 {
        padding-bottom: 5rem;
    }
    .laptop--space-right-80 {
        padding-right: 5rem;
    }
    .laptop--space-left-88 {
        padding-left: 5.5rem;
    }
    .laptop--space-top-88 {
        padding-top: 5.5rem;
    }
    .laptop--space-bottom-88 {
        padding-bottom: 5.5rem;
    }
    .laptop--space-right-88 {
        padding-right: 5.5rem;
    }
    .laptop--space-left-96 {
        padding-left: 6rem;
    }
    .laptop--space-top-96 {
        padding-top: 6rem;
    }
    .laptop--space-bottom-96 {
        padding-bottom: 6rem;
    }
    .laptop--space-right-96 {
        padding-right: 6rem;
    }
    .laptop--space-left-104 {
        padding-left: 6.5rem;
    }
    .laptop--space-top-104 {
        padding-top: 6.5rem;
    }
    .laptop--space-bottom-104 {
        padding-bottom: 6.5rem;
    }
    .laptop--space-right-104 {
        padding-right: 6.5rem;
    }
    .laptop--space-left-112 {
        padding-left: 7rem;
    }
    .laptop--space-top-112 {
        padding-top: 7rem;
    }
    .laptop--space-bottom-112 {
        padding-bottom: 7rem;
    }
    .laptop--space-right-112 {
        padding-right: 7rem;
    }
    .laptop--space-left-120 {
        padding-left: 7.5rem;
    }
    .laptop--space-top-120 {
        padding-top: 7.5rem;
    }
    .laptop--space-bottom-120 {
        padding-bottom: 7.5rem;
    }
    .laptop--space-right-120 {
        padding-right: 7.5rem;
    }
    .laptop--space-left-128 {
        padding-left: 8rem;
    }
    .laptop--space-top-128 {
        padding-top: 8rem;
    }
    .laptop--space-bottom-128 {
        padding-bottom: 8rem;
    }
    .laptop--space-right-128 {
        padding-right: 8rem;
    }
    .laptop--space-left-136 {
        padding-left: 8.5rem;
    }
    .laptop--space-top-136 {
        padding-top: 8.5rem;
    }
    .laptop--space-bottom-136 {
        padding-bottom: 8.5rem;
    }
    .laptop--space-right-136 {
        padding-right: 8.5rem;
    }
    .laptop--space-left-144 {
        padding-left: 9rem;
    }
    .laptop--space-top-144 {
        padding-top: 9rem;
    }
    .laptop--space-bottom-144 {
        padding-bottom: 9rem;
    }
    .laptop--space-right-144 {
        padding-right: 9rem;
    }
    .laptop--space-left-152 {
        padding-left: 9.5rem;
    }
    .laptop--space-top-152 {
        padding-top: 9.5rem;
    }
    .laptop--space-bottom-152 {
        padding-bottom: 9.5rem;
    }
    .laptop--space-right-152 {
        padding-right: 9.5rem;
    }
    .laptop--space-left-160 {
        padding-left: 10rem;
    }
    .laptop--space-top-160 {
        padding-top: 10rem;
    }
    .laptop--space-bottom-160 {
        padding-bottom: 10rem;
    }
    .laptop--space-right-160 {
        padding-right: 10rem;
    }
    .laptop--outer-space-left--16,
    .laptop--space-left--16 {
        margin-left: -1rem;
    }
    .laptop--outer-space-top--16,
    .laptop--space-top--16 {
        margin-top: -1rem;
    }
    .laptop--outer-space-bottom--16,
    .laptop--space-bottom--16 {
        margin-bottom: -1rem;
    }
    .laptop--outer-space-right--16,
    .laptop--space-right--16 {
        margin-right: -1rem;
    }
    .laptop--outer-space-left--12,
    .laptop--space-left--12 {
        margin-left: -0.75rem;
    }
    .laptop--outer-space-top--12,
    .laptop--space-top--12 {
        margin-top: -0.75rem;
    }
    .laptop--outer-space-bottom--12,
    .laptop--space-bottom--12 {
        margin-bottom: -0.75rem;
    }
    .laptop--outer-space-right--12,
    .laptop--space-right--12 {
        margin-right: -0.75rem;
    }
    .laptop--outer-space-left--8,
    .laptop--space-left--8 {
        margin-left: -0.5rem;
    }
    .laptop--outer-space-top--8,
    .laptop--space-top--8 {
        margin-top: -0.5rem;
    }
    .laptop--outer-space-bottom--8,
    .laptop--space-bottom--8 {
        margin-bottom: -0.5rem;
    }
    .laptop--outer-space-right--8,
    .laptop--space-right--8 {
        margin-right: -0.5rem;
    }
    .laptop--outer-space-left--4,
    .laptop--space-left--4 {
        margin-left: -0.25rem;
    }
    .laptop--outer-space-top--4,
    .laptop--space-top--4 {
        margin-top: -0.25rem;
    }
    .laptop--outer-space-bottom--4,
    .laptop--space-bottom--4 {
        margin-bottom: -0.25rem;
    }
    .laptop--outer-space-right--4,
    .laptop--space-right--4 {
        margin-right: -0.25rem;
    }
    .laptop--outer-space-left-8 {
        margin-left: 0.5rem;
    }
    .laptop--outer-space-top-8 {
        margin-top: 0.5rem;
    }
    .laptop--outer-space-bottom-8 {
        margin-bottom: 0.5rem;
    }
    .laptop--outer-space-right-8 {
        margin-right: 0.5rem;
    }
    .laptop--outer-space-left-16 {
        margin-left: 1rem;
    }
    .laptop--outer-space-top-16 {
        margin-top: 1rem;
    }
    .laptop--outer-space-bottom-16 {
        margin-bottom: 1rem;
    }
    .laptop--outer-space-right-16 {
        margin-right: 1rem;
    }
    .laptop--outer-space-left-24 {
        margin-left: 1.5rem;
    }
    .laptop--outer-space-top-24 {
        margin-top: 1.5rem;
    }
    .laptop--outer-space-bottom-24 {
        margin-bottom: 1.5rem;
    }
    .laptop--outer-space-right-24 {
        margin-right: 1.5rem;
    }
    .laptop--outer-space-left-32 {
        margin-left: 2rem;
    }
    .laptop--outer-space-top-32 {
        margin-top: 2rem;
    }
    .laptop--outer-space-bottom-32 {
        margin-bottom: 2rem;
    }
    .laptop--outer-space-right-32 {
        margin-right: 2rem;
    }
    .laptop--outer-space-left-40 {
        margin-left: 2.5rem;
    }
    .laptop--outer-space-top-40 {
        margin-top: 2.5rem;
    }
    .laptop--outer-space-bottom-40 {
        margin-bottom: 2.5rem;
    }
    .laptop--outer-space-right-40 {
        margin-right: 2.5rem;
    }
    .laptop--outer-space-left-48 {
        margin-left: 3rem;
    }
    .laptop--outer-space-top-48 {
        margin-top: 3rem;
    }
    .laptop--outer-space-bottom-48 {
        margin-bottom: 3rem;
    }
    .laptop--outer-space-right-48 {
        margin-right: 3rem;
    }
    .laptop--outer-space-left-56 {
        margin-left: 3.5rem;
    }
    .laptop--outer-space-top-56 {
        margin-top: 3.5rem;
    }
    .laptop--outer-space-bottom-56 {
        margin-bottom: 3.5rem;
    }
    .laptop--outer-space-right-56 {
        margin-right: 3.5rem;
    }
    .laptop--outer-space-left-64 {
        margin-left: 4rem;
    }
    .laptop--outer-space-top-64 {
        margin-top: 4rem;
    }
    .laptop--outer-space-bottom-64 {
        margin-bottom: 4rem;
    }
    .laptop--outer-space-right-64 {
        margin-right: 4rem;
    }
    .laptop--outer-space-left-72 {
        margin-left: 4.5rem;
    }
    .laptop--outer-space-top-72 {
        margin-top: 4.5rem;
    }
    .laptop--outer-space-bottom-72 {
        margin-bottom: 4.5rem;
    }
    .laptop--outer-space-right-72 {
        margin-right: 4.5rem;
    }
    .laptop--outer-space-left-80 {
        margin-left: 5rem;
    }
    .laptop--outer-space-top-80 {
        margin-top: 5rem;
    }
    .laptop--outer-space-bottom-80 {
        margin-bottom: 5rem;
    }
    .laptop--outer-space-right-80 {
        margin-right: 5rem;
    }
    .laptop--outer-space-left-88 {
        margin-left: 5.5rem;
    }
    .laptop--outer-space-top-88 {
        margin-top: 5.5rem;
    }
    .laptop--outer-space-bottom-88 {
        margin-bottom: 5.5rem;
    }
    .laptop--outer-space-right-88 {
        margin-right: 5.5rem;
    }
    .laptop--outer-space-left-96 {
        margin-left: 6rem;
    }
    .laptop--outer-space-top-96 {
        margin-top: 6rem;
    }
    .laptop--outer-space-bottom-96 {
        margin-bottom: 6rem;
    }
    .laptop--outer-space-right-96 {
        margin-right: 6rem;
    }
    .laptop--outer-space-left-104 {
        margin-left: 6.5rem;
    }
    .laptop--outer-space-top-104 {
        margin-top: 6.5rem;
    }
    .laptop--outer-space-bottom-104 {
        margin-bottom: 6.5rem;
    }
    .laptop--outer-space-right-104 {
        margin-right: 6.5rem;
    }
    .laptop--outer-space-left-112 {
        margin-left: 7rem;
    }
    .laptop--outer-space-top-112 {
        margin-top: 7rem;
    }
    .laptop--outer-space-bottom-112 {
        margin-bottom: 7rem;
    }
    .laptop--outer-space-right-112 {
        margin-right: 7rem;
    }
    .laptop--outer-space-left-120 {
        margin-left: 7.5rem;
    }
    .laptop--outer-space-top-120 {
        margin-top: 7.5rem;
    }
    .laptop--outer-space-bottom-120 {
        margin-bottom: 7.5rem;
    }
    .laptop--outer-space-right-120 {
        margin-right: 7.5rem;
    }
    .laptop--outer-space-left-128 {
        margin-left: 8rem;
    }
    .laptop--outer-space-top-128 {
        margin-top: 8rem;
    }
    .laptop--outer-space-bottom-128 {
        margin-bottom: 8rem;
    }
    .laptop--outer-space-right-128 {
        margin-right: 8rem;
    }
    .laptop--outer-space-left-136 {
        margin-left: 8.5rem;
    }
    .laptop--outer-space-top-136 {
        margin-top: 8.5rem;
    }
    .laptop--outer-space-bottom-136 {
        margin-bottom: 8.5rem;
    }
    .laptop--outer-space-right-136 {
        margin-right: 8.5rem;
    }
    .laptop--outer-space-left-144 {
        margin-left: 9rem;
    }
    .laptop--outer-space-top-144 {
        margin-top: 9rem;
    }
    .laptop--outer-space-bottom-144 {
        margin-bottom: 9rem;
    }
    .laptop--outer-space-right-144 {
        margin-right: 9rem;
    }
    .laptop--outer-space-left-152 {
        margin-left: 9.5rem;
    }
    .laptop--outer-space-top-152 {
        margin-top: 9.5rem;
    }
    .laptop--outer-space-bottom-152 {
        margin-bottom: 9.5rem;
    }
    .laptop--outer-space-right-152 {
        margin-right: 9.5rem;
    }
    .laptop--outer-space-left-160 {
        margin-left: 10rem;
    }
    .laptop--outer-space-top-160 {
        margin-top: 10rem;
    }
    .laptop--outer-space-bottom-160 {
        margin-bottom: 10rem;
    }
    .laptop--outer-space-right-160 {
        margin-right: 10rem;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .tablet--display-none {
        display: none;
    }
    .tablet--display-block {
        display: block;
    }
    .tablet--display-inline-block {
        display: inline-block;
    }
    .tablet--no-space-left {
        padding-left: 0;
        margin-left: 0;
    }
    .tablet--no-space-top {
        padding-top: 0;
        margin-top: 0;
    }
    .tablet--no-space-bottom {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .tablet--no-space-right {
        padding-right: 0;
        margin-right: 0;
    }
    .tablet--space-left-8 {
        padding-left: 0.5rem;
    }
    .tablet--space-top-8 {
        padding-top: 0.5rem;
    }
    .tablet--space-bottom-8 {
        padding-bottom: 0.5rem;
    }
    .tablet--space-right-8 {
        padding-right: 0.5rem;
    }
    .tablet--space-left-16 {
        padding-left: 1rem;
    }
    .tablet--space-top-16 {
        padding-top: 1rem;
    }
    .tablet--space-bottom-16 {
        padding-bottom: 1rem;
    }
    .tablet--space-right-16 {
        padding-right: 1rem;
    }
    .tablet--space-left-24 {
        padding-left: 1.5rem;
    }
    .tablet--space-top-24 {
        padding-top: 1.5rem;
    }
    .tablet--space-bottom-24 {
        padding-bottom: 1.5rem;
    }
    .tablet--space-right-24 {
        padding-right: 1.5rem;
    }
    .tablet--space-left-32 {
        padding-left: 2rem;
    }
    .tablet--space-top-32 {
        padding-top: 2rem;
    }
    .tablet--space-bottom-32 {
        padding-bottom: 2rem;
    }
    .tablet--space-right-32 {
        padding-right: 2rem;
    }
    .tablet--space-left-40 {
        padding-left: 2.5rem;
    }
    .tablet--space-top-40 {
        padding-top: 2.5rem;
    }
    .tablet--space-bottom-40 {
        padding-bottom: 2.5rem;
    }
    .tablet--space-right-40 {
        padding-right: 2.5rem;
    }
    .tablet--space-left-48 {
        padding-left: 3rem;
    }
    .tablet--space-top-48 {
        padding-top: 3rem;
    }
    .tablet--space-bottom-48 {
        padding-bottom: 3rem;
    }
    .tablet--space-right-48 {
        padding-right: 3rem;
    }
    .tablet--space-left-56 {
        padding-left: 3.5rem;
    }
    .tablet--space-top-56 {
        padding-top: 3.5rem;
    }
    .tablet--space-bottom-56 {
        padding-bottom: 3.5rem;
    }
    .tablet--space-right-56 {
        padding-right: 3.5rem;
    }
    .tablet--space-left-64 {
        padding-left: 4rem;
    }
    .tablet--space-top-64 {
        padding-top: 4rem;
    }
    .tablet--space-bottom-64 {
        padding-bottom: 4rem;
    }
    .tablet--space-right-64 {
        padding-right: 4rem;
    }
    .tablet--space-left-72 {
        padding-left: 4.5rem;
    }
    .tablet--space-top-72 {
        padding-top: 4.5rem;
    }
    .tablet--space-bottom-72 {
        padding-bottom: 4.5rem;
    }
    .tablet--space-right-72 {
        padding-right: 4.5rem;
    }
    .tablet--space-left-80 {
        padding-left: 5rem;
    }
    .tablet--space-top-80 {
        padding-top: 5rem;
    }
    .tablet--space-bottom-80 {
        padding-bottom: 5rem;
    }
    .tablet--space-right-80 {
        padding-right: 5rem;
    }
    .tablet--space-left-88 {
        padding-left: 5.5rem;
    }
    .tablet--space-top-88 {
        padding-top: 5.5rem;
    }
    .tablet--space-bottom-88 {
        padding-bottom: 5.5rem;
    }
    .tablet--space-right-88 {
        padding-right: 5.5rem;
    }
    .tablet--space-left-96 {
        padding-left: 6rem;
    }
    .tablet--space-top-96 {
        padding-top: 6rem;
    }
    .tablet--space-bottom-96 {
        padding-bottom: 6rem;
    }
    .tablet--space-right-96 {
        padding-right: 6rem;
    }
    .tablet--space-left-104 {
        padding-left: 6.5rem;
    }
    .tablet--space-top-104 {
        padding-top: 6.5rem;
    }
    .tablet--space-bottom-104 {
        padding-bottom: 6.5rem;
    }
    .tablet--space-right-104 {
        padding-right: 6.5rem;
    }
    .tablet--space-left-112 {
        padding-left: 7rem;
    }
    .tablet--space-top-112 {
        padding-top: 7rem;
    }
    .tablet--space-bottom-112 {
        padding-bottom: 7rem;
    }
    .tablet--space-right-112 {
        padding-right: 7rem;
    }
    .tablet--space-left-120 {
        padding-left: 7.5rem;
    }
    .tablet--space-top-120 {
        padding-top: 7.5rem;
    }
    .tablet--space-bottom-120 {
        padding-bottom: 7.5rem;
    }
    .tablet--space-right-120 {
        padding-right: 7.5rem;
    }
    .tablet--space-left-128 {
        padding-left: 8rem;
    }
    .tablet--space-top-128 {
        padding-top: 8rem;
    }
    .tablet--space-bottom-128 {
        padding-bottom: 8rem;
    }
    .tablet--space-right-128 {
        padding-right: 8rem;
    }
    .tablet--space-left-136 {
        padding-left: 8.5rem;
    }
    .tablet--space-top-136 {
        padding-top: 8.5rem;
    }
    .tablet--space-bottom-136 {
        padding-bottom: 8.5rem;
    }
    .tablet--space-right-136 {
        padding-right: 8.5rem;
    }
    .tablet--space-left-144 {
        padding-left: 9rem;
    }
    .tablet--space-top-144 {
        padding-top: 9rem;
    }
    .tablet--space-bottom-144 {
        padding-bottom: 9rem;
    }
    .tablet--space-right-144 {
        padding-right: 9rem;
    }
    .tablet--space-left-152 {
        padding-left: 9.5rem;
    }
    .tablet--space-top-152 {
        padding-top: 9.5rem;
    }
    .tablet--space-bottom-152 {
        padding-bottom: 9.5rem;
    }
    .tablet--space-right-152 {
        padding-right: 9.5rem;
    }
    .tablet--space-left-160 {
        padding-left: 10rem;
    }
    .tablet--space-top-160 {
        padding-top: 10rem;
    }
    .tablet--space-bottom-160 {
        padding-bottom: 10rem;
    }
    .tablet--space-right-160 {
        padding-right: 10rem;
    }
    .tablet--outer-space-left--16,
    .tablet--space-left--16 {
        margin-left: -1rem;
    }
    .tablet--outer-space-top--16,
    .tablet--space-top--16 {
        margin-top: -1rem;
    }
    .tablet--outer-space-bottom--16,
    .tablet--space-bottom--16 {
        margin-bottom: -1rem;
    }
    .tablet--outer-space-right--16,
    .tablet--space-right--16 {
        margin-right: -1rem;
    }
    .tablet--outer-space-left--12,
    .tablet--space-left--12 {
        margin-left: -0.75rem;
    }
    .tablet--outer-space-top--12,
    .tablet--space-top--12 {
        margin-top: -0.75rem;
    }
    .tablet--outer-space-bottom--12,
    .tablet--space-bottom--12 {
        margin-bottom: -0.75rem;
    }
    .tablet--outer-space-right--12,
    .tablet--space-right--12 {
        margin-right: -0.75rem;
    }
    .tablet--outer-space-left--8,
    .tablet--space-left--8 {
        margin-left: -0.5rem;
    }
    .tablet--outer-space-top--8,
    .tablet--space-top--8 {
        margin-top: -0.5rem;
    }
    .tablet--outer-space-bottom--8,
    .tablet--space-bottom--8 {
        margin-bottom: -0.5rem;
    }
    .tablet--outer-space-right--8,
    .tablet--space-right--8 {
        margin-right: -0.5rem;
    }
    .tablet--outer-space-left--4,
    .tablet--space-left--4 {
        margin-left: -0.25rem;
    }
    .tablet--outer-space-top--4,
    .tablet--space-top--4 {
        margin-top: -0.25rem;
    }
    .tablet--outer-space-bottom--4,
    .tablet--space-bottom--4 {
        margin-bottom: -0.25rem;
    }
    .tablet--outer-space-right--4,
    .tablet--space-right--4 {
        margin-right: -0.25rem;
    }
    .tablet--outer-space-left-8 {
        margin-left: 0.5rem;
    }
    .tablet--outer-space-top-8 {
        margin-top: 0.5rem;
    }
    .tablet--outer-space-bottom-8 {
        margin-bottom: 0.5rem;
    }
    .tablet--outer-space-right-8 {
        margin-right: 0.5rem;
    }
    .tablet--outer-space-left-16 {
        margin-left: 1rem;
    }
    .tablet--outer-space-top-16 {
        margin-top: 1rem;
    }
    .tablet--outer-space-bottom-16 {
        margin-bottom: 1rem;
    }
    .tablet--outer-space-right-16 {
        margin-right: 1rem;
    }
    .tablet--outer-space-left-24 {
        margin-left: 1.5rem;
    }
    .tablet--outer-space-top-24 {
        margin-top: 1.5rem;
    }
    .tablet--outer-space-bottom-24 {
        margin-bottom: 1.5rem;
    }
    .tablet--outer-space-right-24 {
        margin-right: 1.5rem;
    }
    .tablet--outer-space-left-32 {
        margin-left: 2rem;
    }
    .tablet--outer-space-top-32 {
        margin-top: 2rem;
    }
    .tablet--outer-space-bottom-32 {
        margin-bottom: 2rem;
    }
    .tablet--outer-space-right-32 {
        margin-right: 2rem;
    }
    .tablet--outer-space-left-40 {
        margin-left: 2.5rem;
    }
    .tablet--outer-space-top-40 {
        margin-top: 2.5rem;
    }
    .tablet--outer-space-bottom-40 {
        margin-bottom: 2.5rem;
    }
    .tablet--outer-space-right-40 {
        margin-right: 2.5rem;
    }
    .tablet--outer-space-left-48 {
        margin-left: 3rem;
    }
    .tablet--outer-space-top-48 {
        margin-top: 3rem;
    }
    .tablet--outer-space-bottom-48 {
        margin-bottom: 3rem;
    }
    .tablet--outer-space-right-48 {
        margin-right: 3rem;
    }
    .tablet--outer-space-left-56 {
        margin-left: 3.5rem;
    }
    .tablet--outer-space-top-56 {
        margin-top: 3.5rem;
    }
    .tablet--outer-space-bottom-56 {
        margin-bottom: 3.5rem;
    }
    .tablet--outer-space-right-56 {
        margin-right: 3.5rem;
    }
    .tablet--outer-space-left-64 {
        margin-left: 4rem;
    }
    .tablet--outer-space-top-64 {
        margin-top: 4rem;
    }
    .tablet--outer-space-bottom-64 {
        margin-bottom: 4rem;
    }
    .tablet--outer-space-right-64 {
        margin-right: 4rem;
    }
    .tablet--outer-space-left-72 {
        margin-left: 4.5rem;
    }
    .tablet--outer-space-top-72 {
        margin-top: 4.5rem;
    }
    .tablet--outer-space-bottom-72 {
        margin-bottom: 4.5rem;
    }
    .tablet--outer-space-right-72 {
        margin-right: 4.5rem;
    }
    .tablet--outer-space-left-80 {
        margin-left: 5rem;
    }
    .tablet--outer-space-top-80 {
        margin-top: 5rem;
    }
    .tablet--outer-space-bottom-80 {
        margin-bottom: 5rem;
    }
    .tablet--outer-space-right-80 {
        margin-right: 5rem;
    }
    .tablet--outer-space-left-88 {
        margin-left: 5.5rem;
    }
    .tablet--outer-space-top-88 {
        margin-top: 5.5rem;
    }
    .tablet--outer-space-bottom-88 {
        margin-bottom: 5.5rem;
    }
    .tablet--outer-space-right-88 {
        margin-right: 5.5rem;
    }
    .tablet--outer-space-left-96 {
        margin-left: 6rem;
    }
    .tablet--outer-space-top-96 {
        margin-top: 6rem;
    }
    .tablet--outer-space-bottom-96 {
        margin-bottom: 6rem;
    }
    .tablet--outer-space-right-96 {
        margin-right: 6rem;
    }
    .tablet--outer-space-left-104 {
        margin-left: 6.5rem;
    }
    .tablet--outer-space-top-104 {
        margin-top: 6.5rem;
    }
    .tablet--outer-space-bottom-104 {
        margin-bottom: 6.5rem;
    }
    .tablet--outer-space-right-104 {
        margin-right: 6.5rem;
    }
    .tablet--outer-space-left-112 {
        margin-left: 7rem;
    }
    .tablet--outer-space-top-112 {
        margin-top: 7rem;
    }
    .tablet--outer-space-bottom-112 {
        margin-bottom: 7rem;
    }
    .tablet--outer-space-right-112 {
        margin-right: 7rem;
    }
    .tablet--outer-space-left-120 {
        margin-left: 7.5rem;
    }
    .tablet--outer-space-top-120 {
        margin-top: 7.5rem;
    }
    .tablet--outer-space-bottom-120 {
        margin-bottom: 7.5rem;
    }
    .tablet--outer-space-right-120 {
        margin-right: 7.5rem;
    }
    .tablet--outer-space-left-128 {
        margin-left: 8rem;
    }
    .tablet--outer-space-top-128 {
        margin-top: 8rem;
    }
    .tablet--outer-space-bottom-128 {
        margin-bottom: 8rem;
    }
    .tablet--outer-space-right-128 {
        margin-right: 8rem;
    }
    .tablet--outer-space-left-136 {
        margin-left: 8.5rem;
    }
    .tablet--outer-space-top-136 {
        margin-top: 8.5rem;
    }
    .tablet--outer-space-bottom-136 {
        margin-bottom: 8.5rem;
    }
    .tablet--outer-space-right-136 {
        margin-right: 8.5rem;
    }
    .tablet--outer-space-left-144 {
        margin-left: 9rem;
    }
    .tablet--outer-space-top-144 {
        margin-top: 9rem;
    }
    .tablet--outer-space-bottom-144 {
        margin-bottom: 9rem;
    }
    .tablet--outer-space-right-144 {
        margin-right: 9rem;
    }
    .tablet--outer-space-left-152 {
        margin-left: 9.5rem;
    }
    .tablet--outer-space-top-152 {
        margin-top: 9.5rem;
    }
    .tablet--outer-space-bottom-152 {
        margin-bottom: 9.5rem;
    }
    .tablet--outer-space-right-152 {
        margin-right: 9.5rem;
    }
    .tablet--outer-space-left-160 {
        margin-left: 10rem;
    }
    .tablet--outer-space-top-160 {
        margin-top: 10rem;
    }
    .tablet--outer-space-bottom-160 {
        margin-bottom: 10rem;
    }
    .tablet--outer-space-right-160 {
        margin-right: 10rem;
    }
}

@media screen and (max-width: 480px) {
    .smartphone--display-none {
        display: none;
    }
    .smartphone--display-block {
        display: block;
    }
    .smartphone--display-inline-block {
        display: inline-block;
    }
    .smartphone--no-space-left {
        padding-left: 0;
        margin-left: 0;
    }
    .smartphone--no-space-top {
        padding-top: 0;
        margin-top: 0;
    }
    .smartphone--no-space-bottom {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .smartphone--no-space-right {
        padding-right: 0;
        margin-right: 0;
    }
    .smartphone--space-left-8 {
        padding-left: 0.5rem;
    }
    .smartphone--space-top-8 {
        padding-top: 0.5rem;
    }
    .smartphone--space-bottom-8 {
        padding-bottom: 0.5rem;
    }
    .smartphone--space-right-8 {
        padding-right: 0.5rem;
    }
    .smartphone--space-left-16 {
        padding-left: 1rem;
    }
    .smartphone--space-top-16 {
        padding-top: 1rem;
    }
    .smartphone--space-bottom-16 {
        padding-bottom: 1rem;
    }
    .smartphone--space-right-16 {
        padding-right: 1rem;
    }
    .smartphone--space-left-24 {
        padding-left: 1.5rem;
    }
    .smartphone--space-top-24 {
        padding-top: 1.5rem;
    }
    .smartphone--space-bottom-24 {
        padding-bottom: 1.5rem;
    }
    .smartphone--space-right-24 {
        padding-right: 1.5rem;
    }
    .smartphone--space-left-32 {
        padding-left: 2rem;
    }
    .smartphone--space-top-32 {
        padding-top: 2rem;
    }
    .smartphone--space-bottom-32 {
        padding-bottom: 2rem;
    }
    .smartphone--space-right-32 {
        padding-right: 2rem;
    }
    .smartphone--space-left-40 {
        padding-left: 2.5rem;
    }
    .smartphone--space-top-40 {
        padding-top: 2.5rem;
    }
    .smartphone--space-bottom-40 {
        padding-bottom: 2.5rem;
    }
    .smartphone--space-right-40 {
        padding-right: 2.5rem;
    }
    .smartphone--space-left-48 {
        padding-left: 3rem;
    }
    .smartphone--space-top-48 {
        padding-top: 3rem;
    }
    .smartphone--space-bottom-48 {
        padding-bottom: 3rem;
    }
    .smartphone--space-right-48 {
        padding-right: 3rem;
    }
    .smartphone--space-left-56 {
        padding-left: 3.5rem;
    }
    .smartphone--space-top-56 {
        padding-top: 3.5rem;
    }
    .smartphone--space-bottom-56 {
        padding-bottom: 3.5rem;
    }
    .smartphone--space-right-56 {
        padding-right: 3.5rem;
    }
    .smartphone--space-left-64 {
        padding-left: 4rem;
    }
    .smartphone--space-top-64 {
        padding-top: 4rem;
    }
    .smartphone--space-bottom-64 {
        padding-bottom: 4rem;
    }
    .smartphone--space-right-64 {
        padding-right: 4rem;
    }
    .smartphone--space-left-72 {
        padding-left: 4.5rem;
    }
    .smartphone--space-top-72 {
        padding-top: 4.5rem;
    }
    .smartphone--space-bottom-72 {
        padding-bottom: 4.5rem;
    }
    .smartphone--space-right-72 {
        padding-right: 4.5rem;
    }
    .smartphone--space-left-80 {
        padding-left: 5rem;
    }
    .smartphone--space-top-80 {
        padding-top: 5rem;
    }
    .smartphone--space-bottom-80 {
        padding-bottom: 5rem;
    }
    .smartphone--space-right-80 {
        padding-right: 5rem;
    }
    .smartphone--space-left-88 {
        padding-left: 5.5rem;
    }
    .smartphone--space-top-88 {
        padding-top: 5.5rem;
    }
    .smartphone--space-bottom-88 {
        padding-bottom: 5.5rem;
    }
    .smartphone--space-right-88 {
        padding-right: 5.5rem;
    }
    .smartphone--space-left-96 {
        padding-left: 6rem;
    }
    .smartphone--space-top-96 {
        padding-top: 6rem;
    }
    .smartphone--space-bottom-96 {
        padding-bottom: 6rem;
    }
    .smartphone--space-right-96 {
        padding-right: 6rem;
    }
    .smartphone--space-left-104 {
        padding-left: 6.5rem;
    }
    .smartphone--space-top-104 {
        padding-top: 6.5rem;
    }
    .smartphone--space-bottom-104 {
        padding-bottom: 6.5rem;
    }
    .smartphone--space-right-104 {
        padding-right: 6.5rem;
    }
    .smartphone--space-left-112 {
        padding-left: 7rem;
    }
    .smartphone--space-top-112 {
        padding-top: 7rem;
    }
    .smartphone--space-bottom-112 {
        padding-bottom: 7rem;
    }
    .smartphone--space-right-112 {
        padding-right: 7rem;
    }
    .smartphone--space-left-120 {
        padding-left: 7.5rem;
    }
    .smartphone--space-top-120 {
        padding-top: 7.5rem;
    }
    .smartphone--space-bottom-120 {
        padding-bottom: 7.5rem;
    }
    .smartphone--space-right-120 {
        padding-right: 7.5rem;
    }
    .smartphone--space-left-128 {
        padding-left: 8rem;
    }
    .smartphone--space-top-128 {
        padding-top: 8rem;
    }
    .smartphone--space-bottom-128 {
        padding-bottom: 8rem;
    }
    .smartphone--space-right-128 {
        padding-right: 8rem;
    }
    .smartphone--space-left-136 {
        padding-left: 8.5rem;
    }
    .smartphone--space-top-136 {
        padding-top: 8.5rem;
    }
    .smartphone--space-bottom-136 {
        padding-bottom: 8.5rem;
    }
    .smartphone--space-right-136 {
        padding-right: 8.5rem;
    }
    .smartphone--space-left-144 {
        padding-left: 9rem;
    }
    .smartphone--space-top-144 {
        padding-top: 9rem;
    }
    .smartphone--space-bottom-144 {
        padding-bottom: 9rem;
    }
    .smartphone--space-right-144 {
        padding-right: 9rem;
    }
    .smartphone--space-left-152 {
        padding-left: 9.5rem;
    }
    .smartphone--space-top-152 {
        padding-top: 9.5rem;
    }
    .smartphone--space-bottom-152 {
        padding-bottom: 9.5rem;
    }
    .smartphone--space-right-152 {
        padding-right: 9.5rem;
    }
    .smartphone--space-left-160 {
        padding-left: 10rem;
    }
    .smartphone--space-top-160 {
        padding-top: 10rem;
    }
    .smartphone--space-bottom-160 {
        padding-bottom: 10rem;
    }
    .smartphone--space-right-160 {
        padding-right: 10rem;
    }
    .smartphone--outer-space-left--16,
    .smartphone--space-left--16 {
        margin-left: -1rem;
    }
    .smartphone--outer-space-top--16,
    .smartphone--space-top--16 {
        margin-top: -1rem;
    }
    .smartphone--outer-space-bottom--16,
    .smartphone--space-bottom--16 {
        margin-bottom: -1rem;
    }
    .smartphone--outer-space-right--16,
    .smartphone--space-right--16 {
        margin-right: -1rem;
    }
    .smartphone--outer-space-left--12,
    .smartphone--space-left--12 {
        margin-left: -0.75rem;
    }
    .smartphone--outer-space-top--12,
    .smartphone--space-top--12 {
        margin-top: -0.75rem;
    }
    .smartphone--outer-space-bottom--12,
    .smartphone--space-bottom--12 {
        margin-bottom: -0.75rem;
    }
    .smartphone--outer-space-right--12,
    .smartphone--space-right--12 {
        margin-right: -0.75rem;
    }
    .smartphone--outer-space-left--8,
    .smartphone--space-left--8 {
        margin-left: -0.5rem;
    }
    .smartphone--outer-space-top--8,
    .smartphone--space-top--8 {
        margin-top: -0.5rem;
    }
    .smartphone--outer-space-bottom--8,
    .smartphone--space-bottom--8 {
        margin-bottom: -0.5rem;
    }
    .smartphone--outer-space-right--8,
    .smartphone--space-right--8 {
        margin-right: -0.5rem;
    }
    .smartphone--outer-space-left--4,
    .smartphone--space-left--4 {
        margin-left: -0.25rem;
    }
    .smartphone--outer-space-top--4,
    .smartphone--space-top--4 {
        margin-top: -0.25rem;
    }
    .smartphone--outer-space-bottom--4,
    .smartphone--space-bottom--4 {
        margin-bottom: -0.25rem;
    }
    .smartphone--outer-space-right--4,
    .smartphone--space-right--4 {
        margin-right: -0.25rem;
    }
    .smartphone--outer-space-left-8 {
        margin-left: 0.5rem;
    }
    .smartphone--outer-space-top-8 {
        margin-top: 0.5rem;
    }
    .smartphone--outer-space-bottom-8 {
        margin-bottom: 0.5rem;
    }
    .smartphone--outer-space-right-8 {
        margin-right: 0.5rem;
    }
    .smartphone--outer-space-left-16 {
        margin-left: 1rem;
    }
    .smartphone--outer-space-top-16 {
        margin-top: 1rem;
    }
    .smartphone--outer-space-bottom-16 {
        margin-bottom: 1rem;
    }
    .smartphone--outer-space-right-16 {
        margin-right: 1rem;
    }
    .smartphone--outer-space-left-24 {
        margin-left: 1.5rem;
    }
    .smartphone--outer-space-top-24 {
        margin-top: 1.5rem;
    }
    .smartphone--outer-space-bottom-24 {
        margin-bottom: 1.5rem;
    }
    .smartphone--outer-space-right-24 {
        margin-right: 1.5rem;
    }
    .smartphone--outer-space-left-32 {
        margin-left: 2rem;
    }
    .smartphone--outer-space-top-32 {
        margin-top: 2rem;
    }
    .smartphone--outer-space-bottom-32 {
        margin-bottom: 2rem;
    }
    .smartphone--outer-space-right-32 {
        margin-right: 2rem;
    }
    .smartphone--outer-space-left-40 {
        margin-left: 2.5rem;
    }
    .smartphone--outer-space-top-40 {
        margin-top: 2.5rem;
    }
    .smartphone--outer-space-bottom-40 {
        margin-bottom: 2.5rem;
    }
    .smartphone--outer-space-right-40 {
        margin-right: 2.5rem;
    }
    .smartphone--outer-space-left-48 {
        margin-left: 3rem;
    }
    .smartphone--outer-space-top-48 {
        margin-top: 3rem;
    }
    .smartphone--outer-space-bottom-48 {
        margin-bottom: 3rem;
    }
    .smartphone--outer-space-right-48 {
        margin-right: 3rem;
    }
    .smartphone--outer-space-left-56 {
        margin-left: 3.5rem;
    }
    .smartphone--outer-space-top-56 {
        margin-top: 3.5rem;
    }
    .smartphone--outer-space-bottom-56 {
        margin-bottom: 3.5rem;
    }
    .smartphone--outer-space-right-56 {
        margin-right: 3.5rem;
    }
    .smartphone--outer-space-left-64 {
        margin-left: 4rem;
    }
    .smartphone--outer-space-top-64 {
        margin-top: 4rem;
    }
    .smartphone--outer-space-bottom-64 {
        margin-bottom: 4rem;
    }
    .smartphone--outer-space-right-64 {
        margin-right: 4rem;
    }
    .smartphone--outer-space-left-72 {
        margin-left: 4.5rem;
    }
    .smartphone--outer-space-top-72 {
        margin-top: 4.5rem;
    }
    .smartphone--outer-space-bottom-72 {
        margin-bottom: 4.5rem;
    }
    .smartphone--outer-space-right-72 {
        margin-right: 4.5rem;
    }
    .smartphone--outer-space-left-80 {
        margin-left: 5rem;
    }
    .smartphone--outer-space-top-80 {
        margin-top: 5rem;
    }
    .smartphone--outer-space-bottom-80 {
        margin-bottom: 5rem;
    }
    .smartphone--outer-space-right-80 {
        margin-right: 5rem;
    }
    .smartphone--outer-space-left-88 {
        margin-left: 5.5rem;
    }
    .smartphone--outer-space-top-88 {
        margin-top: 5.5rem;
    }
    .smartphone--outer-space-bottom-88 {
        margin-bottom: 5.5rem;
    }
    .smartphone--outer-space-right-88 {
        margin-right: 5.5rem;
    }
    .smartphone--outer-space-left-96 {
        margin-left: 6rem;
    }
    .smartphone--outer-space-top-96 {
        margin-top: 6rem;
    }
    .smartphone--outer-space-bottom-96 {
        margin-bottom: 6rem;
    }
    .smartphone--outer-space-right-96 {
        margin-right: 6rem;
    }
    .smartphone--outer-space-left-104 {
        margin-left: 6.5rem;
    }
    .smartphone--outer-space-top-104 {
        margin-top: 6.5rem;
    }
    .smartphone--outer-space-bottom-104 {
        margin-bottom: 6.5rem;
    }
    .smartphone--outer-space-right-104 {
        margin-right: 6.5rem;
    }
    .smartphone--outer-space-left-112 {
        margin-left: 7rem;
    }
    .smartphone--outer-space-top-112 {
        margin-top: 7rem;
    }
    .smartphone--outer-space-bottom-112 {
        margin-bottom: 7rem;
    }
    .smartphone--outer-space-right-112 {
        margin-right: 7rem;
    }
    .smartphone--outer-space-left-120 {
        margin-left: 7.5rem;
    }
    .smartphone--outer-space-top-120 {
        margin-top: 7.5rem;
    }
    .smartphone--outer-space-bottom-120 {
        margin-bottom: 7.5rem;
    }
    .smartphone--outer-space-right-120 {
        margin-right: 7.5rem;
    }
    .smartphone--outer-space-left-128 {
        margin-left: 8rem;
    }
    .smartphone--outer-space-top-128 {
        margin-top: 8rem;
    }
    .smartphone--outer-space-bottom-128 {
        margin-bottom: 8rem;
    }
    .smartphone--outer-space-right-128 {
        margin-right: 8rem;
    }
    .smartphone--outer-space-left-136 {
        margin-left: 8.5rem;
    }
    .smartphone--outer-space-top-136 {
        margin-top: 8.5rem;
    }
    .smartphone--outer-space-bottom-136 {
        margin-bottom: 8.5rem;
    }
    .smartphone--outer-space-right-136 {
        margin-right: 8.5rem;
    }
    .smartphone--outer-space-left-144 {
        margin-left: 9rem;
    }
    .smartphone--outer-space-top-144 {
        margin-top: 9rem;
    }
    .smartphone--outer-space-bottom-144 {
        margin-bottom: 9rem;
    }
    .smartphone--outer-space-right-144 {
        margin-right: 9rem;
    }
    .smartphone--outer-space-left-152 {
        margin-left: 9.5rem;
    }
    .smartphone--outer-space-top-152 {
        margin-top: 9.5rem;
    }
    .smartphone--outer-space-bottom-152 {
        margin-bottom: 9.5rem;
    }
    .smartphone--outer-space-right-152 {
        margin-right: 9.5rem;
    }
    .smartphone--outer-space-left-160 {
        margin-left: 10rem;
    }
    .smartphone--outer-space-top-160 {
        margin-top: 10rem;
    }
    .smartphone--outer-space-bottom-160 {
        margin-bottom: 10rem;
    }
    .smartphone--outer-space-right-160 {
        margin-right: 10rem;
    }
}

html {
    color: #000;
    background-color: #fff;
}

a {
    color: #000;
}

html.no-touchevents a:hover {
    color: #7F8D32;
}

html.no-touchevents .background-color-green a:hover {
    color: rgba(255, 255, 255, 0.5);
}

.color-white {
    color: white;
}

.color-white-90 {
    color: rgba(255, 255, 255, 0.9);
}

.color-white-80 {
    color: rgba(255, 255, 255, 0.8);
}

.color-white-70 {
    color: rgba(255, 255, 255, 0.7);
}

.color-white-60 {
    color: rgba(255, 255, 255, 0.6);
}

.color-white-50 {
    color: rgba(255, 255, 255, 0.5);
}

.color-white-40 {
    color: rgba(255, 255, 255, 0.4);
}

.color-white-30 {
    color: rgba(255, 255, 255, 0.3);
}

.color-white-20 {
    color: rgba(255, 255, 255, 0.2);
}

.color-white-10 {
    color: rgba(255, 255, 255, 0.1);
}

.color-white-5 {
    color: rgba(255, 255, 255, 0.05);
}

.color-white a {
    color: inherit;
}

html.no-touch .color-white a:hover {
    color: #7F8D32;
}

.color-grey {
    color: #424242;
}

.color-grey-90 {
    color: rgba(66, 66, 66, 0.9);
}

.color-grey-80 {
    color: rgba(66, 66, 66, 0.8);
}

.color-grey-70 {
    color: rgba(66, 66, 66, 0.7);
}

.color-grey-60 {
    color: rgba(66, 66, 66, 0.6);
}

.color-grey-50 {
    color: rgba(66, 66, 66, 0.5);
}

.color-grey-40 {
    color: rgba(66, 66, 66, 0.4);
}

.color-grey-30 {
    color: rgba(66, 66, 66, 0.3);
}

.color-grey-20 {
    color: rgba(66, 66, 66, 0.2);
}

.color-grey-10 {
    color: rgba(66, 66, 66, 0.1);
}

.color-grey-5 {
    color: rgba(66, 66, 66, 0.05);
}

.color-grey a {
    color: inherit;
}

html.no-touch .color-grey a:hover {
    color: #7F8D32;
}

.color-black {
    color: black;
}

.color-black-90 {
    color: rgba(0, 0, 0, 0.9);
}

.color-black-80 {
    color: rgba(0, 0, 0, 0.8);
}

.color-black-70 {
    color: rgba(0, 0, 0, 0.7);
}

.color-black-60 {
    color: rgba(0, 0, 0, 0.6);
}

.color-black-50 {
    color: rgba(0, 0, 0, 0.5);
}

.color-black-40 {
    color: rgba(0, 0, 0, 0.4);
}

.color-black-30 {
    color: rgba(0, 0, 0, 0.3);
}

.color-black-20 {
    color: rgba(0, 0, 0, 0.2);
}

.color-black-10 {
    color: rgba(0, 0, 0, 0.1);
}

.color-black-5 {
    color: rgba(0, 0, 0, 0.05);
}

.color-black a {
    color: inherit;
}

html.no-touch .color-black a:hover {
    color: #7F8D32;
}

.color-highlight {
    color: #7f8d32;
}

.color-highlight-90 {
    color: rgba(127, 141, 50, 0.9);
}

.color-highlight-80 {
    color: rgba(127, 141, 50, 0.8);
}

.color-highlight-70 {
    color: rgba(127, 141, 50, 0.7);
}

.color-highlight-60 {
    color: rgba(127, 141, 50, 0.6);
}

.color-highlight-50 {
    color: rgba(127, 141, 50, 0.5);
}

.color-highlight-40 {
    color: rgba(127, 141, 50, 0.4);
}

.color-highlight-30 {
    color: rgba(127, 141, 50, 0.3);
}

.color-highlight-20 {
    color: rgba(127, 141, 50, 0.2);
}

.color-highlight-10 {
    color: rgba(127, 141, 50, 0.1);
}

.color-highlight-5 {
    color: rgba(127, 141, 50, 0.05);
}

.color-highlight a {
    color: inherit;
}

html.no-touch .color-highlight a:hover {
    color: black;
}

.background-color-white {
    background-color: white;
}

.background-color-white-95 {
    background-color: rgba(255, 255, 255, 0.9);
}

.background-color-white-80 {
    background-color: rgba(255, 255, 255, 0.8);
}

.background-color-white-60 {
    background-color: rgba(255, 255, 255, 0.6);
}

.background-color-white-50 {
    background-color: rgba(255, 255, 255, 0.5);
}

.background-color-white-40 {
    background-color: rgba(255, 255, 255, 0.4);
}

.background-color-white-20 {
    background-color: rgba(255, 255, 255, 0.2);
}

.background-color-white-10 {
    background-color: rgba(255, 255, 255, 0.1);
}

.background-color-white-5 {
    background-color: rgba(255, 255, 255, 0.05);
}

.background-color-grey {
    background-color: #424242;
}

.background-color-grey-95 {
    background-color: rgba(66, 66, 66, 0.9);
}

.background-color-grey-80 {
    background-color: rgba(66, 66, 66, 0.8);
}

.background-color-grey-60 {
    background-color: rgba(66, 66, 66, 0.6);
}

.background-color-grey-50 {
    background-color: rgba(66, 66, 66, 0.5);
}

.background-color-grey-40 {
    background-color: rgba(66, 66, 66, 0.4);
}

.background-color-grey-20 {
    background-color: rgba(66, 66, 66, 0.2);
}

.background-color-grey-10 {
    background-color: rgba(66, 66, 66, 0.1);
}

.background-color-grey-5 {
    background-color: rgba(66, 66, 66, 0.05);
}

.background-color-black {
    background-color: black;
}

.background-color-black-95 {
    background-color: rgba(0, 0, 0, 0.9);
}

.background-color-black-80 {
    background-color: rgba(0, 0, 0, 0.8);
}

.background-color-black-60 {
    background-color: rgba(0, 0, 0, 0.6);
}

.background-color-black-50 {
    background-color: rgba(0, 0, 0, 0.5);
}

.background-color-black-40 {
    background-color: rgba(0, 0, 0, 0.4);
}

.background-color-black-20 {
    background-color: rgba(0, 0, 0, 0.2);
}

.background-color-black-10 {
    background-color: rgba(0, 0, 0, 0.1);
}

.background-color-black-5 {
    background-color: rgba(0, 0, 0, 0.05);
}

.background-color-grey-light {
    background-color: #ebebeb;
}

.background-color-grey-light-95 {
    background-color: rgba(235, 235, 235, 0.9);
}

.background-color-grey-light-80 {
    background-color: rgba(235, 235, 235, 0.8);
}

.background-color-grey-light-60 {
    background-color: rgba(235, 235, 235, 0.6);
}

.background-color-grey-light-50 {
    background-color: rgba(235, 235, 235, 0.5);
}

.background-color-grey-light-40 {
    background-color: rgba(235, 235, 235, 0.4);
}

.background-color-grey-light-20 {
    background-color: rgba(235, 235, 235, 0.2);
}

.background-color-grey-light-10 {
    background-color: rgba(235, 235, 235, 0.1);
}

.background-color-grey-light-5 {
    background-color: rgba(235, 235, 235, 0.05);
}

.background-color-green {
    background-color: #7f8d32;
}

.background-color-green-95 {
    background-color: rgba(127, 141, 50, 0.9);
}

.background-color-green-80 {
    background-color: rgba(127, 141, 50, 0.8);
}

.background-color-green-60 {
    background-color: rgba(127, 141, 50, 0.6);
}

.background-color-green-50 {
    background-color: rgba(127, 141, 50, 0.5);
}

.background-color-green-40 {
    background-color: rgba(127, 141, 50, 0.4);
}

.background-color-green-20 {
    background-color: rgba(127, 141, 50, 0.2);
}

.background-color-green-10 {
    background-color: rgba(127, 141, 50, 0.1);
}

.background-color-green-5 {
    background-color: rgba(127, 141, 50, 0.05);
}

span.background-color-white, span.background-color-grey, span.background-color-black, span.background-color-grey-light, span.background-color-green {
    display: inline-block;
    padding: 0.25em 0.5em;
}

.header-icon {
    display: inline;
    max-width: 14rem;
}

.size-8 {
    font-size: 0.5rem;
    line-height: 1.5;
}

.size-16 {
    font-size: 1rem;
    line-height: 1.5;
}

.size-24 {
    font-size: 1.5rem;
    line-height: 1.5;
}

.size-32 {
    font-size: 2rem;
    line-height: 1.5;
}

.size-40 {
    font-size: 2.5rem;
    line-height: 1.4;
}

.size-48 {
    font-size: 3rem;
    line-height: 1.4;
}

.size-56 {
    font-size: 3.5rem;
    line-height: 1.4;
}

.size-64 {
    font-size: 4rem;
    line-height: 1.4;
}

.size-72 {
    font-size: 4.5rem;
    line-height: 1.4;
}

.size-80 {
    font-size: 5rem;
    line-height: 1.3;
}

.size-88 {
    font-size: 5.5rem;
    line-height: 1.3;
}

.size-96 {
    font-size: 6rem;
    line-height: 1.2;
}

.size-104 {
    font-size: 6.5rem;
    line-height: 1.2;
}

.size-112 {
    font-size: 7rem;
    line-height: 1.2;
}

.size-120 {
    font-size: 7.5rem;
    line-height: 1.2;
}

.size-128 {
    font-size: 8rem;
    line-height: 1.1;
}

.size-136 {
    font-size: 8.5rem;
    line-height: 1.1;
}

.size-144 {
    font-size: 9rem;
    line-height: 1.1;
}

.size-152 {
    font-size: 9.5rem;
    line-height: 1.1;
}

.size-160 {
    font-size: 10rem;
    line-height: 1.1;
}

.line-height-8 {
    line-height: 0.5rem;
}

.line-height-16 {
    line-height: 1rem;
}

.line-height-24 {
    line-height: 1.5rem;
}

.line-height-32 {
    line-height: 2rem;
}

.line-height-40 {
    line-height: 2.5rem;
}

.line-height-48 {
    line-height: 3rem;
}

.line-height-56 {
    line-height: 3.5rem;
}

.line-height-64 {
    line-height: 4rem;
}

.line-height-72 {
    line-height: 4.5rem;
}

.line-height-80 {
    line-height: 5rem;
}

.line-height-88 {
    line-height: 5.5rem;
}

.line-height-96 {
    line-height: 6rem;
}

.line-height-104 {
    line-height: 6.5rem;
}

.line-height-112 {
    line-height: 7rem;
}

.line-height-120 {
    line-height: 7.5rem;
}

.line-height-128 {
    line-height: 8rem;
}

.line-height-136 {
    line-height: 8.5rem;
}

.line-height-144 {
    line-height: 9rem;
}

.line-height-152 {
    line-height: 9.5rem;
}

.line-height-160 {
    line-height: 10rem;
}

.icon-size-8 {
    width: 0.5rem !important;
}

.icon-size-16 {
    width: 1rem !important;
}

.icon-size-24 {
    width: 1.5rem !important;
}

.icon-size-32 {
    width: 2rem !important;
}

.icon-size-40 {
    width: 2.5rem !important;
}

.icon-size-48 {
    width: 3rem !important;
}

.icon-size-56 {
    width: 3.5rem !important;
}

.icon-size-64 {
    width: 4rem !important;
}

.icon-size-72 {
    width: 4.5rem !important;
}

.icon-size-80 {
    width: 5rem !important;
}

.icon-size-88 {
    width: 5.5rem !important;
}

.icon-size-96 {
    width: 6rem !important;
}

.icon-size-104 {
    width: 6.5rem !important;
}

.icon-size-112 {
    width: 7rem !important;
}

.icon-size-120 {
    width: 7.5rem !important;
}

.icon-size-128 {
    width: 8rem !important;
}

.icon-size-136 {
    width: 8.5rem !important;
}

.icon-size-144 {
    width: 9rem !important;
}

.icon-size-152 {
    width: 9.5rem !important;
}

.icon-size-160 {
    width: 10rem !important;
}

@media screen and (min-width: 1281px) {
    .desktop--size-8 {
        font-size: 0.5rem;
        line-height: 1.5;
    }
    .desktop--size-16 {
        font-size: 1rem;
        line-height: 1.5;
    }
    .desktop--size-24 {
        font-size: 1.5rem;
        line-height: 1.5;
    }
    .desktop--size-32 {
        font-size: 2rem;
        line-height: 1.5;
    }
    .desktop--size-40 {
        font-size: 2.5rem;
        line-height: 1.4;
    }
    .desktop--size-48 {
        font-size: 3rem;
        line-height: 1.4;
    }
    .desktop--size-56 {
        font-size: 3.5rem;
        line-height: 1.4;
    }
    .desktop--size-64 {
        font-size: 4rem;
        line-height: 1.4;
    }
    .desktop--size-72 {
        font-size: 4.5rem;
        line-height: 1.4;
    }
    .desktop--size-80 {
        font-size: 5rem;
        line-height: 1.3;
    }
    .desktop--size-88 {
        font-size: 5.5rem;
        line-height: 1.3;
    }
    .desktop--size-96 {
        font-size: 6rem;
        line-height: 1.2;
    }
    .desktop--size-104 {
        font-size: 6.5rem;
        line-height: 1.2;
    }
    .desktop--size-112 {
        font-size: 7rem;
        line-height: 1.2;
    }
    .desktop--size-120 {
        font-size: 7.5rem;
        line-height: 1.2;
    }
    .desktop--size-128 {
        font-size: 8rem;
        line-height: 1.1;
    }
    .desktop--size-136 {
        font-size: 8.5rem;
        line-height: 1.1;
    }
    .desktop--size-144 {
        font-size: 9rem;
        line-height: 1.1;
    }
    .desktop--size-152 {
        font-size: 9.5rem;
        line-height: 1.1;
    }
    .desktop--size-160 {
        font-size: 10rem;
        line-height: 1.1;
    }
    .desktop--line-height-8 {
        line-height: 0.5rem;
    }
    .desktop--line-height-16 {
        line-height: 1rem;
    }
    .desktop--line-height-24 {
        line-height: 1.5rem;
    }
    .desktop--line-height-32 {
        line-height: 2rem;
    }
    .desktop--line-height-40 {
        line-height: 2.5rem;
    }
    .desktop--line-height-48 {
        line-height: 3rem;
    }
    .desktop--line-height-56 {
        line-height: 3.5rem;
    }
    .desktop--line-height-64 {
        line-height: 4rem;
    }
    .desktop--line-height-72 {
        line-height: 4.5rem;
    }
    .desktop--line-height-80 {
        line-height: 5rem;
    }
    .desktop--line-height-88 {
        line-height: 5.5rem;
    }
    .desktop--line-height-96 {
        line-height: 6rem;
    }
    .desktop--line-height-104 {
        line-height: 6.5rem;
    }
    .desktop--line-height-112 {
        line-height: 7rem;
    }
    .desktop--line-height-120 {
        line-height: 7.5rem;
    }
    .desktop--line-height-128 {
        line-height: 8rem;
    }
    .desktop--line-height-136 {
        line-height: 8.5rem;
    }
    .desktop--line-height-144 {
        line-height: 9rem;
    }
    .desktop--line-height-152 {
        line-height: 9.5rem;
    }
    .desktop--line-height-160 {
        line-height: 10rem;
    }
    .desktop--icon-size-8 {
        width: 0.5rem !important;
    }
    .desktop--icon-size-16 {
        width: 1rem !important;
    }
    .desktop--icon-size-24 {
        width: 1.5rem !important;
    }
    .desktop--icon-size-32 {
        width: 2rem !important;
    }
    .desktop--icon-size-40 {
        width: 2.5rem !important;
    }
    .desktop--icon-size-48 {
        width: 3rem !important;
    }
    .desktop--icon-size-56 {
        width: 3.5rem !important;
    }
    .desktop--icon-size-64 {
        width: 4rem !important;
    }
    .desktop--icon-size-72 {
        width: 4.5rem !important;
    }
    .desktop--icon-size-80 {
        width: 5rem !important;
    }
    .desktop--icon-size-88 {
        width: 5.5rem !important;
    }
    .desktop--icon-size-96 {
        width: 6rem !important;
    }
    .desktop--icon-size-104 {
        width: 6.5rem !important;
    }
    .desktop--icon-size-112 {
        width: 7rem !important;
    }
    .desktop--icon-size-120 {
        width: 7.5rem !important;
    }
    .desktop--icon-size-128 {
        width: 8rem !important;
    }
    .desktop--icon-size-136 {
        width: 8.5rem !important;
    }
    .desktop--icon-size-144 {
        width: 9rem !important;
    }
    .desktop--icon-size-152 {
        width: 9.5rem !important;
    }
    .desktop--icon-size-160 {
        width: 10rem !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .laptop--size-8 {
        font-size: 0.5rem;
        line-height: 1.5;
    }
    .laptop--size-16 {
        font-size: 1rem;
        line-height: 1.5;
    }
    .laptop--size-24 {
        font-size: 1.5rem;
        line-height: 1.5;
    }
    .laptop--size-32 {
        font-size: 2rem;
        line-height: 1.5;
    }
    .laptop--size-40 {
        font-size: 2.5rem;
        line-height: 1.4;
    }
    .laptop--size-48 {
        font-size: 3rem;
        line-height: 1.4;
    }
    .laptop--size-56 {
        font-size: 3.5rem;
        line-height: 1.4;
    }
    .laptop--size-64 {
        font-size: 4rem;
        line-height: 1.4;
    }
    .laptop--size-72 {
        font-size: 4.5rem;
        line-height: 1.4;
    }
    .laptop--size-80 {
        font-size: 5rem;
        line-height: 1.3;
    }
    .laptop--size-88 {
        font-size: 5.5rem;
        line-height: 1.3;
    }
    .laptop--size-96 {
        font-size: 6rem;
        line-height: 1.2;
    }
    .laptop--size-104 {
        font-size: 6.5rem;
        line-height: 1.2;
    }
    .laptop--size-112 {
        font-size: 7rem;
        line-height: 1.2;
    }
    .laptop--size-120 {
        font-size: 7.5rem;
        line-height: 1.2;
    }
    .laptop--size-128 {
        font-size: 8rem;
        line-height: 1.1;
    }
    .laptop--size-136 {
        font-size: 8.5rem;
        line-height: 1.1;
    }
    .laptop--size-144 {
        font-size: 9rem;
        line-height: 1.1;
    }
    .laptop--size-152 {
        font-size: 9.5rem;
        line-height: 1.1;
    }
    .laptop--size-160 {
        font-size: 10rem;
        line-height: 1.1;
    }
    .laptop--line-height-8 {
        line-height: 0.5rem;
    }
    .laptop--line-height-16 {
        line-height: 1rem;
    }
    .laptop--line-height-24 {
        line-height: 1.5rem;
    }
    .laptop--line-height-32 {
        line-height: 2rem;
    }
    .laptop--line-height-40 {
        line-height: 2.5rem;
    }
    .laptop--line-height-48 {
        line-height: 3rem;
    }
    .laptop--line-height-56 {
        line-height: 3.5rem;
    }
    .laptop--line-height-64 {
        line-height: 4rem;
    }
    .laptop--line-height-72 {
        line-height: 4.5rem;
    }
    .laptop--line-height-80 {
        line-height: 5rem;
    }
    .laptop--line-height-88 {
        line-height: 5.5rem;
    }
    .laptop--line-height-96 {
        line-height: 6rem;
    }
    .laptop--line-height-104 {
        line-height: 6.5rem;
    }
    .laptop--line-height-112 {
        line-height: 7rem;
    }
    .laptop--line-height-120 {
        line-height: 7.5rem;
    }
    .laptop--line-height-128 {
        line-height: 8rem;
    }
    .laptop--line-height-136 {
        line-height: 8.5rem;
    }
    .laptop--line-height-144 {
        line-height: 9rem;
    }
    .laptop--line-height-152 {
        line-height: 9.5rem;
    }
    .laptop--line-height-160 {
        line-height: 10rem;
    }
    .laptop--icon-size-8 {
        width: 0.5rem !important;
    }
    .laptop--icon-size-16 {
        width: 1rem !important;
    }
    .laptop--icon-size-24 {
        width: 1.5rem !important;
    }
    .laptop--icon-size-32 {
        width: 2rem !important;
    }
    .laptop--icon-size-40 {
        width: 2.5rem !important;
    }
    .laptop--icon-size-48 {
        width: 3rem !important;
    }
    .laptop--icon-size-56 {
        width: 3.5rem !important;
    }
    .laptop--icon-size-64 {
        width: 4rem !important;
    }
    .laptop--icon-size-72 {
        width: 4.5rem !important;
    }
    .laptop--icon-size-80 {
        width: 5rem !important;
    }
    .laptop--icon-size-88 {
        width: 5.5rem !important;
    }
    .laptop--icon-size-96 {
        width: 6rem !important;
    }
    .laptop--icon-size-104 {
        width: 6.5rem !important;
    }
    .laptop--icon-size-112 {
        width: 7rem !important;
    }
    .laptop--icon-size-120 {
        width: 7.5rem !important;
    }
    .laptop--icon-size-128 {
        width: 8rem !important;
    }
    .laptop--icon-size-136 {
        width: 8.5rem !important;
    }
    .laptop--icon-size-144 {
        width: 9rem !important;
    }
    .laptop--icon-size-152 {
        width: 9.5rem !important;
    }
    .laptop--icon-size-160 {
        width: 10rem !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .tablet--size-8 {
        font-size: 0.5rem;
        line-height: 1.5;
    }
    .tablet--size-16 {
        font-size: 1rem;
        line-height: 1.5;
    }
    .tablet--size-24 {
        font-size: 1.5rem;
        line-height: 1.5;
    }
    .tablet--size-32 {
        font-size: 2rem;
        line-height: 1.5;
    }
    .tablet--size-40 {
        font-size: 2.5rem;
        line-height: 1.4;
    }
    .tablet--size-48 {
        font-size: 3rem;
        line-height: 1.4;
    }
    .tablet--size-56 {
        font-size: 3.5rem;
        line-height: 1.4;
    }
    .tablet--size-64 {
        font-size: 4rem;
        line-height: 1.4;
    }
    .tablet--size-72 {
        font-size: 4.5rem;
        line-height: 1.4;
    }
    .tablet--size-80 {
        font-size: 5rem;
        line-height: 1.3;
    }
    .tablet--size-88 {
        font-size: 5.5rem;
        line-height: 1.3;
    }
    .tablet--size-96 {
        font-size: 6rem;
        line-height: 1.2;
    }
    .tablet--size-104 {
        font-size: 6.5rem;
        line-height: 1.2;
    }
    .tablet--size-112 {
        font-size: 7rem;
        line-height: 1.2;
    }
    .tablet--size-120 {
        font-size: 7.5rem;
        line-height: 1.2;
    }
    .tablet--size-128 {
        font-size: 8rem;
        line-height: 1.1;
    }
    .tablet--size-136 {
        font-size: 8.5rem;
        line-height: 1.1;
    }
    .tablet--size-144 {
        font-size: 9rem;
        line-height: 1.1;
    }
    .tablet--size-152 {
        font-size: 9.5rem;
        line-height: 1.1;
    }
    .tablet--size-160 {
        font-size: 10rem;
        line-height: 1.1;
    }
    .tablet--line-height-8 {
        line-height: 0.5rem;
    }
    .tablet--line-height-16 {
        line-height: 1rem;
    }
    .tablet--line-height-24 {
        line-height: 1.5rem;
    }
    .tablet--line-height-32 {
        line-height: 2rem;
    }
    .tablet--line-height-40 {
        line-height: 2.5rem;
    }
    .tablet--line-height-48 {
        line-height: 3rem;
    }
    .tablet--line-height-56 {
        line-height: 3.5rem;
    }
    .tablet--line-height-64 {
        line-height: 4rem;
    }
    .tablet--line-height-72 {
        line-height: 4.5rem;
    }
    .tablet--line-height-80 {
        line-height: 5rem;
    }
    .tablet--line-height-88 {
        line-height: 5.5rem;
    }
    .tablet--line-height-96 {
        line-height: 6rem;
    }
    .tablet--line-height-104 {
        line-height: 6.5rem;
    }
    .tablet--line-height-112 {
        line-height: 7rem;
    }
    .tablet--line-height-120 {
        line-height: 7.5rem;
    }
    .tablet--line-height-128 {
        line-height: 8rem;
    }
    .tablet--line-height-136 {
        line-height: 8.5rem;
    }
    .tablet--line-height-144 {
        line-height: 9rem;
    }
    .tablet--line-height-152 {
        line-height: 9.5rem;
    }
    .tablet--line-height-160 {
        line-height: 10rem;
    }
    .tablet--icon-size-8 {
        width: 0.5rem !important;
    }
    .tablet--icon-size-16 {
        width: 1rem !important;
    }
    .tablet--icon-size-24 {
        width: 1.5rem !important;
    }
    .tablet--icon-size-32 {
        width: 2rem !important;
    }
    .tablet--icon-size-40 {
        width: 2.5rem !important;
    }
    .tablet--icon-size-48 {
        width: 3rem !important;
    }
    .tablet--icon-size-56 {
        width: 3.5rem !important;
    }
    .tablet--icon-size-64 {
        width: 4rem !important;
    }
    .tablet--icon-size-72 {
        width: 4.5rem !important;
    }
    .tablet--icon-size-80 {
        width: 5rem !important;
    }
    .tablet--icon-size-88 {
        width: 5.5rem !important;
    }
    .tablet--icon-size-96 {
        width: 6rem !important;
    }
    .tablet--icon-size-104 {
        width: 6.5rem !important;
    }
    .tablet--icon-size-112 {
        width: 7rem !important;
    }
    .tablet--icon-size-120 {
        width: 7.5rem !important;
    }
    .tablet--icon-size-128 {
        width: 8rem !important;
    }
    .tablet--icon-size-136 {
        width: 8.5rem !important;
    }
    .tablet--icon-size-144 {
        width: 9rem !important;
    }
    .tablet--icon-size-152 {
        width: 9.5rem !important;
    }
    .tablet--icon-size-160 {
        width: 10rem !important;
    }
}

@media screen and (max-width: 480px) {
    .smartphone--size-8 {
        font-size: 0.5rem;
        line-height: 1.5;
    }
    .smartphone--size-16 {
        font-size: 1rem;
        line-height: 1.5;
    }
    .smartphone--size-24 {
        font-size: 1.5rem;
        line-height: 1.5;
    }
    .smartphone--size-32 {
        font-size: 2rem;
        line-height: 1.5;
    }
    .smartphone--size-40 {
        font-size: 2.5rem;
        line-height: 1.4;
    }
    .smartphone--size-48 {
        font-size: 3rem;
        line-height: 1.4;
    }
    .smartphone--size-56 {
        font-size: 3.5rem;
        line-height: 1.4;
    }
    .smartphone--size-64 {
        font-size: 4rem;
        line-height: 1.4;
    }
    .smartphone--size-72 {
        font-size: 4.5rem;
        line-height: 1.4;
    }
    .smartphone--size-80 {
        font-size: 5rem;
        line-height: 1.3;
    }
    .smartphone--size-88 {
        font-size: 5.5rem;
        line-height: 1.3;
    }
    .smartphone--size-96 {
        font-size: 6rem;
        line-height: 1.2;
    }
    .smartphone--size-104 {
        font-size: 6.5rem;
        line-height: 1.2;
    }
    .smartphone--size-112 {
        font-size: 7rem;
        line-height: 1.2;
    }
    .smartphone--size-120 {
        font-size: 7.5rem;
        line-height: 1.2;
    }
    .smartphone--size-128 {
        font-size: 8rem;
        line-height: 1.1;
    }
    .smartphone--size-136 {
        font-size: 8.5rem;
        line-height: 1.1;
    }
    .smartphone--size-144 {
        font-size: 9rem;
        line-height: 1.1;
    }
    .smartphone--size-152 {
        font-size: 9.5rem;
        line-height: 1.1;
    }
    .smartphone--size-160 {
        font-size: 10rem;
        line-height: 1.1;
    }
    .smartphone--line-height-8 {
        line-height: 0.5rem;
    }
    .smartphone--line-height-16 {
        line-height: 1rem;
    }
    .smartphone--line-height-24 {
        line-height: 1.5rem;
    }
    .smartphone--line-height-32 {
        line-height: 2rem;
    }
    .smartphone--line-height-40 {
        line-height: 2.5rem;
    }
    .smartphone--line-height-48 {
        line-height: 3rem;
    }
    .smartphone--line-height-56 {
        line-height: 3.5rem;
    }
    .smartphone--line-height-64 {
        line-height: 4rem;
    }
    .smartphone--line-height-72 {
        line-height: 4.5rem;
    }
    .smartphone--line-height-80 {
        line-height: 5rem;
    }
    .smartphone--line-height-88 {
        line-height: 5.5rem;
    }
    .smartphone--line-height-96 {
        line-height: 6rem;
    }
    .smartphone--line-height-104 {
        line-height: 6.5rem;
    }
    .smartphone--line-height-112 {
        line-height: 7rem;
    }
    .smartphone--line-height-120 {
        line-height: 7.5rem;
    }
    .smartphone--line-height-128 {
        line-height: 8rem;
    }
    .smartphone--line-height-136 {
        line-height: 8.5rem;
    }
    .smartphone--line-height-144 {
        line-height: 9rem;
    }
    .smartphone--line-height-152 {
        line-height: 9.5rem;
    }
    .smartphone--line-height-160 {
        line-height: 10rem;
    }
    .smartphone--icon-size-8 {
        width: 0.5rem !important;
    }
    .smartphone--icon-size-16 {
        width: 1rem !important;
    }
    .smartphone--icon-size-24 {
        width: 1.5rem !important;
    }
    .smartphone--icon-size-32 {
        width: 2rem !important;
    }
    .smartphone--icon-size-40 {
        width: 2.5rem !important;
    }
    .smartphone--icon-size-48 {
        width: 3rem !important;
    }
    .smartphone--icon-size-56 {
        width: 3.5rem !important;
    }
    .smartphone--icon-size-64 {
        width: 4rem !important;
    }
    .smartphone--icon-size-72 {
        width: 4.5rem !important;
    }
    .smartphone--icon-size-80 {
        width: 5rem !important;
    }
    .smartphone--icon-size-88 {
        width: 5.5rem !important;
    }
    .smartphone--icon-size-96 {
        width: 6rem !important;
    }
    .smartphone--icon-size-104 {
        width: 6.5rem !important;
    }
    .smartphone--icon-size-112 {
        width: 7rem !important;
    }
    .smartphone--icon-size-120 {
        width: 7.5rem !important;
    }
    .smartphone--icon-size-128 {
        width: 8rem !important;
    }
    .smartphone--icon-size-136 {
        width: 8.5rem !important;
    }
    .smartphone--icon-size-144 {
        width: 9rem !important;
    }
    .smartphone--icon-size-152 {
        width: 9.5rem !important;
    }
    .smartphone--icon-size-160 {
        width: 10rem !important;
    }
}

nav.socials {
    margin-top: 1rem !important;
}

nav.socials ul, nav.socials li, nav.socials a {
    display: inline-block;
}

nav.socials li {
    margin-left: 1.5rem;
}

nav.socials li:first-child {
    margin-left: 0;
}

nav.socials svg {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: text-bottom;
}

nav.socials svg path {
    fill: white;
    transition: fill ease-in-out 0.3s;
}

html.no-touchevents nav.socials a:hover svg path {
    fill: #7F8D32;
}

html.no-touchevents nav.socials a.twitter:hover svg path {
    fill: #00aced;
}

html.no-touchevents nav.socials a.instagram:hover svg path {
    fill: #bc2a8d;
}

html.no-touchevents nav.socials a.tumblr:hover svg path {
    fill: #32506d;
}

@keyframes header-overlay-active {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes header-overlay-inactive {
    from {
        top: 0;
        opacity: 1;
    }
    99% {
        top: 0;
    }
    to {
        opacity: 0;
        top: -100%;
    }
}

.page-uid-1 #header__logo, .page-uid-7 #header__logo, .page-uid-8 #header__logo, .page-uid-10 #header__logo, .page-uid-11 #header__logo, .page-uid-12 .page-uid-14 #header__logo, .page-uid-15 #header__logo, .page-uid-16 #header__logo, .page-uid-18 #header__logo, .page-uid-19 #header__logo, .page-uid-20 #header__logo, .page-uid-22 #header__logo {
    background-image: url("../gfx/Logo/logo_w.svg");
}

@media screen and (max-width: 480px) {
    .page-uid-1 #header__logo, .page-uid-7 #header__logo, .page-uid-8 #header__logo, .page-uid-10 #header__logo, .page-uid-11 #header__logo, .page-uid-12 .page-uid-14 #header__logo, .page-uid-15 #header__logo, .page-uid-16 #header__logo, .page-uid-18 #header__logo, .page-uid-19 #header__logo, .page-uid-20 #header__logo, .page-uid-22 #header__logo {
        background-image: url("../gfx/Logo/logo_b.svg");
    }
}

.page-uid-6 #header__logo, .page-uid-20 #header__logo, .page-uid-11 #header__logo, .page-uid-12 #header__logo {
    background-image: url("../gfx/Logo/logo_b.svg");
}

.page-uid-10 #header__logo, .page-uid-17 #header__logo {
    background-image: url("../gfx/Logo/logo_bw.svg");
}

@media screen and (max-width: 480px) {
    .page-uid-10 #header__logo, .page-uid-17 #header__logo {
        background-image: url("../gfx/Logo/logo_b.svg");
    }
}

#header {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

@media screen and (min-width: 1791px) {
    #header {
        left: 50%;
        right: auto;
        width: 1790px;
        margin-left: -895px;
    }
}

#header__logo {
    position: absolute;
    top: 60px;
    right: 64px;
    width: 130px;
    height: 130px;
}

html.header-overlay-active #header__logo {
    display: none;
}

#header__toggle {
    position: fixed;
    top: 24px;
    width: 41px;
    z-index: 102;
}

@media screen and (max-width: 1820px) {
    #header__toggle {
        margin-left: 64px;
    }
}

#header__toggle__button {
    position: absolute;
    width: 41px;
    height: 41px;
    left: 50%;
    top: 50px;
    transform: translate(-50%, -50%);
}

#header__toggle__button .bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 41px;
    height: 3px;
    background-color: #000;
    transition: width 0.3s ease-in-out;
}

#header__toggle__button .bars:before, #header__toggle__button .bars:after {
    content: "";
    position: absolute;
    right: 0;
    width: 41px;
    height: 3px;
    background-color: #000;
    transition: all 0.3s ease-in-out;
}

#header__toggle__button .bars:before {
    top: -12px;
}

#header__toggle__button .bars:after {
    top: 12px;
}

html.header-overlay-active #header__toggle__button .bars {
    width: 0;
}

html.header-overlay-active #header__toggle__button .bars:before, html.header-overlay-active #header__toggle__button .bars:after {
    right: -27px;
    top: 0;
    width: 55px;
    background-color: #000;
}

html.header-overlay-active #header__toggle__button .bars:before {
    transform: rotate(-45deg);
}

html.header-overlay-active #header__toggle__button .bars:after {
    right: -27px;
    top: 0;
    width: 55px;
    transform: rotate(45deg);
}

#header__overlay {
    z-index: 101;
    position: fixed;
    top: -100%;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

#header__overlay .overlaycontainer {
    height: 100%;
}

#header__overlay .navi-image {
    position: absolute;
    right: 9999px;
}

html.header-overlay-active #header__overlay .whitediv {
    width: 100%;
    height: 200px;
    background-color: red;
}

html.header-overlay-active #header__overlay .navi-image {
    background-image: url("../../../../../../fileadmin/Data/Images/Navi/navi-image.jpg");
    background-size: cover;
    position: absolute;
    width: 55%;
    height: 100%;
    right: 0;
    top: 0;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    html.header-overlay-active #header__overlay .navi-image {
        width: 45%;
    }
}

@media screen and (max-width: 768px) {
    html.header-overlay-active #header__overlay .navi-image {
        position: absolute;
        right: 9999px;
    }
}

#header__overlay .links a {
    text-decoration: none;
}

#header__overlay__logo {
    position: absolute;
    top: 60px;
    right: 64px;
    width: 130px;
    height: 130px;
    background-image: url("../gfx/Logo/logo_w.svg");
}

@media screen and (max-width: 768px) {
    #header__overlay__logo {
        background-image: url("../gfx/Logo/logo_b.svg");
    }
}

#header__overlay__socials {
    display: none;
}

html.header-overlay-active #header__overlay {
    animation-duration: 0.8s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-name: header-overlay-active;
    animation-fill-mode: forwards;
    top: 0;
}

html.header-overlay-inactive #header__overlay {
    animation-duration: 0.8s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-name: header-overlay-inactive;
    animation-fill-mode: forwards;
}

@media screen and (max-width: 1280px) {
    #header__toggle {
        margin-left: 32px;
    }
}

@media screen and (max-width: 768px) {
    #header__logo {
        right: 32px;
        top: 23px;
        width: 90px;
        height: 90px;
    }
    #header__toggle {
        top: 0;
    }
    #header__toggle__button {
        top: 67px;
        transform: translate(-50%, -50%);
    }
    #header__overlay__logo {
        right: 32px;
        top: 23px;
        width: 90px;
        height: 90px;
    }
}

@media screen and (max-width: 480px) {
    #header__logo {
        right: 16px;
        top: 23px;
        width: 90px;
        height: 90px;
    }
    #header__toggle {
        margin-left: 16px;
        height: auto;
    }
    #header__overlay__logo {
        right: 16px;
        top: 23px;
        width: 90px;
        height: 90px;
    }
    #header__overlay__socials {
        margin-left: 16px;
    }
}

nav.main {
    padding-top: 250px;
    padding-left: 64px;
}

@media screen and (max-width: 768px) {
    nav.main {
        padding-left: 32px;
        padding-top: 140px;
    }
}

@media screen and (max-width: 480px) {
    nav.main {
        padding-left: 16px;
    }
}

nav.main li {
    margin-top: 1rem;
}

nav.main li:first-child {
    margin-top: 0;
}

nav.main a {
    display: block;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: #000;
    line-height: 1.7;
    font-size: 30px;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
}

nav.main a span.icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
    transition: transform ease-in-out 0.3s, opacity linear 0.8s;
    opacity: 0;
    width: 4.84375vw;
}

nav.main a span.icon svg {
    width: 100%;
    height: auto;
}

nav.main a span.label {
    margin-left: 0;
    transition: margin-left ease-in-out 0.3s;
}

html.no-touchevents nav.main a:hover, nav.main a.active {
    color: #7F8D32;
}

html.no-touchevents nav.main a:hover span.icon, nav.main a.active span.icon {
    transform: translate(0, -50%);
    opacity: 1;
}

html.no-touchevents nav.main a:hover span.label, nav.main a.active span.label {
    margin-left: 5.32812vw;
}

@media screen and (max-width: 600px) {
    nav.main a {
        font-size: 26px;
    }
}

@media screen and (max-width: 1000px) {
    nav.main a span.icon {
        width: 45px;
    }
    html.no-touchevents nav.main a:hover span.label, nav.main a.active span.label {
        margin-left: 49.5px;
    }
}

@media screen and (min-width: 1281px) {
    nav.main a {
        font-size: 30px;
    }
    nav.main a span.icon {
        width: 62px;
    }
    html.no-touchevents nav.main a:hover span.label, nav.main a.active span.label {
        margin-left: 68.2px;
    }
}

nav.meta {
    padding-top: 3rem;
    padding-left: 64px;
}

@media screen and (max-width: 768px) {
    nav.meta {
        padding-left: 32px;
        padding-top: 3vw;
    }
}

@media screen and (max-width: 480px) {
    nav.meta {
        padding-left: 16px;
    }
}

nav.meta ul, nav.meta li, nav.meta a {
    display: inline-block;
}

nav.meta li:before {
    color: white;
    content: "|";
    margin: 0 0.5rem;
}

nav.meta li:first-child {
    margin-left: 0;
}

nav.meta li:first-child:before {
    display: none;
}

nav.meta a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    font-weight: 400;
}

html.no-touchevents nav.meta a:hover, nav.meta a.active {
    color: #7F8D32;
    text-decoration: underline;
}

#footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

#footer .footercontent {
    padding: 35px 64px;
}

#footer .address {
    width: 33.3%;
    display: inline-block;
    vertical-align: top;
}

#footer .address a {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    text-decoration: none;
}

#footer .morelinks {
    width: 33.3%;
    display: inline-block;
    vertical-align: top;
}

#footer .morelinks ul {
    display: inline-block;
    list-style-type: none;
}

#footer .morelinks ul a {
    text-decoration: none;
}

#footer .morelinks .morelinks-first {
    margin-left: 20%;
    margin-right: 20%;
}

#footer .logocontainer {
    display: inline-block;
    width: 33.3%;
}

#footer .logocontainer .logos {
    display: flex;
    vertical-align: middle;
}

#footer .logocontainer .logos .logo-first, #footer .logocontainer .logos .logo-second {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer .logocontainer .logos .logo-first img, #footer .logocontainer .logos .logo-second img {
    max-height: 50px;
    width: auto;
}

#footer .upperborder {
    border-top: 1px solid #000;
    display: inline;
    padding-top: 0.75rem;
}

#footer .hr-footer {
    margin-top: -40px;
}

@media screen and (max-width: 1820px) {
    #footer .hr-footer {
        margin: -40px 16px 0;
    }
}

#footer__logo {
    display: block;
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    transform: translateX(13%);
}

@media screen and (max-width: 1280px) {
    #footer .footercontent {
        padding: 35px 32px;
    }
    #footer .address {
        width: 100%;
        padding-bottom: 30px;
    }
    #footer .morelinks {
        width: 50%;
    }
    #footer .morelinks ul {
        padding-left: 0px;
    }
    #footer .morelinks .morelinks-first {
        margin-left: 0px;
    }
    #footer .logocontainer {
        width: 50%;
    }
}

@media screen and (max-width: 480px) {
    #footer .footercontent {
        padding: 35px 0px;
    }
    #footer .address {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
    #footer .address .companyname {
        display: block;
    }
    #footer .mailaddress {
        display: block;
    }
    #footer .morelinks {
        width: 100%;
        padding: 15px 16px 0;
        margin-bottom: 15px;
        background-color: rgba(235, 235, 235, 0.6);
    }
    #footer .morelinks ul {
        padding-left: 0px;
        line-height: 2.3;
    }
    #footer .morelinks .morelinks-first {
        margin-right: 30%;
    }
    #footer .logocontainer {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }
    #footer .logocontainer .logos .logo-first img, #footer .logocontainer .logos .logo-second img {
        width: 100%;
        max-height: initial;
    }
}

nav.footer {
    display: inline-block;
    margin-left: 1rem;
}

nav.footer ul, nav.footer li, nav.footer a {
    display: inline-block;
}

nav.footer li:before {
    color: white;
    content: "|";
    margin: 0 0.5rem;
}

nav.footer li:first-child {
    margin-left: 0;
}

nav.footer li:first-child:before {
    display: none;
}

nav.footer a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 400;
}

html.no-touchevents nav.footer a:hover, nav.footer a.active {
    color: white;
    text-decoration: underline;
}

a.ce-linkarrow--light {
    color: white;
}

.text-columne-count-1,
.text-columne-count-2,
.text-columne-count-3,
.text-columne-count-4,
.text-columne-count-5,
.text-columne-count-6,
.text-columne-count-7,
.text-columne-count-8 {
    column-gap: 0;
}

.text-columne-count-1 {
    column-count: 1;
}

.text-columne-count-2 {
    column-count: 2;
}

.text-columne-count-3 {
    column-count: 3;
}

.text-columne-count-4 {
    column-count: 4;
}

.text-columne-count-5 {
    column-count: 5;
}

.text-columne-count-6 {
    column-count: 6;
}

.text-columne-count-8 {
    column-count: 8;
}

.text-columne-gap-8 {
    column-gap: 0.5rem;
}

.text-columne-gap-16 {
    column-gap: 1rem;
}

.text-columne-gap-24 {
    column-gap: 1.5rem;
}

.text-columne-gap-32 {
    column-gap: 2rem;
}

.text-columne-gap-40 {
    column-gap: 2.5rem;
}

.text-columne-gap-48 {
    column-gap: 3rem;
}

.text-columne-gap-56 {
    column-gap: 3.5rem;
}

.text-columne-gap-64 {
    column-gap: 4rem;
}

.text-columne-gap-72 {
    column-gap: 4.5rem;
}

.text-columne-gap-80 {
    column-gap: 5rem;
}

@media screen and (min-width: 1281px) {
    .desktop--text-columne-count-1 {
        column-count: 1;
    }
    .desktop--text-columne-count-2 {
        column-count: 2;
    }
    .desktop--text-columne-count-3 {
        column-count: 3;
    }
    .desktop--text-columne-count-4 {
        column-count: 4;
    }
    .desktop--text-columne-count-5 {
        column-count: 5;
    }
    .desktop--text-columne-count-6 {
        column-count: 6;
    }
    .desktop--text-columne-count-8 {
        column-count: 8;
    }
    .desktop--text-columne-gap-8 {
        column-gap: 0.5rem;
    }
    .desktop--text-columne-gap-16 {
        column-gap: 1rem;
    }
    .desktop--text-columne-gap-24 {
        column-gap: 1.5rem;
    }
    .desktop--text-columne-gap-32 {
        column-gap: 2rem;
    }
    .desktop--text-columne-gap-40 {
        column-gap: 2.5rem;
    }
    .desktop--text-columne-gap-48 {
        column-gap: 3rem;
    }
    .desktop--text-columne-gap-56 {
        column-gap: 3.5rem;
    }
    .desktop--text-columne-gap-64 {
        column-gap: 4rem;
    }
    .desktop--text-columne-gap-72 {
        column-gap: 4.5rem;
    }
    .desktop--text-columne-gap-80 {
        column-gap: 5rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .laptop--text-columne-count-1 {
        column-count: 1;
    }
    .laptop--text-columne-count-2 {
        column-count: 2;
    }
    .laptop--text-columne-count-3 {
        column-count: 3;
    }
    .laptop--text-columne-count-4 {
        column-count: 4;
    }
    .laptop--text-columne-count-5 {
        column-count: 5;
    }
    .laptop--text-columne-count-6 {
        column-count: 6;
    }
    .laptop--text-columne-count-8 {
        column-count: 8;
    }
    .laptop--text-columne-gap-8 {
        column-gap: 0.5rem;
    }
    .laptop--text-columne-gap-16 {
        column-gap: 1rem;
    }
    .laptop--text-columne-gap-24 {
        column-gap: 1.5rem;
    }
    .laptop--text-columne-gap-32 {
        column-gap: 2rem;
    }
    .laptop--text-columne-gap-40 {
        column-gap: 2.5rem;
    }
    .laptop--text-columne-gap-48 {
        column-gap: 3rem;
    }
    .laptop--text-columne-gap-56 {
        column-gap: 3.5rem;
    }
    .laptop--text-columne-gap-64 {
        column-gap: 4rem;
    }
    .laptop--text-columne-gap-72 {
        column-gap: 4.5rem;
    }
    .laptop--text-columne-gap-80 {
        column-gap: 5rem;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .tablet--text-columne-count-1 {
        column-count: 1;
    }
    .tablet--text-columne-count-2 {
        column-count: 2;
    }
    .tablet--text-columne-count-3 {
        column-count: 3;
    }
    .tablet--text-columne-count-4 {
        column-count: 4;
    }
    .tablet--text-columne-count-5 {
        column-count: 5;
    }
    .tablet--text-columne-count-6 {
        column-count: 6;
    }
    .tablet--text-columne-count-8 {
        column-count: 8;
    }
    .tablet--text-columne-gap-8 {
        column-gap: 0.5rem;
    }
    .tablet--text-columne-gap-16 {
        column-gap: 1rem;
    }
    .tablet--text-columne-gap-24 {
        column-gap: 1.5rem;
    }
    .tablet--text-columne-gap-32 {
        column-gap: 2rem;
    }
    .tablet--text-columne-gap-40 {
        column-gap: 2.5rem;
    }
    .tablet--text-columne-gap-48 {
        column-gap: 3rem;
    }
    .tablet--text-columne-gap-56 {
        column-gap: 3.5rem;
    }
    .tablet--text-columne-gap-64 {
        column-gap: 4rem;
    }
    .tablet--text-columne-gap-72 {
        column-gap: 4.5rem;
    }
    .tablet--text-columne-gap-80 {
        column-gap: 5rem;
    }
}

@media screen and (max-width: 480px) {
    .smartphone--text-columne-count-1 {
        column-count: 1;
    }
    .smartphone--text-columne-count-2 {
        column-count: 2;
    }
    .smartphone--text-columne-count-3 {
        column-count: 3;
    }
    .smartphone--text-columne-count-4 {
        column-count: 4;
    }
    .smartphone--text-columne-count-5 {
        column-count: 5;
    }
    .smartphone--text-columne-count-6 {
        column-count: 6;
    }
    .smartphone--text-columne-count-8 {
        column-count: 8;
    }
    .smartphone--text-columne-gap-8 {
        column-gap: 0.5rem;
    }
    .smartphone--text-columne-gap-16 {
        column-gap: 1rem;
    }
    .smartphone--text-columne-gap-24 {
        column-gap: 1.5rem;
    }
    .smartphone--text-columne-gap-32 {
        column-gap: 2rem;
    }
    .smartphone--text-columne-gap-40 {
        column-gap: 2.5rem;
    }
    .smartphone--text-columne-gap-48 {
        column-gap: 3rem;
    }
    .smartphone--text-columne-gap-56 {
        column-gap: 3.5rem;
    }
    .smartphone--text-columne-gap-64 {
        column-gap: 4rem;
    }
    .smartphone--text-columne-gap-72 {
        column-gap: 4.5rem;
    }
    .smartphone--text-columne-gap-80 {
        column-gap: 5rem;
    }
}

.standard-form input[type=text],
.standard-form input[type=search],
.standard-form input[type=number],
.standard-form textarea,
.standard-form select {
    display: block;
    padding: 0.5rem;
    outline: 0 none;
    width: 100%;
    border: none;
    border: 1px solid #424242;
    border-radius: 3px;
    font-size: 18px;
    font-weight: 400;
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.standard-form input[type=text].f3-form-error,
.standard-form input[type=search].f3-form-error,
.standard-form input[type=number].f3-form-error,
.standard-form textarea.f3-form-error,
.standard-form select.f3-form-error {
    border-color: #FF0000;
}

.standard-form input[type=number] {
    text-align: right;
}

.standard-form textarea {
    height: 12em;
    resize: vertical;
    line-height: 1.3;
}

.standard-form select::-ms-expand {
    display: none;
}

.standard-form select {
    background-image: url("../Images/Icons/select.svg");
    background-position: right 20px center;
    background-repeat: no-repeat;
    background-size: 16px 10px;
    padding-right: 50px;
}

.standard-form .checkbox-replacement {
    display: inline-block;
    vertical-align: unset;
}

.standard-form .checkbox-replacement span {
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid #cdcdcd;
}

.standard-form .checkbox-replacement span.checked {
    border-color: #7F8D32;
    background-color: #7F8D32;
}

.standard-form input:-moz-placeholder {
    color: #969696;
    font-size: 18px;
    font-weight: 400;
}

.standard-form :-ms-input-placeholder {
    color: #969696;
    font-size: 18px;
    font-weight: 400;
}

.standard-form ::-moz-placeholder {
    color: #969696;
    font-size: 18px;
    font-weight: 400;
}

.standard-form ::-webkit-input-placeholder {
    color: #969696;
    font-size: 18px;
    font-weight: 400;
}

.form-field__info {
    font-size: 80%;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.form-button {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.75em;
    cursor: pointer;
    outline: 0 none;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 700;
    vertical-align: middle;
    text-decoration: none;
    color: white;
    background-color: #cdcdcd;
    border: 1px solid #cdcdcd;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-left: 1rem;
    -webkit-appearance: none;
    border-radius: 3px;
}

.form-button--grey {
    background-color: #c6c6c6;
    border-color: #c6c6c6;
}

.form-button--dark-grey {
    background-color: #424242;
    border-color: #424242;
}

.form-button--highlight {
    background-color: #7F8D32;
    border-color: #7F8D32;
}

.form-button--white {
    background-color: white;
    border-color: #cdcdcd;
}

.form-button:first-child {
    margin-left: 0;
}

.form-button--full-width {
    width: 100%;
}

html.no-touchevents .form-button:hover {
    text-decoration: none;
    color: black;
    background-color: white;
    border-color: black;
}

.box-error .form-error-message {
    position: relative;
    color: white;
    background-color: #FF0000;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0.04em;
    font-weight: 400;
    padding: 5px;
    margin-top: 10px;
    display: inline-block;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    width: initial;
}

.box-error .form-error-message:before {
    border: 5px solid transparent;
    border-bottom-color: #FF0000;
    bottom: 100%;
    left: 5px;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.form-error-message {
    width: 100%;
    border-top: 3px solid #FF0000;
    position: relative;
    color: #FF0000;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0.04em;
    font-weight: 400;
    padding: 5px;
    margin-top: -1px;
    display: inline-block;
}

.ce-phone-email__phone, .ce-phone-email__email {
    display: block;
    padding: 2rem 1rem;
    text-align: center;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
}

.ce-phone-email__phone i, .ce-phone-email__email i {
    font-size: 4.29688vw;
}

@media screen and (max-width: 600px) {
    .ce-phone-email__phone i, .ce-phone-email__email i {
        font-size: 26px;
    }
}

@media screen and (min-width: 1281px) {
    .ce-phone-email__phone i, .ce-phone-email__email i {
        font-size: 55px;
    }
}

.ce-phone-email__phone .label, .ce-phone-email__email .label {
    display: block;
    margin-top: 1rem;
    line-height: 1.2;
    font-size: 3.51562vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 830px) {
    .ce-phone-email__phone .label, .ce-phone-email__email .label {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .ce-phone-email__phone .label, .ce-phone-email__email .label {
        font-size: 45px;
    }
}

.ce-phone-email__phone {
    background-color: #424242;
    color: white;
}

html.no-touchevents .ce-phone-email__phone:hover {
    text-decoration: none;
    background-color: #686868;
    color: #d9d9d9;
}

.ce-phone-email__email {
    background-color: #EBEBEB;
    color: #424242;
}

html.no-touchevents .ce-phone-email__email:hover {
    text-decoration: none;
    background-color: #dadada;
    color: #646464;
}

.ce-images-slider__wrapper {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    overflow-x: auto;
}

@media screen and (max-width: 480px) {
    .ce-images-slider__wrapper {
        height: 250px;
    }
}

.ce-images-slider__items {
    display: table;
    height: inherit;
}

.ce-images-slider__item {
    display: table-cell;
    height: inherit;
}

.ce-images-slider__item picture {
    margin-left: 40px;
}

@media screen and (max-width: 480px) {
    .ce-images-slider__item picture {
        margin-left: 20px;
    }
}

.ce-images-slider__item picture,
.ce-images-slider__item img {
    display: block;
    width: auto;
    max-width: unset;
    height: inherit;
}

.ce-images-slider__item:first-child picture {
    margin-left: 0;
}

.ce-images-slider .slider-btn {
    display: inline-block;
    color: #7F8D32;
    width: 23px;
}

.ce-images-slider .slider-btn--right {
    margin-left: 50px;
}

.ce-images-slider__buttons {
    text-align: right;
}

.ce-images-slider-container__wrapper {
    position: relative;
    width: 100%;
}

.ce-images-slider-container__items {
    display: table;
    width: 100%;
}

.ce-images-slider-container__item {
    width: 100%;
}

.ce-images-slider-container__item picture,
.ce-images-slider-container__item img {
    display: block;
    width: 100%;
}

.ce-images-slider-container .sliderfade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 5s;
    animation-name: fade;
    animation-duration: 5s;
}

@-webkit-keyframes fade {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.ce-images-slider-container .dotcontainer {
    position: absolute;
    left: 10px;
    bottom: 10px;
}

.ce-images-slider-container .dotcontainer .dot {
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
    display: inline-block;
    transition: background-color 0.6s ease;
}

@media screen and (max-width: 480px) {
    .ce-images-slider-container .dotcontainer .dot {
        height: 10px;
        width: 10px;
        margin: 0 3px;
    }
}

.ce-images-slider-container .dotcontainer .active {
    background-color: rgba(0, 0, 0, 0);
}

.ce-images-slider-container .slider-btn {
    display: inline-block;
    color: #7F8D32;
    width: 23px;
}

.ce-images-slider-container .slider-btn--right {
    margin-left: 50px;
}

.ce-images-slider-container__buttons {
    text-align: right;
}

.page-uid-10 .contact-form .initial-radio label {
    line-height: 1.2;
    font-size: 3.125vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    .page-uid-10 .contact-form .initial-radio label {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .page-uid-10 .contact-form .initial-radio label {
        font-size: 40px;
    }
}

.contact-form #initial-selection .form-field {
    margin-top: 30px;
}

.contact-form #initial-selection .form-field--radio {
    display: block;
    margin-right: initial;
}

.contact-form label {
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.71875vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    .contact-form label {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .contact-form label {
        font-size: 22px;
    }
}

.contact-form label.font-bigger {
    line-height: 1.2;
    font-size: 3.125vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    .contact-form label.font-bigger {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .contact-form label.font-bigger {
        font-size: 40px;
    }
}

.contact-form .form-field--textfield {
    margin-bottom: 60px;
}

.contact-form .form-field--textfield input {
    width: 100%;
    height: 30px;
}

.contact-form .form-field--select {
    margin-bottom: 60px;
}

.contact-form .form-field--section--space {
    margin: 60px 0;
}

.contact-form .form-field--radio {
    display: inline-block;
    margin-right: 60px;
    margin-top: 20px;
}

.contact-form .form-field--radio--input {
    margin-right: 10px;
}

.contact-form .lower-section label {
    margin-bottom: 20px;
}

.contact-form .lower-section #section-coupon, .contact-form .lower-section #section-coupon-reciever {
    width: 33.3%;
}

.contact-form .lower-section #section-coupon .form-field--textfield, .contact-form .lower-section #section-coupon-reciever .form-field--textfield {
    margin: 20px 0 60px;
}

.contact-form .lower-section #section-amountOfAdults .form-field--select, .contact-form .lower-section #section-amountOfKids .form-field--select {
    margin-bottom: 20px;
}

.contact-form .lower-section #adultfields, .contact-form .lower-section #kidfields {
    margin-bottom: 30px;
}

.contact-form .lower-section .form-field__label {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: initial;
}

.contact-form .lower-section .form-field--checkbox {
    margin-top: 10px;
}

.contact-form .lower-section .form-field--checkbox:last-child {
    width: 50%;
}

@media screen and (max-width: 1280px) {
    .contact-form .lower-section .form-field--checkbox:last-child {
        width: 100%;
    }
}

.contact-form .lower-section .form-field--radio--label label {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: initial;
}

.contact-form input {
    border: 0;
    border-bottom: 1px solid black;
    padding: 0 10px;
    line-height: 1.2;
    font-size: 1.71875vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    .contact-form input {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .contact-form input {
        font-size: 22px;
    }
}

.contact-form input::placeholder {
    line-height: 1.2;
    font-size: 1.71875vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #707070;
}

@media screen and (max-width: 780px) {
    .contact-form input::placeholder {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .contact-form input::placeholder {
        font-size: 22px;
    }
}

.contact-form input[type="radio"] {
    transform: translateY(-3px);
}

.contact-form #green-section {
    width: 100%;
    background-color: #7F8D32;
    padding: 40px 0;
}

.contact-form #green-section .form-field--radio-set {
    margin: 40px 0;
}

.contact-form #green-section .form-field--radio {
    display: initial;
    margin-right: initial;
}

.contact-form #pricefield {
    margin-top: 75px;
}

.contact-form #pricefield input {
    border-bottom: initial;
    color: #7F8D32;
}

.contact-form #pricefield p {
    line-height: 1.2;
    font-size: 1.71875vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

@media screen and (max-width: 780px) {
    .contact-form #pricefield p {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .contact-form #pricefield p {
        font-size: 22px;
    }
}

.contact-form #pricefield p .price {
    color: #7F8D32;
}

.contact-form #section-amountOfAdults, .contact-form #sectionAmountOfKids {
    margin-top: 50px;
}

.contact-form #property-amountOfAdults {
    margin-bottom: 10px;
}

.contact-form #property-amountOfAdults, .contact-form #property-amountOfKids {
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.contact-form #property-message {
    width: 100%;
    height: 200px;
}

.contact-form textarea {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: initial;
    color: black;
    padding: 10px;
    margin-top: 20px;
}

.contact-form textarea::placeholder {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: initial;
    color: #707070;
}

.contact-form #form-button-bbg {
    width: 100%;
    height: 100px;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    background-color: #7F8D32;
    line-height: 1.2;
    font-size: 3.125vw;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    margin: 0;
}

@media screen and (max-width: 780px) {
    .contact-form #form-button-bbg {
        font-size: 20px;
    }
}

@media screen and (min-width: 1281px) {
    .contact-form #form-button-bbg {
        font-size: 40px;
    }
}

.contact-form #form-button-bbg:hover {
    background-color: rgba(127, 141, 50, 0.7);
    border: initial;
}

.contact-form .last-section {
    width: 75%;
}

.contact-form .last-section .last-text {
    margin-top: 20px;
}

.contact-form .last-section .last-text p {
    font-size: 16px;
}

.contact-form .grid-booking {
    margin-left: 0;
}

.contact-form .header-picture {
    padding-left: 0;
}

.contact-form #adultfields {
    width: 100%;
}

.contact-form #kidfields {
    width: 100%;
}

.contact-form .success-wrapper {
    width: 100%;
    height: 600px;
}

.contact-form .success-wrapper .success {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 1280px) {
    .contact-form .lower-section #section-coupon, .contact-form .lower-section #section-coupon-reciever {
        width: 50%;
    }
    .contact-form .last-section {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .contact-form .lower-section #section-coupon, .contact-form .lower-section #section-coupon-reciever {
        width: 100%;
    }
    .contact-form #green-section label {
        font-size: 3.6vw;
    }
    .contact-form .form-field--radio {
        display: block !important;
    }
    .contact-form #lowerform .form-field--radio--label {
        position: absolute;
        transform: translateY(-2px);
    }
}

.tx-pb-social .pb-list {
    margin-left: -1rem;
    margin-bottom: -1rem;
}

.tx-pb-social .pb-list-item-wrap {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    padding-left: 1rem;
    margin-bottom: 1rem;
}

.pb-list .pb-list-item-wrap:nth-child(n+1) {
    margin-right: -4px;
}

@media only screen and (max-width: 1024px) {
    .tx-pb-social .pb-list-item-wrap {
        width: 33.3333%;
    }
}

@media only screen and (max-width: 600px) {
    .tx-pb-social .pb-list-item-wrap {
        width: 50%;
    }
}

@media only screen and (max-width: 480px) {
    .tx-pb-social .pb-list-item-wrap {
        width: 100%;
    }
}

.tx-pb-social .pb-list-item {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.tx-pb-social .pb-list-item .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.tx-pb-social .pb-list-item .icon {
    position: absolute;
    top: 30px;
    right: 20px;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    cursor: pointer;
    background-size: 40px 40px;
}

.tx-pb-social .pb-list-item .text-wrap {
    position: absolute;
    height: 25%;
    min-height: 120px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #fff;
    cursor: pointer;
}

.tx-pb-social .pb-list-item .text-wrap .text {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    color: #000;
    font-size: 16px;
    line-height: 1.6;
    overflow: hidden;
}

.tx-pb-social .pb-list-item .additional-info {
    box-sizing: border-box;
    position: absolute;
    color: #ffffff;
    width: 30px;
    height: 20px;
    bottom: 2px;
    text-align: right;
    text-decoration: none;
    font-size: 11px;
    font-weight: bold;
    text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
}

.tx-pb-social .pb-list-item .additional-info.info-1 {
    right: 65px;
}

.tx-pb-social .pb-list-item .additional-info.info-2 {
    right: 20px;
}

.tx-pb-social .pb-list-item .video-overlay {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    opacity: 0.9;
    cursor: pointer;
}

.tx-pb-social .pb-list-item[data-type="video"] .image {
    background-size: contain;
    background-repeat: no-repeat;
    background-color: black;
}

.tx-pb-social .pb-list-item[data-type="video"] .video-overlay {
    top: 95px;
    bottom: auto;
}

.tx-pb-social .pb-list-item-facebook .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/fb.jpg");
}

.tx-pb-social .pb-list-item-facebook .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook.png");
}

.tx-pb-social .pb-list-item-googleplus .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/gplus.jpg");
}

.tx-pb-social .pb-list-item-googleplus .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/google+.png");
}

.tx-pb-social .pb-list-item-instagram .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/instagram.png");
}

.tx-pb-social .pb-list-item-pinterest .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/pinterest.png");
}

.tx-pb-social .pb-list-item-tumblr .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/tumblr.jpg");
}

.tx-pb-social .pb-list-item-tumblr .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/tumblr.png");
}

.tx-pb-social .pb-list-item-twitter .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/twitter.jpg");
}

.tx-pb-social .pb-list-item-twitter .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/twitter.png");
}

.tx-pb-social .pb-list-item-imgur .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/imgur.png");
}

.tx-pb-social .pb-list-item-youtube .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/yt.jpg");
}

.tx-pb-social .pb-list-item-youtube .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/youtube.png");
}

.tx-pb-social .pb-list-item-vimeo .placeholder-image {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/Placeholder/vimeo.png");
}

.tx-pb-social .pb-list-item-vimeo .icon {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/vimeo.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-detail-wrap {
    position: relative;
    top: 190px;
    text-align: center;
    width: 100%;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details {
    opacity: 0;
    position: relative;
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0, 0.75);
    color: white;
    text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details.active {
    opacity: 1;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details span {
    position: relative;
    top: 10px;
    margin: 0 4px 0 5px;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details i {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 3px;
    background-size: 12px 12px;
    background-size: cover;
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__like {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/like.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__love {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/love.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__haha {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/haha.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__wow {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/wow.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__sad {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/sad.png");
}

.tx-pb-social .pb-list-item-facebook .fb-like-details .fb-like-detail__angry {
    background-image: url("/typo3conf/ext/pb_social/Resources/Public/Icons/facebook_reactions/angry.png");
}

.tx-pb-social .button {
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    height: 30px;
    width: 300px;
    padding-top: 15px;
    border: 1px solid black;
}

.tx-pb-social .button.loading {
    border: none;
    color: white;
    background-color: darkgray;
    cursor: progress;
}

.tx-pb-social .button.pb-disabled {
    visibility: hidden;
}

.tx-pb-social .button__wrap {
    float: left;
    width: 100%;
    text-align: center;
}

.tx-pb-social .pb-hide-initial {
    display: none;
}

.ucoi__wrapper {
    position: relative;
}

.ucoi__layer__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.9);
}

.ucoi__layer__container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 400px;
    padding: 0.5rem;
    border-radius: 3px;
    transform: translate(-50%, -50%);
    z-index: 11;
    color: white;
    background-color: transparent;
    text-align: center;
    font-size: 14px;
    line-height: 1.3;
    hyphens: none;
}

.ucoi__layer__icon {
    display: inline-block;
    width: 5rem;
    margin-bottom: 1em;
}

.ucoi__layer__title {
    margin-bottom: 0.75em;
    font-weight: 700;
}

.ucoi__layer__info {
    margin-bottom: 1.5em;
}

.ucoi__layer__agree {
    border: 1px solid white;
    background-color: white;
    color: black;
    padding: 0.5em 1em;
    font-size: 100%;
    cursor: pointer;
    transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
    vertical-align: middle;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;
}

.ucoi__layer__agree:hover {
    background-color: black;
    color: white;
    vertical-align: middle;
}

.ucoi__layer__disagree {
    display: inline-block;
    color: white !important;
    text-decoration: underline !important;
    margin-top: 1em;
}

.ucoi__imagebutton__button-parent {
    position: relative;
}

.ucoi__imagebutton__button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: #777;
}

.ucoi__imagebutton__button .svg {
    width: 100%;
    height: 100%;
}

.ucoi__imagebutton__button .svg__background {
    fill: transparent;
}

.ucoi__imagebutton__button .svg__image__background {
    fill: transparent;
    transition: fill ease-in-out 0.4s;
}

.ucoi__imagebutton__button .svg__image__mountains {
    fill: rgba(0, 0, 0, 0.3);
    transition: fill ease-in-out 0.4s;
}

.ucoi__imagebutton__button .svg__image__sun {
    fill: rgba(255, 255, 255, 0.5);
    transition: fill ease-in-out 1s;
}

.ucoi__imagebutton__button .svg__text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
}

.ucoi__imagebutton__button .svg:hover .svg__image__background {
    fill: #dbf7ff;
}

.ucoi__imagebutton__button .svg:hover .svg__image__mountains {
    fill: rgba(0, 0, 0, 0.8);
}

.ucoi__imagebutton__button .svg:hover .svg__image__sun {
    fill: #e5d100;
}

.ucoi__imagebutton__button .svg__image {
    transform: translateY(-29%);
}

#body .slick-container .slick-slider {
    margin-bottom: 0;
}

#body .slick-container .slick-slider .slick-prev {
    left: 30px;
    width: 30px;
}

#body .slick-container .slick-slider .slick-prev::before {
    content: url(../Icons/button-left.svg);
}

#body .slick-container .slick-slider .slick-next {
    right: 30px;
    width: 30px;
}

#body .slick-container .slick-slider .slick-next::before {
    content: url(../Icons/button-right.svg);
}

#body .slick-container .slick-slider .slick-dots {
    bottom: 10px;
    left: 20px;
    width: initial;
}

#body .slick-container .slick-slider .slick-dots li button::before {
    color: #FFF;
}

@media screen and (max-width: 480px) {
    #body .slick-container .slick-slider .slick-prev {
        width: 20px;
    }
    #body .slick-container .slick-slider .slick-next {
        width: 20px;
    }
}
