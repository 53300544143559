$actSelector: "html.header-overlay-active";
$inactSelector: "html.header-overlay-inactive";

@keyframes header-overlay-active {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes header-overlay-inactive {
    from {
        top: 0;
        opacity: 1;
    }

    99% {
        top: 0;
    }

    to {
        opacity: 0;
        top: -100%;
    }
}

.page-uid-1, .page-uid-7, .page-uid-8, .page-uid-10, .page-uid-11, .page-uid-12
.page-uid-14, .page-uid-15, .page-uid-16, .page-uid-18, .page-uid-19, .page-uid-20, .page-uid-22 {
    #header__logo {
        background-image: url("../gfx/Logo/logo_w.svg");
        
        @include mediaQuery($device_smartphone) {
            background-image: url("../gfx/Logo/logo_b.svg");
        }
    }
}

.page-uid-6, .page-uid-20, .page-uid-11, .page-uid-12 {
    #header__logo {
        background-image: url("../gfx/Logo/logo_b.svg");
    }
}
 
.page-uid-10, .page-uid-17 {
    #header__logo {
        background-image: url("../gfx/Logo/logo_bw.svg");
        
        @include mediaQuery($device_smartphone) {
            background-image: url("../gfx/Logo/logo_b.svg");
        }
    }
}


#header {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @include mediaQuery($device_larger_than_content_max) {
        left: 50%;
        right: auto;
        width: $content_max;
        margin-left: $content_max / -2;
    }

    &__logo {
        position: absolute;
        top: 60px;
        right: 64px;
        width: 130px;
        height: 130px;

        #{$actSelector} & {
            display: none;
        }

    }

    &__toggle {
        position: fixed;
        top: 24px;
        width: 41px;
        z-index: 102;

        @media screen and (max-width: 1820px) {
            margin-left: 64px;
        }

        &__button {
            position: absolute;
            width: 41px;
            height: 41px;
            //background-color: rgba(red, 0.5);
            left: 50%;
            top: 50px;
            transform: translate(-50%, -50%);

            .bars {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 41px;
                height: 3px;
                background-color: #000;
                transition: width 0.3s ease-in-out;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    width: 41px;
                    height: 3px;
                    background-color: #000;
                    transition: all 0.3s ease-in-out;
                }

                &:before {
                    top: -12px;
                }

                &:after {
                    top: 12px;
                }
            }

            // state of button when overlay is active - transforms to a X
            #{$actSelector} & {
                .bars {
                    width: 0;

                    &:before,
                    &:after {
                        right: -27px;
                        top: 0;
                        width: 55px;
                        background-color: #000;
                    }

                    &:before {
                        transform: rotate(-45deg);
                    }

                    &:after {
                        right: -27px;
                        top: 0;
                        width: 55px;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    &__overlay {
    

        z-index: 101;
        position: fixed;
        top: -100%;
        opacity: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;

        .overlaycontainer {
            height: 100%;
        }

        .navi-image{
            position: absolute;
            right: 9999px;
        }

        #{$actSelector} & {

            .whitediv {
                width: 100%;
                height: 200px;
                background-color:red;
            }

            .navi-image {
                background-image: url("../../../../../../fileadmin/Data/Images/Navi/navi-image.jpg");
                background-size: cover;
                position: absolute;
                width: 55%;
                height: 100%;
                right: 0;
                top: 0;

                @include mediaQuery($device_laptop){
                    width: 45%;
                }

                @include mediaQuery($device_smaller_than_laptop) {
                    position: absolute;
                    right: 9999px;
                }
            }
        }

        .links {
            a {
               text-decoration: none; 
            }
            
        }

        &__logo {
            position: absolute;
            top: 60px;
            right: 64px;
            width: 130px;
            height: 130px;
        
            background-image: url("../gfx/Logo/logo_w.svg");
    
            @include mediaQuery($device_smaller_than_laptop) {
                background-image: url("../gfx/Logo/logo_b.svg");
            }
        }

        &__socials {
            display: none;
        }

        #{$actSelector} & {
            animation-duration: 0.8s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-name: header-overlay-active;
            animation-fill-mode: forwards;
            top: 0;
        }

        #{$inactSelector} & {
            animation-duration: 0.8s;
            animation-iteration-count: 1;
            animation-timing-function: linear;
            animation-name: header-overlay-inactive;
            animation-fill-mode: forwards;
        }
    }
}

@include mediaQuery($device_smaller_than_desktop) {
    #header {

        &__toggle {
            margin-left: 32px;
        }
    }
}

@include mediaQuery($device_smaller_than_laptop) {
    #header {
        &__logo {
            right: 32px;
            top: 23px;
            width: 90px;
            height: 90px;
        }

        &__toggle {
            top: 0;

            &__button {
                top: 67px;
                transform: translate(-50%, -50%);
            }
        }

        &__overlay {
            &__logo {
                right: 32px;
                top: 23px;
                width: 90px;
                height: 90px;
            }
        }
    }
}

@include mediaQuery($device_smartphone) {
    #header {
        &__logo {
            right: 16px;
            top: 23px;
            width: 90px;
            height: 90px;
        }

        &__toggle {
            margin-left: 16px;
            height: auto;
        }

        &__overlay {
            &__logo {
                right: 16px;
                top: 23px;
                width: 90px;
                height: 90px;
            }

            &__socials {
                margin-left: 16px;
            }
        }
    }
}
