@import "../variables";
@import "../mixins";

.ce-images-slider-container {
    &__wrapper {
        position: relative;
        width: 100%;
    }

    &__items {
        display: table;
        width: 100%;
    }

    &__item {
        width: 100%;

        picture,
        img {
            display: block;
            width: 100%;
        }
    }

    .sliderfade {
      -webkit-animation-name: fade;
      -webkit-animation-duration: 5s;
      animation-name: fade;
      animation-duration: 5s;
    } 
    
    @-webkit-keyframes fade {
      0% {opacity: 0;} 
      10% {opacity: 1;}
      90% {opacity: 1;}
      100% {opacity: 0;}
    }
    
    @keyframes fade {
      0% {opacity: 0;} 
      10% {opacity: 1;}
      90% {opacity: 1;}
      100% {opacity: 0;}
    }

    .dotcontainer {
        position: absolute;
        left: 10px;
        bottom: 10px;

        .dot {
            height: 15px;
            width: 15px;
            margin: 0 5px;
            background-color: #fff;
            border-radius: 50%;
            border: 2px solid #fff;
            display: inline-block;
            transition: background-color 0.6s ease;

            @include mediaQuery($device_smartphone) {
                height: 10px;
                width: 10px;
                margin: 0 3px;  
            }
        }
    
        .active {
            background-color: rgba(0,0,0,0);
        }
    }



    .slider-btn {
        display: inline-block;
        color: $color-highlight;
        width: 23px;
        
        &--right {
            margin-left: 50px;
        }
    }

    &__buttons {
        text-align: right;
    }
}
