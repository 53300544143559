  /* Signika-regular - latin */
  @font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 400;
    src: url('#{$fonts-directory}signika-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Signika Regular'), local('Signika-Regular'),
         url('#{$fonts-directory}signika-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$fonts-directory}signika-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$fonts-directory}signika-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('#{$fonts-directory}signika-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$fonts-directory}signika-v9-latin-regular.svg#Signika') format('svg'); /* Legacy iOS */
  }
  
  /* Signika-700 - latin */
  @font-face {
    font-family: 'Signika';
    font-style: normal;
    font-weight: 700;
    src: url('#{$fonts-directory}signika-v9-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Signika Bold'), local('Signika-Bold'),
         url('#{$fonts-directory}signika-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$fonts-directory}signika-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$fonts-directory}signika-v9-latin-700.woff') format('woff'), /* Modern Browsers */
         url('#{$fonts-directory}signika-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$fonts-directory}signika-v9-latin-700.svg#Signika') format('svg'); /* Legacy iOS */
  }

  /* Lato-regular - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('#{$fonts-directory}lato-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
         url('#{$fonts-directory}lato-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$fonts-directory}lato-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$fonts-directory}lato-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('#{$fonts-directory}lato-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$fonts-directory}lato-v15-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  
  /* Lato-700 - latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('#{$fonts-directory}lato-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Lato Bold'), local('Lato-Bold'),
         url('#{$fonts-directory}lato-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$fonts-directory}lato-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$fonts-directory}lato-v15-latin-700.woff') format('woff'), /* Modern Browsers */
         url('#{$fonts-directory}lato-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$fonts-directory}lato-v15-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
  }
