@mixin input-tags {
    input[type=text],
	input[type=search],
	//input[type=password],
    //input[type=date],
    //input[type=time],
	//input[type=email],
	input[type=number],
	textarea,
	select {
        @content;
    }
}

.standard-form {
    // all input fields
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    @include input-tags {
        display: block;
        padding: 0.5rem;
        outline: 0 none;
        width: 100%;
        border: none;
        border: 1px solid $color-grey;
        border-radius: 3px;
        font-size: 18px;
        font-weight: $font-weight-regular;
        color: black;

        // disable some default styles:
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.f3-form-error {
            border-color: $color-error;
        }
    }

    input[type=number] {
        text-align: right;
    }

    // textarea
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    textarea {
        height: 12em;
        resize: vertical;
        line-height: 1.3;
    }

    // select
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    // remove arrow in IE
    select::-ms-expand {
        display: none;
    }

    // @todo Create icon for select input
    select {
        background-image: url("../Images/Icons/select.svg");
        background-position: right 20px center;
        background-repeat: no-repeat;
        background-size: 16px 10px;
        padding-right: 50px;
        //text-transform: uppercase;
    }

    // checkbox replacement
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    .checkbox-replacement {
        display: inline-block;
        vertical-align: unset;

        span {
            display: block;
            width: 15px;
            height: 15px;
            border: 1px solid #cdcdcd;

            &.checked {
                border-color: $color-highlight;
                background-color: $color-highlight;
            }
        }
    }

    // placeholder
    // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

    @include placeholder(
        (
            color: #969696,
            font-size: 18px,
            font-weight: $font-weight-regular
        )
    );
}

.form-field {

    &__info {
        font-size: 80%;
    }
}