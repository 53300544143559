$fonts-directory: "../fonts/";
@import "FontsLocal";

html {
    font-size: unquote($root-pixel + "px");
    line-height: 1.6;
    word-break: normal;
    word-wrap: break-word;
    hyphens: auto;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: auto;
}

body {
    @include bodytext-font-and-size();
    //font-feature-settings: "dlig" 0, "numr" 0, "dnom" 0, "tnum" 0, "case" 0, "zero", "frac", "sups" 0, "subs" 0, "cpsp" 0, "salt" 0, "ss01" 0, "ss02" 0, "ss03" 0, "cv01" 0, "cv02", "cv03", "cv04",
    //    "cv05", "cv06" 0, "cv07" 0, "cv08", "cv09" 0, "cv10" 0, "cv11" 0, "calt", "liga", "kern";
}

.ui-widget {
    font-family: #{$font-family} !important;
    font-size: 14px !important;
}

p {
    //margin: 1rem 0;
}

strong,
b {
    font-weight: $font-weight-bold;
}

p code {
    //display: inline-block;
    padding: 0 0.1em;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

pre,
code {
    font-family: monospace;
    font-weight: normal;
    font-feature-settings: "dlig" 0, "numr" 0, "dnom" 0, "tnum" 0, "case" 0, "zero" 0, "frac" 0, "sups" 0, "subs" 0, "cpsp" 0, "salt" 0, "ss01" 0, "ss02" 0, "ss03" 0, "cv01" 0, "cv02" 0, "cv03" 0,
        "cv04" 0, "cv05" 0, "cv06" 0, "cv07" 0, "cv08", "cv09" 0, "cv10" 0, "cv11" 0, "calt" 0, "liga" 0, "kern" 0;

    strong,
    b {
        font-weight: 700;
    }
}

pre {
    code.language-js,
    code.language-javascript,
    code.language-bash,
    code.language-php,
    code.language-css,
    code.json,
    code.language-ts,
    code.language-yaml,
    code.language-html,
    code.apache {
        position: relative;
        padding-top: 1.5rem;

        &:before {
            font-size: 60%;
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
        }
    }

    code.language-js:before,
    code.language-javascript:before {
        content: "JavaScript:";
    }
    code.language-bash:before {
        content: "BASH:";
    }
    code.language-php:before {
        content: "PHP:";
    }
    code.language-css:before {
        content: "CSS:";
    }
    code.json:before {
        content: "JSON:";
    }
    code.language-ts:before {
        content: "TS:";
    }
    code.language-yaml:before {
        content: "YAML:";
    }
    code.language-html:before {
        content: "HTML:";
    }
    code.apache:before {
        content: "Apache:";
    }
}



i,
em {
    font-style: italic;
}

// DEFAUlT STYLES
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    //margin: 1rem 0;

    .icon-before,
    .icon-after {
        vertical-align: middle;
    }

    a {
        display: block;
    }
}

h1,
.header--large {
    @include header-font-and-size();
}

h2,
.header--middle {
    @include header-middle-font-and-size();
}

h3,
.header--small {
    @include header-small-font-and-size();
}

.merged-header-and-subheader {
    .header,
    .subheader {
        display: block;
    }

    .header {
        @include header-font-and-size();
    }

    .subheader {
        @include header-extrasmall-font-and-size();
        color: $color-highlight;
        margin-top: 10px;

        @include mediaQuery($device_smaller_than_laptop){
            margin-top: 5px;
        }
    }
}

// Font-definitions

h1,
h2,
h3,
h4,
h5,
h6,
h1.font-regular,
h2.font-regular,
h3.font-regular,
h4.font-regular,
h5.font-regular,
h6.font-regular,
.font-regular {
    font-weight: $font-weight-bold;
}

h1.font-bold,
h2.font-bold,
h3.font-bold,
h4.font-bold,
h5.font-bold,
h6.font-bold,
.font-bold {
    font-weight: $font-weight-bold;
}

h1.font-header-regular,
h2.font-header-regular,
h3.font-header-regular,
h4.font-header-regular,
h5.font-header-regular,
h6.font-header-regular,
.font-header-regular {
    font-family: $font-family-signika;
    font-weight: $font-weight-regular;
}

h1.font-header-bold,
h2.font-header-bold,
h3.font-header-bold,
h4.font-header-bold,
h5.font-header-bold,
h6.font-header-bold,
.font-header-bold {
    font-family: $font-family-signika;
    font-weight: $font-weight-bold;
}

h1.font-headline-bold,
h2.font-headline-bold,
h3.font-headline-bold,
h4.font-headline-bold,
h5.font-headline-bold,
h6.font-headline-bold,
.font-headline-bold {
    @include header-font-and-size();
}

h1.font-small-headline-bold,
h2.font-small-headline-bold,
h3.font-small-headline-bold,
h4.font-small-headline-bold,
h5.font-small-headline-bold,
h6.font-small-headline-bold,
.font-small-headline-bold {
    @include header-small-font-and-size();
}

h1.font-extrasmall-headline-bold,
h2.font-extrasmall-headline-bold,
h3.font-extrasmall-headline-bold,
h4.font-extrasmall-headline-bold,
h5.font-extrasmall-headline-bold,
h6.font-extrasmall-headline-bold,
.font-extrasmall-headline-bold {
    @include header-extrasmall-font-and-size();
}

h1.font-table-headline-bold,
h2.font-table-headline-bold,
h3.font-table-headline-bold,
h4.font-table-headline-bold,
h5.font-table-headline-bold,
h6.font-table-headline-bold,
.font-table-headline-bold {
    @include header-table-font-and-size();
}

h1.font-small-underlined-headline-bold,
h2.font-small-underlined-headline-bold,
h3.font-small-underlined-headline-bold,
h4.font-small-underlined-headline-bold,
h5.font-small-underlined-headline-bold,
h6.font-small-underlined-headline-bold,
.font-small-underlined-headline-bold {
    border-bottom: 1px solid black;
    @include header-small-font-and-size();
}

h1.font-extrasmall-underlined-headline-bold,
h2.font-extrasmall-underlined-headline-bold,
h3.font-extrasmall-underlined-headline-bold,
h4.font-extrasmall-underlined-headline-bold,
h5.font-extrasmall-underlined-headline-bold,
h6.font-extrasmall-underlined-headline-bold,
.font-extrasmall-underlined-headline-bold {
    border-bottom: 1px solid black;
    @include header-extrasmall-font-and-size();
}

.price-balloon {
    position: absolute;
    top: -110px;
    right: 10px;
    text-align: center;
    background-color: #7f8d32;
    color: white;
    padding: 10px 25px;
    @include header-small-font-and-size();

    @include mediaQuery($device_smaller_than_laptop){
        top: -86px;
        font-size: 20px;
    }
}