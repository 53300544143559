
// social media icons below footer
nav.socials {
    margin-top: 1rem !important;

    ul,li,a {
        display: inline-block;
    }

    li {
        margin-left: 1.5rem;

        &:first-child {
            margin-left: 0;
        }
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: text-bottom;

        path {
            fill: white;
            transition: fill ease-in-out 0.3s;
        }
    }

    #{$no-touch-selector} & a:hover {
        svg {
            path {
                fill: $color-highlight;
            }
        }
    }

    #{$no-touch-selector} & a.twitter:hover {
        svg {
            path {
                fill: $color-twitter;
            }
        }
    }

    #{$no-touch-selector} & a.instagram:hover {
        svg {
            path {
                fill: $color-instagram;
            }
        }
    }

    #{$no-touch-selector} & a.tumblr:hover {
        svg {
            path {
                fill: $color-tumblr;
            }
        }
    }
}