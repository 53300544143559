@import "../variables";
@import "../mixins";

.ce-images-slider {
    &__wrapper {
        position: relative;
        width: 100%;
        height: 400px;
        overflow: hidden;
        overflow-x: auto;

        @include mediaQuery($device_smartphone) {
            height: 250px;
        }
    }

    &__items {
        display: table;
        height: inherit;
    }

    &__item {
        display: table-cell;
        height: inherit;

        picture {
            margin-left: 40px;

            @include mediaQuery($device_smartphone) {
                margin-left: 20px;
            }
        }

        picture,
        img {
            display: block;
            width: auto;
            max-width: unset;
            height: inherit;
        }

        &:first-child {
            picture {
                margin-left: 0;
            }
        }
    }

    .slider-btn {
        display: inline-block;
        color: $color-highlight;
        width: 23px;
        
        &--right {
            margin-left: 50px;
        }
    }

    &__buttons {
        text-align: right;
    }
}
