@mixin rounded($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    border-top-left-radius: $topleft;

    @include background-clip(padding-box);
}

@mixin box-shadow($rules) {
    -webkit-box-shadow: unquote($rules);
    -moz-box-shadow: unquote($rules);
    -ms-box-shadow: unquote($rules);
    box-shadow: unquote($rules);
}

@mixin text-shadow($rules) {
    -webkit-text-shadow: unquote($rules);
    -moz-text-shadow: unquote($rules);
    -ms-text-shadow: unquote($rules);
    text-shadow: unquote($rules);
}

@mixin font-size-ruleset($font-size: 15px, $line-height: 180%) {
    font-size: $font-size;
    line-height: $line-height;
}

@mixin placeholder($rules) {
    & input:-moz-placeholder {
        @each $parameter, $value in $rules {
            #{$parameter}: $value;
        }
    }

    // ie
    & :-ms-input-placeholder {
        @each $parameter, $value in $rules {
            #{$parameter}: $value;
        }
    }

    // firefox 19+
    & ::-moz-placeholder {
        @each $parameter, $value in $rules {
            #{$parameter}: $value;
        }
    }

    & ::-webkit-input-placeholder {
        @each $parameter, $value in $rules {
            #{$parameter}: $value;
        }
    }
}

@mixin background-clip($argument: padding-box) {
    -webkit-background-clip: $argument;
    -moz-background-clip: $argument;
    background-clip: $argument;
}

@mixin transform($trans...) {
    @if length($trans) < 1 {
        $trans: rotate(7deg);
    }
    -webkit-transform: $trans;
    -moz-transform: $trans;
    -ms-transform: $trans;
    transform: $trans;
}

@mixin transition($trans...) {
    @if length($trans) < 1 {
        $trans: all 0.5s ease-out;
    }
    -webkit-transition: $trans;
    -moz-transition: $trans;
    -ms-transition: $trans;
    transition: $trans;
}

@mixin opacity($opacity: 0.5) {
    -webkit-opacity: $opacity;
    -khtml-opacity: $opacity;
    -moz-opacity: $opacity;
    opacity: $opacity;
    $opperc: $opacity * 100;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opperc})";
    filter: "alpha(opacity=#{$opperc})";
}

@mixin color-rgba($color: rgb(0, 0, 0), $alpha: 0.5) {
    color: $color;
    color: rgba(red($color), green($color), blue($color), $alpha);
}

@mixin background-color-rgba($color: rgb(0, 0, 0), $alpha: 0.5) {
    background-color: $color;
    background-color: rgba(red($color), green($color), blue($color), $alpha);
}

@mixin border-color-rgba($color: rgb(0, 0, 0), $alpha: 0.5) {
    border-color: $color;
    border-color: rgba(red($color), green($color), blue($color), $alpha);
}

@mixin greyscale() {
    -webkit-filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    /* Firefox 3.5+ */
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

@mixin greyscale_off() {
    -webkit-filter: grayscale(0%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    filter: grayscale(0%);
}

// Mixin to prefix several properties at once
// @author Hugo Giraudel
// @param {Map} $declarations - Declarations to prefix
// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: webkit moz o) {
    @each $property, $value in $declarations {
        @each $prefix in $prefixes {
            #{'-' + $prefix + '-' + $property}: $value;
        }

        // Output standard non-prefixed declaration
        #{$property}: $value;
    }
}

// Mixin to generate a media query
@mixin mediaQuery($device) {
    @media screen and #{$device} {
        @content;
    }
}
