// error messages
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

.box-error {
    .form-error-message {
        position: relative;
        color: white;
        background-color: $color-error;
        font-size: 12px;
        line-height: 1.2;
        letter-spacing: 0.04em;
        font-weight: $font-weight-regular;
        padding: 5px;
        margin-top: 10px;
        display: inline-block;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
        width: initial;

        &:before {
            border: 5px solid transparent;
            border-bottom-color: $color-error;
            bottom: 100%;
            left: 5px;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
    }
}



.form-error-message {
    width: 100%;
    border-top: 3px solid $color-error;
    position: relative;
    color: $color-error;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0.04em;
    font-weight: $font-weight-regular;
    padding: 5px;
    margin-top: -1px;
    display: inline-block;
}