// button
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

// fix the button height of firefox
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
    //margin-top:-2px;
    //margin-bottom: -2px;
}

.form-button {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.75em;
    cursor: pointer;
    outline: 0 none;
    font-size: 14px;
    line-height: 1.2;
    font-weight: $font-weight-bold;
    vertical-align: middle;
    text-decoration: none;
    color: white;
    background-color: #cdcdcd;
    border: 1px solid #cdcdcd;
    @include transition(all 0.3s ease);

    &--grey {
        background-color: mix(white, $color-grey, 70%);
        border-color: mix(white, $color-grey, 70%);
    }

    &--dark-grey {
        background-color: $color-grey;
        border-color: $color-grey;
    }

    &--highlight {
        background-color: $color-highlight;
        border-color: $color-highlight;
    }

    &--white {
        background-color: white;
        border-color: #cdcdcd;
    }

    margin-left: 1rem;
    &:first-child {
        margin-left: 0;
    }

    // disable webkit iOS-Style:
    -webkit-appearance: none;
    border-radius: 3px;


    &--full-width {
        width: 100%;
    }
}

#{$no-touch-selector} .form-button:hover {
    text-decoration: none;
    color: black;
    background-color: white;
    border-color: black;
}
