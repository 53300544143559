nav.meta {
    padding-top: 3rem;
    padding-left: 64px;

    @include mediaQuery($device_smaller_than_laptop) {
        padding-left: 32px;
        padding-top: 3vw;
    }

    @include mediaQuery($device_smartphone) {
        padding-left: 16px;
    }

    ul, li, a {
        display: inline-block;
    }

    li {
        &:before {
            color: white;
            content: "|";
            margin: 0 0.5rem;
        }

        &:first-child {
            margin-left: 0;

            &:before {
                display: none;
            }
        }
    }

    a {
        text-decoration: none;
        color: #000;
        font-size: 18px;
        font-weight: $font-weight-regular;

        #{$no-touch-selector} &:hover,
        &.active {
            color: $color-highlight;
            text-decoration: underline;
        }
    }
}