nav.footer {
    display: inline-block;
    margin-left: 1rem;

    ul, li, a {
        display: inline-block;
    }

    li {
        &:before {
            color: white;
            content: "|";
            margin: 0 0.5rem;
        }

        &:first-child {
            margin-left: 0;

            &:before {
                display: none;
            }
        }
    }

    a {
        text-decoration: none;
        color: white;
        font-size: 18px;
        font-weight: $font-weight-regular;

        #{$no-touch-selector} &:hover,
        &.active {
            color: white;
            text-decoration: underline;
        }
    }
}