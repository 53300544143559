nav.main {
    padding-top: 250px;
    padding-left: 64px;

    @include mediaQuery($device_smaller_than_laptop) {
        padding-left: 32px;
        padding-top: 140px;
    }

    @include mediaQuery($device_smartphone) {
        padding-left: 16px;
    }

    li {
        margin-top: 1rem;

        &:first-child {
            margin-top: 0;
        }
    }

    a {
        $minFontSize: 26;
        $maxFontSize: 55;

        $minIconWidth: 45;
        $maxIconWidth: 62;

        display: block;
        position: relative;
        overflow: hidden;
        text-decoration: none;
        color: #000;
        line-height: 1.7;
        font-size: 30px;
        font-family: $font-family-signika;
        font-weight: $font-weight-bold;

        span.icon {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-100%, -50%);
            transition: transform ease-in-out 0.3s, opacity linear 0.8s;
            opacity: 0;
            width: 100vw / 1280 * $maxIconWidth;

            svg {
                width: 100%;
                height: auto;
            }
        }

        span.label {
            margin-left: 0;
            transition: margin-left ease-in-out 0.3s;
        }

        #{$no-touch-selector} &:hover,
        &.active {
            color: $color-highlight;

            span.icon {
                transform: translate(0, -50%);
                opacity: 1
            }

            span.label {
                margin-left: 100vw / 1280 * $maxIconWidth * 1.1;
            }
        }

        @include mediaQuery("(max-width: 600px)") {
            font-size: $minFontSize + px;
        }

        @include mediaQuery("(max-width: 1000px)") {
            span.icon {
                width: $minIconWidth + px;
            }

            #{$no-touch-selector} &:hover,
            &.active {
                span.label {
                    margin-left: $minIconWidth * 1.1 + px;
                }
            }
        }

        @include mediaQuery($device_desktop) {
            font-size: 30px;

            span.icon {
                width: $maxIconWidth + px;
            }

            #{$no-touch-selector} &:hover,
            &.active {
                span.label {
                    margin-left: $maxIconWidth * 1.1 + px;
                }
            }
        }
    }
}