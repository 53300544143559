@import "../variables";
@import "../mixins";

.page-uid-10 {
    .contact-form {
        .initial-radio {
            label {
                @include header-small-font-and-size();
            }
        }
    }
}

.contact-form {

    #initial-selection {
        .form-field {
            margin-top: 30px;

            &--radio {
                display: block;
                margin-right: initial;
            }
        }
    }

    label {
        font-family: $font-family-signika;
        font-weight: $font-weight-bold;
        @include header-extrasmall-font-and-size();
        letter-spacing: $letter-spacing; 

        &.font-bigger {
            @include header-small-font-and-size();
        }
    }
 
    .form-field {

        &--textfield {
            margin-bottom: 60px;

            input {
                width: 100%;
                height: 30px;
            }
        }

        &--select {
            margin-bottom: 60px;
        }

        &--section {
 
            &--space {
                margin: 60px 0;
            }
        }

        &--radio {
            display: inline-block;
            margin-right: 60px;
            margin-top: 20px;

            &--input {
                margin-right: 10px;
            }
        }
    }

    .lower-section {

        label {
            margin-bottom: 20px;
        }

        #section-coupon, #section-coupon-reciever {

            width: 33.3%; 

            .form-field {
            
                &--textfield {
                    margin: 20px 0 60px;
                }
            }
        }

        #section-amountOfAdults, #section-amountOfKids {
            .form-field--select {
                margin-bottom: 20px;
            }
        }

        #adultfields, #kidfields {
            margin-bottom: 30px;
        }

        .form-field {

            &__label {
                font-family: $font-family;
                font-weight: $font-weight-regular;
                font-size: 16px;
                letter-spacing: initial;
            }

            &--checkbox {
                margin-top: 10px;

                &:last-child {
                    width: 50%;

                    @include mediaQuery($device_smaller-than_desktop) {
                        width: 100%;
                    }
                }
            }

            &--radio {

                &--label {

                    label {
                        font-family: $font-family;
                        font-weight: $font-weight-regular;
                        font-size: 16px;
                        letter-spacing: initial;
                    }
                }
            }
        }
    }

    input {
        border: 0;
        border-bottom: 1px solid black;
        padding: 0 10px;
        @include header-extrasmall-font-and-size();

        &::placeholder {
            @include header-extrasmall-font-and-size();
            color: #707070;
        }
    }

    input[type="radio"] {
        transform: translateY(-3px);
    }


    #green-section {
        width: 100%;
        background-color: #7F8D32;
        padding: 40px 0;

        .form-field {

            &--radio-set {
                margin: 40px 0;
            }

            &--radio {
                display: initial;
                margin-right: initial;
            }
        }
    }

    #pricefield {
        margin-top: 75px;

        input {
            border-bottom: initial;
            color: #7F8D32;
        }

        p {
            @include header-extrasmall-font-and-size();
            font-family: $font-family-signika;
            font-weight: $font-weight-bold;
            letter-spacing: $letter-spacing;

            .price {
                color: #7F8D32;
            }
        }
        
    }

    #section-amountOfAdults, #sectionAmountOfKids {
        margin-top: 50px;
    }

    #property-amountOfAdults {
        margin-bottom: 10px;
    }

    #property-amountOfAdults, #property-amountOfKids {
        width: 100%;
        height: 30px;
        font-size: 14px;
        font-family: $font-family-signika;
        font-weight: $font-weight-bold;
        letter-spacing: $letter-spacing;
    }

    #property-message {
        width: 100%;
        height: 200px;
    }

    textarea {
        font-family: $font-family;
        font-weight: $font-weight-regular;
        font-size: 16px;
        letter-spacing: initial;
        color: black;
        padding: 10px;
        margin-top: 20px;

        &::placeholder {
            font-family: $font-family;
            font-weight: $font-weight-regular;
            font-size: 16px;
            letter-spacing: initial;
            color: #707070;
        }
    }

    #form-button-bbg {
        width: 100%;
        height: 100px;
        font-family: $font-family-signika;
        font-weight: $font-weight-bold;
        background-color: #7F8D32;
        @include header-small-font-and-size();
        margin: 0;

            &:hover {
                background-color: rgba(127,141,50,0.7);
                border: initial;
            }
    }

    .last-section {
        width: 75%;

        .last-text {
            margin-top: 20px;

            p {
                font-size: 16px;
            }
        }
    }
 
    .grid-booking {
        margin-left: 0;
    }

    .header-picture {
        padding-left: 0;
    }

    #adultfields {
        width: 100%;
    }

    #kidfields {
        width: 100%;
    }

    .success-wrapper {
        width: 100%;
        height: 600px;

        .success {
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

@include mediaQuery($device_smaller_than_desktop) {

    
    
    .contact-form {
        .lower-section {
            #section-coupon, #section-coupon-reciever {
                width: 50%;
            }
        }

        .last-section {
            width: 100%;
        }
    }
}


@include mediaQuery($device_smartphone) {

    .contact-form {

        .lower-section {
            #section-coupon, #section-coupon-reciever {
                width: 100%;
            }
        }

        #green-section {
            label {
                font-size: 3.6vw;
            }
        }
        
        .form-field {

            &--radio {
                display: block !important;
            }
        }

        #lowerform {
            .form-field {

                &--radio {
    
                    &--label {
    
                        position: absolute;
                        transform: translateY(-2px);
                    }
                }
            }
        }
    }
}