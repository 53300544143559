@mixin size ($prefix:"") {
    .#{$prefix}size-8 {             font-size:   $size-8;      line-height: 1.5; }
    .#{$prefix}size-16 {            font-size:   $size-16;     line-height: 1.5; }
    .#{$prefix}size-24 {            font-size:   $size-24;     line-height: 1.5; }
    .#{$prefix}size-32 {            font-size:   $size-32;     line-height: 1.5; }
    .#{$prefix}size-40 {            font-size:   $size-40;     line-height: 1.4; }
    .#{$prefix}size-48 {            font-size:   $size-48;     line-height: 1.4; }
    .#{$prefix}size-56 {            font-size:   $size-56;     line-height: 1.4; }
    .#{$prefix}size-64 {            font-size:   $size-64;     line-height: 1.4; }
    .#{$prefix}size-72 {            font-size:   $size-72;     line-height: 1.4; }
    .#{$prefix}size-80 {            font-size:   $size-80;     line-height: 1.3; }
    .#{$prefix}size-88 {            font-size:   $size-88;     line-height: 1.3; }
    .#{$prefix}size-96 {            font-size:   $size-96;     line-height: 1.2; }
    .#{$prefix}size-104 {           font-size:   $size-104;    line-height: 1.2; }
    .#{$prefix}size-112 {           font-size:   $size-112;    line-height: 1.2; }
    .#{$prefix}size-120 {           font-size:   $size-120;    line-height: 1.2; }
    .#{$prefix}size-128 {           font-size:   $size-128;    line-height: 1.1; }
    .#{$prefix}size-136 {           font-size:   $size-136;    line-height: 1.1; }
    .#{$prefix}size-144 {           font-size:   $size-144;    line-height: 1.1; }
    .#{$prefix}size-152 {           font-size:   $size-152;    line-height: 1.1; }
    .#{$prefix}size-160 {           font-size:   $size-160;    line-height: 1.1; }

    //.#{$prefix}size-text-31 {       font-size:   $size-text-31; line-height: 1.2; }
    //.#{$prefix}size-text-41 {       font-size:   $size-text-41; line-height: 1.2; }
    //.#{$prefix}size-text-3 {        font-size:   $size-text-3; line-height: 1.5; }
    //.#{$prefix}size-text-4 {        font-size:   $size-text-4; line-height: 1.5; }
    //.#{$prefix}size-text-5 {        font-size:   $size-text-5; line-height: 1.5; }
    //.#{$prefix}size-text-6 {        font-size:   $size-text-6; line-height: 1.5; }
    //.#{$prefix}size-text-7 {        font-size:   $size-text-7; line-height: 1.5; }

    .#{$prefix}line-height-8 {      line-height: $size-8;   }
    .#{$prefix}line-height-16 {     line-height: $size-16;  }
    .#{$prefix}line-height-24 {     line-height: $size-24;  }
    .#{$prefix}line-height-32 {     line-height: $size-32;  }
    .#{$prefix}line-height-40 {     line-height: $size-40;  }
    .#{$prefix}line-height-48 {     line-height: $size-48;  }
    .#{$prefix}line-height-56 {     line-height: $size-56;  }
    .#{$prefix}line-height-64 {     line-height: $size-64;  }
    .#{$prefix}line-height-72 {     line-height: $size-72;  }
    .#{$prefix}line-height-80 {     line-height: $size-80;  }
    .#{$prefix}line-height-88 {     line-height: $size-88;  }
    .#{$prefix}line-height-96 {     line-height: $size-96;  }
    .#{$prefix}line-height-104 {    line-height: $size-104; }
    .#{$prefix}line-height-112 {    line-height: $size-112; }
    .#{$prefix}line-height-120 {    line-height: $size-120; }
    .#{$prefix}line-height-128 {    line-height: $size-128; }
    .#{$prefix}line-height-136 {    line-height: $size-136; }
    .#{$prefix}line-height-144 {    line-height: $size-144; }
    .#{$prefix}line-height-152 {    line-height: $size-152; }
    .#{$prefix}line-height-160 {    line-height: $size-160; }

    //.#{$prefix}line-height-text-31 { line-height: $size-text-31; }
    //.#{$prefix}line-height-text-41 { line-height: $size-text-41; }
    //.#{$prefix}line-height-text-3 { line-height: $size-text-3; }
    //.#{$prefix}line-height-text-4 { line-height: $size-text-4; }
    //.#{$prefix}line-height-text-5 { line-height: $size-text-5; }
    //.#{$prefix}line-height-text-6 { line-height: $size-text-6; }
    //.#{$prefix}line-height-text-7 { line-height: $size-text-7; }

    .#{$prefix}icon-size-8 {        width:   $size-8 !important;   }
    .#{$prefix}icon-size-16 {       width:   $size-16 !important;  }
    .#{$prefix}icon-size-24 {       width:   $size-24 !important;  }
    .#{$prefix}icon-size-32 {       width:   $size-32 !important;  }
    .#{$prefix}icon-size-40 {       width:   $size-40 !important;  }
    .#{$prefix}icon-size-48 {       width:   $size-48 !important;  }
    .#{$prefix}icon-size-56 {       width:   $size-56 !important;  }
    .#{$prefix}icon-size-64 {       width:   $size-64 !important;  }
    .#{$prefix}icon-size-72 {       width:   $size-72 !important;  }
    .#{$prefix}icon-size-80 {       width:   $size-80 !important;  }
    .#{$prefix}icon-size-88 {       width:   $size-88 !important;  }
    .#{$prefix}icon-size-96 {       width:   $size-96 !important;  }
    .#{$prefix}icon-size-104 {      width:   $size-104 !important; }
    .#{$prefix}icon-size-112 {      width:   $size-112 !important; }
    .#{$prefix}icon-size-120 {      width:   $size-120 !important; }
    .#{$prefix}icon-size-128 {      width:   $size-128 !important; }
    .#{$prefix}icon-size-136 {      width:   $size-136 !important; }
    .#{$prefix}icon-size-144 {      width:   $size-144 !important; }
    .#{$prefix}icon-size-152 {      width:   $size-152 !important; }
    .#{$prefix}icon-size-160 {      width:   $size-160 !important; }

    //.#{$prefix}icon-size-text-31 {   width:   $size-text-31; }
    //.#{$prefix}icon-size-text-41 {   width:   $size-text-41; }
    //.#{$prefix}icon-size-text-3 {   width:   $size-text-3; }
    //.#{$prefix}icon-size-text-4 {   width:   $size-text-4; }
    //.#{$prefix}icon-size-text-5 {   width:   $size-text-5; }
    //.#{$prefix}icon-size-text-6 {   width:   $size-text-6; }
    //.#{$prefix}icon-size-text-7 {   width:   $size-text-7; }
}

// Default styles
.header-icon {
	display: inline;
    max-width: 224 / $root-pixel + rem;
}

@include size ();

@include mediaQuery($device_desktop) {
    @include size ("desktop--");
}

@include mediaQuery($device_laptop) {
    @include size ("laptop--");
}

@include mediaQuery($device_tablet) {
    @include size ("tablet--");
}

@include mediaQuery($device_smartphone) {
    @include size ("smartphone--");
}



