@import "../variables";
@import "../mixins";

.ce-phone-email {
    &__phone,
    &__email {
        display: block;
        padding: 2rem 1rem;
        text-align: center;
        text-decoration: none;
        transition: all ease-in-out 0.3s;

        i {
            $min: 26;
            $max: 55;
            font-size: 100vw / 1280 * $max;

            @include mediaQuery("(max-width: 600px)") {
                font-size: $min + px;
            }

            @include mediaQuery($device_desktop) {
                font-size: $max + px;
            }
        }

        .label {
            display: block;
            margin-top: 1rem;
            @include header-middle-font-and-size();
        }
    }

    &__phone {
        background-color: $color-grey;
        color: white;

        #{$no-touch-selector} &:hover {
            text-decoration: none;
            background-color: mix($color-grey, white, 80%);
            color: mix(white, $color-grey, 80%);
        }
    }

    &__email {
        background-color: $color-grey-light;
        color: $color-grey;

        #{$no-touch-selector} &:hover {
            text-decoration: none;
            background-color: mix($color-grey-light, $color-grey, 90%);
            color: mix($color-grey, $color-grey-light, 80%);
        }
    }
}
