@mixin color-builder($color, $color-hover) {
    & {
        $red: red($color);
        $green: green($color);
        $blue: blue($color);

        & {
            color: rgb($red, $green, $blue);
        }
        &-90 {
            color: rgba($red, $green, $blue, 0.9);
        }
        &-80 {
            color: rgba($red, $green, $blue, 0.8);
        }
        &-70 {
            color: rgba($red, $green, $blue, 0.7);
        }
        &-60 {
            color: rgba($red, $green, $blue, 0.6);
        }
        &-50 {
            color: rgba($red, $green, $blue, 0.5);
        }
        &-40 {
            color: rgba($red, $green, $blue, 0.4);
        }
        &-30 {
            color: rgba($red, $green, $blue, 0.3);
        }
        &-20 {
            color: rgba($red, $green, $blue, 0.2);
        }
        &-10 {
            color: rgba($red, $green, $blue, 0.1);
        }
        &-5 {
            color: rgba($red, $green, $blue, 0.05);
        }

        a {
            color: inherit;

            html.no-touch &:hover {
                color: $color-hover;
            }
        }
    }
}

@mixin background-color-builder($color) {
    $red: red($color);
    $green: green($color);
    $blue: blue($color);

    & {
        background-color: rgb($red, $green, $blue);
    }
    &-95 {
        background-color: rgba($red, $green, $blue, 0.9);
    }
    //&-90 {
    //	background-color: rgba($red, $green, $blue, .9);
    //}
    &-80 {
        background-color: rgba($red, $green, $blue, 0.8);
    }
    //&-70 {
    //	background-color: rgba($red, $green, $blue, .7);
    //}
    &-60 {
        background-color: rgba($red, $green, $blue, 0.6);
    }
    &-50 {
        background-color: rgba($red, $green, $blue, 0.5);
    }
    &-40 {
        background-color: rgba($red, $green, $blue, 0.4);
    }
    //&-30 {
    //	background-color: rgba($red, $green, $blue, .3);
    //}
    &-20 {
        background-color: rgba($red, $green, $blue, 0.2);
    }
    &-10 {
        background-color: rgba($red, $green, $blue, 0.1);
    }
    &-5 {
        background-color: rgba($red, $green, $blue, 0.05);
    }
}

// Colors
// = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

html {
    color: $default-font-color;
    background-color: $default-background-color;
}

a {
    color: $default-link-color;

    html.no-touchevents &:hover {
        color: $default-link-hover-color;
    }
}

.background-color-green {
    a {
        html.no-touchevents &:hover {
            color: rgba(255,255,255,0.5);
        }
    }
}



// loop all font-colors
@each $fontColor in $fontColors {
    $css-class: nth($fontColor, 1);
    $color: nth($fontColor, 2);
    $color-hover: nth($fontColor, 3);

    .#{$css-class} {
        @include color-builder($color, $color-hover);
    }
}

// loop all background-colors
$backgroundColorSelector: ();
@each $backgroundColor in $backgroundColors {
    $css-class: nth($backgroundColor, 1);
    $color: nth($backgroundColor, 2);

    .#{$css-class} {
        @include background-color-builder($color);
    }

    $backgroundColorSelector: append(
        $backgroundColorSelector,
        unquote("span.#{$css-class}"),
        "comma"
    );
}

#{$backgroundColorSelector} {
    display: inline-block;
    padding: 0.25em 0.5em;
}
