@mixin text-columne($prefix: "") {
    .#{$prefix}text-columne-count-1 {
        column-count: 1;
    }

    .#{$prefix}text-columne-count-2 {
        column-count: 2;
    }

    .#{$prefix}text-columne-count-3 {
        column-count: 3;
    }

    .#{$prefix}text-columne-count-4 {
        column-count: 4;
    }

    .#{$prefix}text-columne-count-5 {
        column-count: 5;
    }

    .#{$prefix}text-columne-count-6 {
        column-count: 6;
    }

    .#{$prefix}text-columne-count-8 {
        column-count: 8;
    }

    .#{$prefix}text-columne-gap-8 {
        column-gap: $size-8;
    }

    .#{$prefix}text-columne-gap-16 {
        column-gap: $size-16;
    }

    .#{$prefix}text-columne-gap-24 {
        column-gap: $size-24;
    }

    .#{$prefix}text-columne-gap-32 {
        column-gap: $size-32;
    }

    .#{$prefix}text-columne-gap-40 {
        column-gap: $size-40;
    }

    .#{$prefix}text-columne-gap-48 {
        column-gap: $size-48;
    }

    .#{$prefix}text-columne-gap-56 {
        column-gap: $size-56;
    }

    .#{$prefix}text-columne-gap-64 {
        column-gap: $size-64;
    }

    .#{$prefix}text-columne-gap-72 {
        column-gap: $size-72;
    }

    .#{$prefix}text-columne-gap-80 {
        column-gap: $size-80;
    }
}

// Default styles
.text-columne-count-1,
.text-columne-count-2,
.text-columne-count-3,
.text-columne-count-4,
.text-columne-count-5,
.text-columne-count-6,
.text-columne-count-7,
.text-columne-count-8 {
    column-gap: 0;
}

@include text-columne();

// Styles for each breakpoint
@include mediaQuery($device_desktop) {
    @include text-columne("desktop--");
}
@include mediaQuery($device_laptop) {
    @include text-columne("laptop--");
}
@include mediaQuery($device_tablet) {
    @include text-columne("tablet--");
}
@include mediaQuery($device_smartphone) {
    @include text-columne("smartphone--");
}
