// User interface for opt-in
// = = = = = = = = = = = = = = = = = = = =
$ucoi-layer-z-index: 3000 !default;
$ucoi-layer-background-background-color: rgba(black, 0.9) !default;

$ucoi-layer-container-color: white !default;
$ucoi-layer-container-background-color: transparent !default;
$ucoi-layer-container-font-size: 14px !default;

$ucoi-layer-agree-border-width: 1px !default;
$ucoi-layer-agree-color1: white !default;
$ucoi-layer-agree-color2: black !default;

// replacement image or call it button to trigger opt-in
// = = = = = = = = = = = = = = = = = = = =

// button background color
$ucoi-imagebutton-background-color: #777 !default;

// svg background color
$ucoi-imagebutton-svg-background-color: transparent !default;

// svg image rectangle background color
$ucoi-imagebutton-svg-image-background-color: transparent !default;

// svg image rectangle background color on hover
$ucoi-imagebutton-hover-svg-image-background-color: rgb(219, 247, 255) !default;

// mountains in svg and on hover
$ucoi-imagebutton-svg-image-mountains-color: rgba(black, 0.3) !default;
$ucoi-imagebutton-hover-svg-image-mountains-color: rgba(black, 0.8) !default;

// moon/sun in svg and on hover
$ucoi-imagebutton-svg-image-sun-color: rgba(white, 0.5) !default;
$ucoi-imagebutton-hover-svg-image-sun-color: rgb(229, 209, 0) !default;

// text in svg
$ucoi-imagebutton-svg-image-text-color: rgba(black, 0.5) !default;
$ucoi-imagebutton-svg-image-text-fons-size: 16px !default;

// -
// = = = = = = = = = = = = = = = = = = = =
// -

// container with all items
// = = = = = = = = = = = = = = = = = = = =

.ucoi__wrapper {
    position: relative;
}

// Opt-in layer
// = = = = = = = = = = = = = = = = = = = =

.ucoi__layer {
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $ucoi-layer-z-index;
        background-color: $ucoi-layer-background-background-color;
    }

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 90%;
        max-width: 400px;
        padding: 0.5rem;
        border-radius: 3px;
        transform: translate(-50%, -50%);
        z-index: $ucoi-layer-z-index + 1;
        color: $ucoi-layer-container-color;
        background-color: $ucoi-layer-container-background-color;
        text-align: center;
        font-size: $ucoi-layer-container-font-size;
        line-height: 1.3;
        hyphens: none;
    }

    &__icon {
        display: inline-block;
        width: 5rem;
        margin-bottom: 1em;
    }

    &__title {
        margin-bottom: 0.75em;
        font-weight: 700;
    }

    &__info {
        margin-bottom: 1.5em;
    }

    &__agree {
        border: $ucoi-layer-agree-border-width solid $ucoi-layer-agree-color1;
        background-color: $ucoi-layer-agree-color1;
        color: $ucoi-layer-agree-color2;
        padding: 0.5em 1em;
        font-size: 100%;
        cursor: pointer;
        transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
        vertical-align: middle;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: 700;

        &:hover {
            background-color: $ucoi-layer-agree-color2;
            color: $ucoi-layer-agree-color1;
            vertical-align: middle;
        }
    }

    &__disagree {
        display: inline-block;
        color: $ucoi-layer-container-color !important;
        text-decoration: underline !important;
        margin-top: 1em;
    }
}

// Image replacement
// = = = = = = = = = = = = = = = = = = = =

.ucoi__imagebutton {
    // The wrap around the button
    &__button-parent {
        position: relative;
    }

    // The button to open the opt-in
    &__button {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        background-color: $ucoi-imagebutton-background-color;
    }

    &__button .svg {
        width: 100%;
        height: 100%;

        &__background {
            fill: $ucoi-imagebutton-svg-background-color;
        }

        &__image__background {
            fill: transparent;
            transition: fill ease-in-out 0.4s;
        }

        &__image__mountains {
            fill: $ucoi-imagebutton-svg-image-mountains-color;
            transition: fill ease-in-out 0.4s;
        }

        &__image__sun {
            fill: $ucoi-imagebutton-svg-image-sun-color;
            transition: fill ease-in-out 1s;
        }

        &__text {
            color: $ucoi-imagebutton-svg-image-text-color;
            font-size: $ucoi-imagebutton-svg-image-text-fons-size;
        }

        &:hover {
            .svg__image__background {
                fill: $ucoi-imagebutton-hover-svg-image-background-color;
            }
            .svg__image__mountains {
                fill: $ucoi-imagebutton-hover-svg-image-mountains-color;
            }
            .svg__image__sun {
                fill: $ucoi-imagebutton-hover-svg-image-sun-color;
            }
        }
    }
}
