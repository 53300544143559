#footer {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .footercontent {
        padding: 35px 64px;
    }

    .address {
        width: 33.3%;
        display: inline-block;
        vertical-align: top;

        a {
            font-family: $font-family;
            font-weight: $font-weight-regular;
            text-decoration: none;
        }
    }

    .morelinks {
        width: 33.3%;
        display: inline-block;
        vertical-align: top;

        ul {
            display: inline-block;
            list-style-type: none;

            a {
                text-decoration: none;
            }
        }

        .morelinks-first{
            margin-left: 20%;
            margin-right: 20%;
        }
    }

    .logocontainer {
        display: inline-block;
        width: 33.3%;
    
        .logos {
            display: flex;
            vertical-align: middle;

            .logo-first, .logo-second {
                width: 50%;
                display: flex; 
                justify-content: center; 
                align-items: center;


                img {
                    max-height: 50px;
                    width: auto;
                }
            }
        }
    }

    .upperborder {
        border-top: 1px solid #000;
        display: inline;
        padding-top: 0.75rem;
    }

    .hr-footer {
        margin-top: -40px;

        @media screen and (max-width: 1820px) {
            margin: -40px 16px 0;
        }
    }

    &__logo {
        display: block;
        width: 120px;
        height: 120px;
        margin-left: auto;
        margin-right: auto;
        transform: translateX(13%);
    }
}

@include mediaQuery($device_smaller_than_desktop){

    #footer {
    
        .footercontent {
            padding: 35px 32px;
        }
    
        .address {
            width: 100%;
            padding-bottom: 30px;
        }
    
        .morelinks {
            width: 50%;
    
            ul {
                padding-left: 0px;
            }
    
            .morelinks-first{
                margin-left: 0px;
            }
        }
    
        .logocontainer {
            width: 50%;
        }
    }
}

@include mediaQuery($device_smartphone){

    #footer {
    
        .footercontent {
            padding: 35px 0px;
        }
    
        .address {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;

            .companyname {
                display: block;
            }
        }
    
        .mailaddress {
            display: block;
        }

        .morelinks {
            width: 100%;
            padding: 15px 16px 0;
            margin-bottom: 15px;
            background-color: rgba(235, 235, 235, 0.6);
            
    
            ul {
                padding-left: 0px;
                line-height: 2.3;
            }
    
            .morelinks-first{
                margin-right: 30%;
            }
        }
    
        .logocontainer {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;

            .logos {

                .logo-first, .logo-second{

                    img {
                        width: 100%;
                        max-height: initial;
                    }

                }
            }
        }
    }
}


