#body {

    .slick-container {

        .slick-slider {
            margin-bottom: 0;
            
            .slick-prev {
                left: 30px;
                width: 30px;

                &::before {
                    content: url(../Icons/button-left.svg);
                }
            }

            .slick-next {
                right: 30px;
                width: 30px;

                &::before {
                    content: url(../Icons/button-right.svg);
                }
            }

            .slick-dots {
                bottom: 10px;
                left: 20px;
                width: initial;

                li {

                    button {

                        &::before {
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
}


@include mediaQuery($device_smartphone) {
    #body {

        .slick-container {
    
            .slick-slider {
                
                .slick-prev {
                    width: 20px;
                }
    
                .slick-next {
                    width: 20px;
                }
            }
        }
    }
}